import { Box, Group, Stack, Title } from "@mantine/core";
import FeatureCard from "./FeatureCard";

import imageCalculadora from "../../assets/img/feature_01.jpg";
import imagePdf from "../../assets/img/feature_02.jpg";
import imageOptimizacion from "../../assets/img/feature_04.jpg";
import imageMateriales from "../../assets/img/feature_03.jpg";

const HomeMidBanner = () => {
  return (
    <Box bg="brand.6" pt={40} pb={60}>
      <Stack align="center">
        <Title order={2} c="brand.0">
          Características
        </Title>
        <Group h="100%" align="center" position="center" spacing="xl">
          <FeatureCard
            image={imageCalculadora}
            text="Calculadora de presupuestos"
          />
          <FeatureCard
            image={imagePdf}
            text="Generación de archivos PDF para impresión"
          />
          <FeatureCard image={imageMateriales} text="Listado de materiales" />
          <FeatureCard
            image={imageOptimizacion}
            text="Optimización de perfiles"
          />
        </Group>
      </Stack>
    </Box>
  );
};

export default HomeMidBanner;
