import { Button, Checkbox, Group } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { setTerms } from "../services/services";
import UserContext from "../store/user-context";
import styles from "./TermsPage.module.css";

const AFTER_ACCEPTED_REDIRECT_ACTIVE = "/help";
const AFTER_ACCEPTED_REDIRECT_INACTIVE = "/quote";

const TermsPage = () => {
  const userCtx = useContext(UserContext);

  const termsMutation = useMutation(() => setTerms(userCtx.token), {
    onSuccess: (data) => {
      if (data === true) {
        userCtx.editData({ terms: true });
        navigate(
          userCtx.data.active
            ? AFTER_ACCEPTED_REDIRECT_ACTIVE
            : AFTER_ACCEPTED_REDIRECT_INACTIVE
        );
      }
    },
  });

  const [acceptedCheck, setAcceptedCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AcceptTermsHandler = async () => {
    termsMutation.mutate();
  };

  const CloseTermsHandler = () => {
    userCtx.onLogout();
  };

  return (
    <div className={styles.termsPage}>
      <div className={styles.termsText}>
        <h1>Términos y Condiciones</h1>
        <p>
          Los términos y condiciones que se describen a continuación tienen por
          objeto informar sobre el uso de los servicios brindados a través del
          Sitio web aludig.com.ar, los derechos y deberes de las partes, como
          así también de las garantías ofrecidas y formas de finalización del
          contrato. Son de aplicación desde el primer acceso y tienen carácter
          obligatorio y vinculante. No se aceptarán otros Términos y Condiciones
          diferentes a los aquí detallados.
        </p>

        <h2>Aceptación de Términos y Condiciones</h2>

        <p>
          Se entiende que quien haya creado un Usuario para contratar los
          Servicios brindados por parte de ALUDIG, ha leído, entendido y
          aceptado los Términos y Condiciones aquí expuestos. Si no está de
          acuerdo con ello, el Usuario no deberá proporcionar ninguna
          información personal, ni utilizar el servicio porque no está
          autorizado para hacerlo sin mediar la aceptación de los Términos y
          Condiciones detallados.
        </p>
        <p>
          Quien adquiera los servicios del Sitio deberá tener capacidad para
          ello, por lo tanto, deben abstenerse aquellas personas que estén
          imposibilitadas de hacerlo. ALUDIG no asume ninguna responsabilidad en
          el caso de inexactitud de los datos personales ingresados, siendo el
          USUARIO totalmente responsable por todos los perjuicios que pudiera
          sufrir ALUDIG o un tercero.
        </p>
        <p>
          El uso de los Servicios también está sujeto a Políticas de cookies y a
          Política de privacidad, que abarca el modo en que se recaba, utiliza,
          comparte y almacena la información personal. Las partes contratantes
          aceptan que todos los Términos y Condiciones que a continuación se
          describen serán interpretados y ejecutados de acuerdo a las Leyes de
          la República Argentina en cuanto a su validez intrínseca, naturaleza,
          efectos, derechos y obligaciones excepto cuando se establezca lo
          contrario.
        </p>
        <p>
          Cualquier violación a los Términos y Condiciones generarán el derecho
          en favor de ALUDIG a suspender o cancelar la prestación del servicio.
        </p>

        <h2>Objeto </h2>

        <p>
          ALUDIG ofrece una licencia de uso de software, el cual consiste en la
          provisión y uso de un sistema de cálculo totalmente online, a través
          de una cuenta de Usuario, por parte de quien contrate el servicio, en
          adelante llamado USUARIO. Dicha licencia es otorgada exclusivamente a
          quién contrate los servicios de ALUDIG, por lo tanto, el USUARIO y, si
          tuviera, su personal a cargo, serán los únicos autorizados para
          utilizarlos. Si ALUDIG advirtiera que el Servicio está siendo
          utilizado por terceras personas no autorizadas para ello, o que el
          mismo se está comercializando por persona ajena a ALUDIG, podrá
          inhabilitar la CUENTA DE USUARIO, además de iniciar las acciones
          correspondientes por el uso y/o comercialización indebida.
        </p>

        <h2>Formas de Acceso. Alta de Usuario</h2>

        <p>
          Para contratar los servicios cada Cliente deberá aportar datos
          personales y de facturación que resultan esenciales al momento de la
          contratación, los cuales declara que son exactos y completos
          comprometiéndose a mantenerlos actualizados.
        </p>
        <p>
          Para acceder a los servicios ofrecidos por ALUDIG las personas pueden
          hacerlo a través de una CUENTA DE USUARIO, donde se solicita el
          ingreso de datos personales que quedarán visibles en la sección MI
          CUENTA (nombre y apellido, dirección de correo electrónico, teléfono,
          localidad y una contraseña). La dirección de correo electrónico y la
          contraseña servirán como DATOS DE INICIO DE SESIÓN al USUARIO.
        </p>
        <p>
          Al registrarse, el USUARIO confirma los Términos y Condiciones aquí
          expresados. Si ALUDIG advirtiera que la información de su cuenta es
          falsa, inexacta, incompleta o desactualizada, podrá suspender o
          cancelar la misma bajo su absoluta discreción y sin notificación
          alguna.
        </p>
        <p>
          El hecho de dar de alta una CUENTA DE USUARIO y/o contratar alguno de
          los servicios ofrecidos a través del Sitio Web aludig.com.ar, no podrá
          ser considerado ni interpretado como un contrato de sociedad, mandato
          o que genere ningún tipo de relación entre ALUDIG y el USUARIO, más
          que lo detallado en los Términos y Condiciones.
        </p>

        <h2>Funciones Disponibles. Deberes y Derechos</h2>

        <p>
          Una vez finalizado el proceso de registro, el USUARIO podrá comenzar a
          utilizar los servicios. El USUARIO solo podrá contratar los servicios
          que se encuentren disponibles en el Sitio Web aludig.com.ar en su
          versión actual.
        </p>
        <p>
          El USUARIO podrá comenzar a ingresar datos para la realización de un
          nuevo diseño de aberturas, calculando el sistema las medidas y costos
          asociados, generando al finalizar archivos en PDF, uno para el USUARIO
          y otro para su Cliente.
        </p>
        <p>
          Antes de comenzar a generar presupuestos, el USUARIO debe crear
          “diseños”, es decir listas de productos que representan los contenidos
          necesarios para armar distintas aberturas. A partir de estos diseños
          la herramienta calculará los presupuestos automáticamente.
        </p>
        <p>
          El sistema ya contiene algunos diseños basados en los catálogos de las
          diferentes marcas de productos de aluminio, por lo tanto, el USUARIO
          deberá indicar qué marca o marcas va a utilizar, teniendo acceso solo
          a los productos y catálogo de la marca seleccionada.
        </p>
        <p>
          Asimismo, el sistema posee algunos diseños de muestra de aberturas
          comunes, a fin de poder brindarle al USUARIO más opciones a la hora de
          realizar un nuevo diseño. Los descuentos de los perfiles que figuran
          como predeterminados son aproximados y no aptos para la fabricación en
          serie, por lo tanto, cada USUARIO deberá diseñar y modificar, de
          acuerdo a su criterio, las medidas de los descuentos correspondientes.
        </p>
        <p>
          Al finalizar el diseño por parte del USUARIO, se generarán dos
          archivos en PDF, uno para el USUARIO y otro para otorgarle a su
          Cliente. Los presupuestos realizados contienen el precio final, ya que
          todos los importes a cargar por parte del USUARIO, como lo son el
          aluminio, vidrios, accesorios, etc., son de precio final con IVA
          incluido.
        </p>
        <p>
          {`El USUARIO tendrá disponible para su visualización los últimos ${
            process.env.REACT_APP_SAVED_QUOTES ?? "10"
          }
          presupuestos que ha generado en la sección HISTORIAL, esto se debe a que
          la capacidad de almacenamiento debe ser limitada a los fines de
          brindar un mejor servicio a todos los USUARIOS. Dicha opción de
          guardado podrá ser desactivada por parte del USUARIO accediendo a
          CONFIGURACION, los presupuestos deberán ser descargados en ese momento
          ya que luego de generados serán borrados del sistema automáticamente.
          ALUDIG no se responsabiliza por la pérdida de datos y presupuestos.
          Por lo tanto, es responsabilidad del USUARIO tomar los recaudos
          necesarios para asegurar su resguardo, realizando en forma periódica
          la descarga de los archivos y/o información que resida en su cuenta.
          En ningún caso, el USUARIO podrá adjudicar a ALUDIG responsabilidades
          por daños y perjuicios derivados de la pérdida total o parcial de
          información originada en la deficiencia o inexistencia de los archivos
          si este no los ha descargado en el tiempo debido.`}
        </p>
        <p>
          El sistema ofrecido por parte de ALUDIG ayuda a realizar todos los
          cálculos, agilizando lo más posible el trabajo al USUARIO, quedando en
          este último la responsabilidad de revisar los datos consignados en el
          sistema, no siendo responsable ALUDIG de los datos que el USUARIO
          pueda consignar de manera errónea y que provoquen errores en los
          cálculos del sistema.
        </p>
        <p>
          Para cualquiera de los servicios abonados, el USUARIO tiene el derecho
          de revocar la aceptación del Servicio adquirido dentro de los diez
          (10) días de que adquirió el servicio sin gasto alguno para él, conf.
          Art.1110 y 1111 s.s. y c.c. del C.C.C.N.
        </p>
        <p>
          ALUDIG no tiene influencia sobre los respectivos detalles del servicio
          y los precios ofrecidos por el USUARIO frente a sus Cliente, por lo
          tanto, el USUARIO es el único responsable ante sus Clientes.
        </p>
        <p>
          ALUDIG se reserva el derecho de cambiar funciones, módulos,
          componentes proporcionados, condiciones de acceso y de utilización,
          para que pueda disponer de nuevas funciones. También podrá interrumpir
          la provisión de funciones proporcionadas por un breve plazo en razón
          de llevar a cabo labores de mantenimiento, todo ello en pos de ofrecer
          un mejor servicio, lo cual le será informado a través del Sitio Web
          aludig.com.ar.
        </p>

        <h2>Aceptación por parte de AluDig</h2>

        <p>
          ALUDIG se reserva el derecho de aceptar o rechazar las solicitudes de
          contratación por parte de los Clientes sin necesidad de invocación de
          causa alguna. La aceptación de la contratación se verá formalizada al
          momento de recibir el pago del Servicio contratado, una vez de
          realizado dicho pago el Cliente podrá ingresar los datos de acceso
          para comenzar a utilizar los servicios.
        </p>

        <p>
          Asimismo, Aludig se reserva el derecho de contratar con usuarios de
          diferentes países que considere convenientes teniendo en cuenta la
          regulación o ley aplicable y tratados y convenciones internacionales
          vigentes con el país de donde contratare el usuario.
        </p>

        <h2>Atención al Cliente</h2>

        <p>
          El USUARIO acepta recibir información por parte de ALUDIG a través de
          nuestro teléfono (disponible en la web) que haya consignado al dar de
          alta su CUENTA DE USUARIO.
        </p>
        <p>
          El USUARIO, ante cualquier duda o inconveniente que presente con el
          servicio contratado, podrá ponerse en contacto con personal de ALUDIG
          ingresando a la sección CONTACTO. Allí el USUARIO podrá remitir un
          mensaje a través de nuestro WhatsApp solicitando soporte técnico o
          realizar consultas con respecto al sistema.
        </p>

        <h2>Responsabilidad</h2>

        <p>
          ALUDIG no está obligado a revisar la información brindada y el
          contenido cargado por parte de cada USUARIO, por lo tanto, éste
          entiende y acepta que es el único responsable de dicho contenido, ya
          sea realizado por él mismo, persona autorizada, o por un tercero. En
          caso de que los Servicios sean utilizados por terceros, será exclusiva
          responsabilidad del Cliente especificar que dichos Servicios deben ser
          utilizados según los Términos y condiciones detallados.
        </p>
        <p>
          No obstante, le está prohibido al USUARIO hacer uso de los servicios
          de ALUDIG que sean contrarios a estos Términos y Condiciones, la
          normativa legal vigente, los derechos de terceros, nombre,
          personalidad, derechos de autor, protección de datos y derechos de
          marca y patentes.
        </p>
        <p>
          ALUDIG podrá denegar, suspender o interrumpir sus servicios, sin
          notificación previa, si advirtiere una clara violación de los Términos
          y Condiciones, sin derecho a recibir compensación y/o reembolso
          alguno, y sin perjuicio de cualquier acción o reclamo que pudiera
          corresponder a ALUDIG por el uso indebido de sus servicios.
        </p>

        <h2>Disponibilidad de los Servicios - Límite de las Garantías</h2>

        <p>
          Si bien ALUDIG se esfuerza para que el Servicio esté siempre
          disponible y con la menor interrupción posible, no garantiza la
          provisión continua e ininterrumpida del servicio ofrecido.
        </p>
        <p>
          En ciertas ocasiones se podrá interrumpir en forma temporal el
          servicio brindado por motivos de seguridad, por fallos en el sistema
          (Software o Hardware) o actualizaciones realizadas en nuestros equipos
          destinadas a la mejora continua de los Servicios. En todos los casos
          procuraremos que dichas interrupciones afecten en forma mínima al
          USUARIO. Los sistemas informáticos de los que dependen los servicios
          de ALUDIG pueden eventualmente no estar disponibles debido a
          dificultades técnicas o fallas de conectividad, que no pueden preverse
          de manera razonable o que por cualquier otra circunstancia se
          encuentre fuera del control de ALUDIG. El USUARIO acepta y entiende
          que los perjuicios causados por dichas interrupciones, no podrán ser
          imputados a ALUDIG, no siendo responsable de los problemas de acceso a
          la cuenta de usuario y/o de sus servicios, ni de los perjuicios que se
          pudieran causar por ello, cuando éstos se deban a factores ajenos a su
          ámbito de control.
        </p>
        <p>
          Por su parte el USUARIO reconoce y acepta que, la visualización y
          acceso a los sitios de Internet, archivos o cualquier contenido, sólo
          es posible gracias a la infraestructura de recursos de terceras partes
          como ser proveedores de conexión a Internet, proveedores de energía
          eléctrica, etc., sobre las cuales ALUDIG no posee el control. En
          consecuencia, el USUARIO no podrá reclamar cualquier inconveniente
          producto de problemas debidos a fallos, lentitud, y/o errores en el
          acceso y uso del servicio, causado por dificultades de conectividad,
          comunicaciones u otras contingencias posibles.
        </p>
        <p>
          ALUDIG tampoco se responsabiliza en caso de verse interrumpido los
          servicios o producirse deficiencias en su prestación cuando ello sea
          consecuencia de cualquier circunstancia que pueda ser calificada como
          un delito informático, como puede ser ataques Dos o DDoS, intrusión no
          autorizada a los sistemas, virus, etc.
        </p>

        <h2>Modificaciones de los Servicios</h2>

        <p>
          ALUDIG se reserva el derecho de poder realizar cambios de funciones,
          módulos, componentes proporcionados, condiciones de acceso y de
          utilización para que el USUARIO pueda disponer de nuevas funciones.
          También podrá interrumpir la provisión de los servicios proporcionados
          por el menor tiempo posible, en pos de ofrecer un mejor servicio.
        </p>

        <h2>Confidencialidad</h2>

        <p>
          Toda la información aportada por parte de los USUARIOS solo será
          empleada a los fines de poder utilizar los servicios ofrecidos por
          ALUDIG y como canal de información para notificarle al USUARIO
          posibles promociones o avisos que deba cursarle ALUDIG, bajo ningún
          motivo dicha información será difundida, comprometiéndose a preservar
          los datos de los USUARIOS.
        </p>
        <p>
          El USUARIO declara que la información aportada es exacta y completa
          comprometiéndose a mantenerla actualizada a fin de facilitar la
          utilización de los servicios contratados y mantener una comunicación
          fluida con ALUDIG.
        </p>

        <h2>Pago</h2>

        <p>
          Teniendo en cuenta que ALUDIG presta sus servicios en diferentes
          países, el usuario acepta las disposiciones y reglamentaciones
          fiscales / impositivas vigentes en la República Argentina al momento
          de contratación.
        </p>
        <p>
          Todos los precios están expresados en{" "}
          {userCtx.data.intl ? "dólares estadounidenses" : "pesos argentinos"},
          moneda de curso legal de la República Argentina. Todos los precios
          incluyen IVA, salvo que se indique lo contrario.
        </p>
        <p>
          Los datos de facturación y de pago se encuentran sujetos al plan de
          contratación. ALUDIG se reserva el derecho de modificar los precios
          del servicio en cualquier momento, teniendo en cuenta los costos de
          transacción y condiciones generales y/o particulares del servicio
          contratado.
        </p>
        <p>
          El USUARIO tiene la posibilidad de abonar por medio de transferencia
          bancaria o por pago en efectivo.
        </p>
        <p>
          El USUARIO podrá solicitar cambios en los datos de la facturación
          debiéndolo notificar fehacientemente a ALUDIG, quien confirmará la
          recepción y avisará de la aplicación de cambios. Si la solicitud no
          cumple con este requisito, el USUARIO deberá pagar la factura emitida
          con los datos preexistentes.
        </p>
        <p>
          Si el USUARIO no abonara en tiempo y forma, la CUENTA DE USUARIO será
          desactivada de manera automática sin interpelación alguna, guardando
          los datos volcados por el USUARIO por un pazo de quince (15) días
          corridos por si decidiera volver a utilizar los servicios brindados
          por ALUDIG.
        </p>

        <h2>Baja de un Servicio</h2>

        <p>
          En todo momento los USUARIOS podrán dar de baja sus cuentas,
          eliminando de manera automática toda la información proporcionada por
          el USUARIO, no pudiendo reclamar la devolución o reembolso de la
          cantidad abonada.
        </p>

        <h2>Propiedad Intelectual</h2>

        <p>
          ALUDIG es propietaria de todos los derechos de propiedad intelectual
          sobre su sitio, todo su contenido, servicios, logos, diseños,
          imágenes, frases publicitarias, derechos de autor, dominios, códigos,
          desarrollos, software, bases de datos, información, entre otros
          (“Propiedad Intelectual”) y se encuentran protegidos por leyes
          nacionales e internacionales.
        </p>

        <p>
          Aunque ALUDIG otorga permiso para usar sus productos y servicios
          conforme a lo previsto en los Términos y Condiciones, esto no implica
          una autorización para usar su Propiedad Intelectual, excepto
          consentimiento previo y expreso de ALUDIG. En cualquier caso, los
          usuarios vendedores que usen dichos productos y servicios no podrán
          utilizar la Propiedad Intelectual de ALUDIG de una manera que cause
          confusión en el público y deberán llevar a cabo su actividad comercial
          bajo una marca o nombre comercial propio y distintivo, que no resulte
          confundible con ALUDIG. La utilización de los servicios ofrecidos por
          ALUDIG tampoco implica la autorización para usar propiedadintelectual
          de terceros que pueda estar involucrada, cuyo uso quedará bajo
          exclusiva responsabilidad del usuario.
        </p>

        <p>
          En caso que una Persona Usuaria o cualquier publicación infrinja la
          Propiedad Intelectual de ALUDIG o de terceros, ALUDIG podrá remover
          dicha publicación total o parcialmente, sancionar al usuario conforme
          a lo previsto en estos Términos y Condiciones y ejercer las acciones
          extrajudiciales y/o judiciales correspondientes.
        </p>

        <h2>Disposiciones Finales</h2>

        <p>
          Los Términos y Condiciones podrán ser modificados en todo o en parte
          en cualquier momento y a exclusivo criterio de ALUDIG, dichos cambios
          e implementaciones tendrán vigencia a partir del momento mismo en que
          sean publicados o insertados en el Sitio Web, lo cual le será avisado
          a través de aludig.com.ar, por ello le sugerimos que visite
          periódicamente el Sitio Web.
        </p>
        <p>
          Las partes acuerdan acceder incondicionalmente a la Jurisdicción de
          los Tribunales de la Ciudad de Bahía Blanca, o en su defecto, a los
          Tribunales de la República Argentina para la resolución de cualquier
          duda, dificultad o controversia relacionada con todo o parte de los
          Términos y Condiciones si así lo estableciera el derecho aplicable.
        </p>
        <p>
          Se fija como domicilio de ALUDIG calle Brasil N°654, Ciudad de Bahía
          Blanca, Provincia de Buenos Aires, Código Postal 8000, República
          Argentina.
        </p>
      </div>

      <Group mt={48} position="center">
        <Checkbox
          label="Acepto los términos y condiciones"
          checked={acceptedCheck}
          onChange={(event) => setAcceptedCheck(event.currentTarget.checked)}
        />
      </Group>
      <Group mt={40} mb={80} position="center" spacing={40}>
        <Button
          size="md"
          sx={{ width: "200px" }}
          loading={termsMutation.isLoading}
          disabled={!acceptedCheck}
          onClick={AcceptTermsHandler}
        >
          Continuar
        </Button>
        <Button
          size="md"
          sx={{ width: "200px" }}
          variant="outline"
          disabled={termsMutation.isLoading}
          onClick={CloseTermsHandler}
        >
          Salir
        </Button>
      </Group>
    </div>
  );
};

export default TermsPage;
