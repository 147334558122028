import {
  Accordion,
  ActionIcon,
  Button,
  Code,
  Group,
  Text,
} from "@mantine/core";
import {
  BiCalendar,
  BiDownload,
  BiEdit,
  BiTagAlt,
  BiTrash,
  BiUser,
} from "react-icons/bi";
import SavedQuote from "../../models/saved-quote";

type HistoryButtonProps = {
  id: string;
  text: string;
  disabled: boolean;
  clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const HistoryButton = (props: HistoryButtonProps) => {
  return (
    <Button
      leftIcon={<BiDownload size={20} />}
      value={props.id}
      variant="outline"
      color="brand"
      disabled={props.disabled}
      onClick={props.clickHandler}
    >
      {props.text}
    </Button>
  );
};

type HistoryAccordionProps = {
  quotes: SavedQuote[];
  loading: boolean;
  canRemove: boolean;
  canDownloadCostFile: boolean;
  onDownloadCost: (id: string) => void;
  onDownloadOrder: (id: string) => void;
  onDownloadMaterial: (id: string) => void;
  onDownloadClient: (id: string) => void;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
};

const HistoryAccordion = (props: HistoryAccordionProps) => {
  return (
    <Accordion
      multiple={false}
      variant="contained"
      chevronPosition="left"
      styles={{
        item: { backgroundColor: "white", border: "1px solid lightgray" },
        content: {
          backgroundColor: "white",
          padding: "1em 2em 1.5em",
        },
      }}
    >
      {props.quotes.map((quote) => (
        <Accordion.Item key={quote._id} value={quote._id}>
          <Accordion.Control>
            <Group position="apart">
              <Group spacing="xl" noWrap>
                <Group spacing={6} noWrap>
                  <BiCalendar />
                  <Text>
                    {new Date(quote.date).toLocaleDateString("es-AR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })}
                  </Text>
                </Group>
                <Group spacing={6} noWrap>
                  <BiTagAlt />
                  <Code sx={{ fontSize: 14 }}>{quote.code}</Code>
                </Group>
                {quote.clientName && (
                  <Group spacing={6} noWrap>
                    <BiUser />
                    <Text>{quote.clientName}</Text>
                  </Group>
                )}
              </Group>
              {props.canRemove && (
                <ActionIcon
                  value={quote._id}
                  variant="subtle"
                  color="red"
                  disabled={props.loading}
                  onClick={() => props.onRemove(quote._id)}
                >
                  <BiTrash size={20} />
                </ActionIcon>
              )}
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Group>
              {quote.hasCostFile && (
                <HistoryButton
                  id={quote._id}
                  text="Costos"
                  disabled={props.loading || !props.canDownloadCostFile}
                  clickHandler={() => props.onDownloadCost(quote._id)}
                />
              )}
              {quote.hasOrderFile && (
                <HistoryButton
                  id={quote._id}
                  text="Orden"
                  disabled={props.loading}
                  clickHandler={() => props.onDownloadOrder(quote._id)}
                />
              )}
              {quote.hasMaterialFile && (
                <HistoryButton
                  id={quote._id}
                  text="Materiales"
                  disabled={props.loading}
                  clickHandler={() => props.onDownloadMaterial(quote._id)}
                />
              )}
              {quote.hasClientFile && (
                <HistoryButton
                  id={quote._id}
                  text="Presupuesto"
                  disabled={props.loading}
                  clickHandler={() => props.onDownloadClient(quote._id)}
                />
              )}
              {quote.hasState && (
                <Button
                  value={quote._id}
                  leftIcon={<BiEdit size={20} />}
                  variant="filled"
                  color="blue"
                  disabled={props.loading}
                  onClick={() => props.onEdit(quote._id)}
                >
                  Editar
                </Button>
              )}
            </Group>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default HistoryAccordion;
