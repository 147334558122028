import { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { Group, Paper, Stack, Table, Text, Title } from "@mantine/core";
import UserData from "../../models/user-data";
import { Plan } from "../../utils/globalConstants";

import styles from "./Account.module.css";

type AccountBillingInfoProps = {
  data: UserData;
  plans: Plan[];
};

const BANK_ALIAS = "Aludig.programa";
const BANK_CBU = "0110244630024484890983";
const BANK_INTL_CBU = "0110244631024485691302";
const BANK_INTL_SWIFT = "NACNARBABAH";
const DUE_DATE = Number(process.env.REACT_APP_DUE_DATE);

const AccountBillingInfo = (props: AccountBillingInfoProps) => {
  const [fee, setFee] = useState(0);
  const [overdue, setOverdue] = useState(false);
  const [overdueFee, setOverdueFee] = useState(0);
  const [finalFee, setFinalFee] = useState(0);

  useEffect(() => {
    const plan = props.plans.find((plan) => plan.code === props.data.plan);
    if (!plan) return;

    const planPrice = props.data.intl ? plan.priceUsd : plan.price;
    const isOverdue =
      props.data.initialized &&
      !props.data.intl &&
      !props.data.monthPaid &&
      new Date().getDate() >= DUE_DATE;

    setFee(planPrice);
    if (isOverdue) {
      const overduePrice = Math.ceil(planPrice * 0.1);
      setOverdue(isOverdue);
      setOverdueFee(overduePrice);
      setFinalFee(planPrice + overduePrice);
    }
  }, [props.data, props.plans]);

  const formatPriceString = (price: number | undefined) => {
    if (price) {
      return price.toLocaleString("es-AR", {
        style: "currency",
        currency: props.data.intl ? "USD" : "ARS",
        maximumFractionDigits: 0,
      });
    } else return "";
  };

  return (
    <Paper p="xl">
      <Title order={2}>Facturación</Title>
      <Table
        mt="sm"
        captionSide="bottom"
        verticalSpacing="xs"
        className={styles.tableUser}
      >
        <tbody>
          <tr>
            <td>Estado de la cuenta</td>
            <td>
              <Text
                weight="bold"
                color={props.data.active ? "green.8" : "red.8"}
              >
                {props.data.active ? "Activo" : "Inactivo"}
              </Text>
            </td>
          </tr>
          <tr>
            <td>Plan</td>
            <td>{props.data.planName}</td>
          </tr>
          <tr>
            <td>
              <Stack spacing={8}>
                <Text>Cuota a pagar</Text>
                {overdue && (
                  <>
                    <Text>Recargo 10%</Text>
                    <Text>Total a pagar</Text>
                  </>
                )}
              </Stack>
            </td>
            <td>
              <Stack spacing={8}>
                <Group>
                  <Text span td={props.data.monthPaid ? "line-through" : ""}>
                    {formatPriceString(fee)}
                  </Text>
                  {props.data.monthPaid && <Text span>✓ Pago registrado</Text>}
                </Group>
                {overdue && (
                  <>
                    <Text>{formatPriceString(overdueFee)}</Text>
                    <Text>{formatPriceString(finalFee)}</Text>
                  </>
                )}
              </Stack>
            </td>
          </tr>
        </tbody>
        <caption style={{ color: "dimgray" }}>
          <Stack spacing={4}>
            {!props.data.intl && (
              <Group spacing={4}>
                <BiErrorCircle size={16} />
                <Text>Cuota ajustable trimestralmente según inflación.</Text>
              </Group>
            )}
            <Text>Cuenta bancaria:</Text>
            <Group ml="lg">
              <Stack spacing={0}>
                <Text>CBU</Text>
                <Text>{props.data.intl ? "SWIFT" : "ALIAS"}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text inherit>
                  {props.data.intl ? BANK_INTL_CBU : BANK_CBU}
                </Text>
                <Text inherit>
                  {props.data.intl ? BANK_INTL_SWIFT : BANK_ALIAS}
                </Text>
              </Stack>
            </Group>
            {!props.data.intl && (
              <Text>Vencimiento: Del 1 al 10 de cada mes.</Text>
            )}
          </Stack>
        </caption>
      </Table>
    </Paper>
  );
};

export default AccountBillingInfo;
