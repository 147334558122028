import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  BiArrowBack,
  BiCheck,
  BiError,
  BiErrorCircle,
  BiInfoCircle,
  BiMinus,
  BiPlus,
  BiQuestionMark,
  BiSave,
} from "react-icons/bi";

import {
  Measures,
  OPENINGS,
  ProductCategory,
} from "../../utils/globalConstants";

import CatalogueList from "./CatalogueList";
import DesignTable from "./DesignTable";
import UserContext from "../../store/user-context";
import Item from "../../models/item";
import Design from "../../models/design";
import Opening from "../../models/opening";
import { DESIGN_CATEGORIES } from "../../utils/globalConstants";

import styles from "./NewDesign.module.css";
import {
  Alert,
  Button,
  Group,
  Indicator,
  Loader,
  MediaQuery,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  SelectItem,
  Stack,
  Tabs,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import DiffInput from "../UI/DiffInput";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  DesignSaveBody,
  editDesign,
  getBrands,
  getDesignById,
  getProductsByBrandLine,
  saveNewDesign,
} from "../../services/services";

type GlobalDesignOps = {
  crossbar?: string;
  crossbarDiff?: Measures;
  revest?: string;
  revestDiff?: Measures;
  mosquitoDiff?: Measures;
  premarcDiff?: Measures;
  moduleDiff?: Measures;
  tejidoDiff?: Measures;
  guiaDiff?: number;
  cvdPerCross?: number;
  laborTime?: number;
  ok?: boolean;
};

type NewDesignProps = {
  editType: DesignEditType;
  intl?: boolean;
};

export enum DesignEditType {
  NewDesign,
  EditOwnDesign,
  BaseOnOwnDesign,
  BaseOnTemplate,
}

const HIDDEN_CATS = [ProductCategory.MOSQUITO, ProductCategory.PREMARC];
const CVD_PER_CROSSBAR_OPTIONS: SelectItem[] = [
  { value: "0", label: "Ninguno" },
  { value: "2", label: "x2" },
  { value: "4", label: "x4" },
];

const NewDesign = (props: NewDesignProps) => {
  const userCtx = useContext(UserContext);

  const queryClient = useQueryClient();

  const [pageTitle, setPageTitle] = useState("Nuevo diseño");
  const [started, setStarted] = useState(false);
  const [designName, setDesignName] = useState("");
  const [openingType, setOpeningType] = useState<Opening>(OPENINGS[0]);

  const [brand, setBrand] = useState<string>(undefined);
  const [line, setLine] = useState<string>(undefined);
  const [panelCount, setPanelCount] = useState<number>(OPENINGS[0].minPan);

  const [selectedProductId, setSelectedProductId] = useState<string>(undefined);
  const [items, setItems] = useState<Item[]>([]);
  const [currentCat, setCurrentCat] = useState(DESIGN_CATEGORIES[0][0]);

  const nextItemIndex = useRef(0);

  const [globalOps, setGlobalOps] = useState<GlobalDesignOps>({
    crossbar: "",
    crossbarDiff: { width: 0, height: 0 },
    revest: "",
    revestDiff: { width: 0, height: 0 },
    mosquitoDiff: { width: 0, height: 0 },
    premarcDiff: { width: 0, height: 0 },
    moduleDiff: { width: 0, height: 0 },
    tejidoDiff: { width: 0, height: 0 },
    guiaDiff: 0,
    cvdPerCross: 0,
    laborTime: 0,
    ok: true,
  });
  const [laborEdit, setLaborEdit] = useState<{ h: number; m: number }>({
    h: 0,
    m: 0,
  });

  const params = useParams();
  const [originalName, setOriginalName] = useState<string>(undefined);

  const navigate = useNavigate();
  const AFTER_SAVE_REDIRECT = "/design";
  const MAX_ITEMS_LENGTH = 60;
  const MAX_NAME_LENGTH = 64;

  // Edit state
  const [designEditId, setDesignEditId] = useState<string>(undefined);

  // Queries
  const { data: brandData } = useQuery(
    "brands",
    () => getBrands(userCtx.token),
    { placeholderData: [] }
  );
  const { data: designData, isFetching: loadingDesign } = useQuery(
    ["design", designEditId],
    () => getDesignById(designEditId, userCtx.token),
    {
      enabled: Boolean(designEditId),
      onSuccess: (data: Design) => {
        setBrand(data.brand);
        setLine(data.line);
      },
    }
  );
  const { data: productData, isFetching: loadingProducts } = useQuery(
    ["products", brand, line],
    () => getProductsByBrandLine(brand, line, userCtx.token),
    {
      placeholderData: [],
      enabled:
        (started || Boolean(designEditId)) && Boolean(brand) && Boolean(line),
    }
  );

  useEffect(() => {
    if (props.editType === DesignEditType.EditOwnDesign)
      setPageTitle("Editar diseño");
    else if (props.editType === DesignEditType.BaseOnTemplate)
      setPageTitle("Nuevo diseño basado en plantilla");
  }, [props.editType]);

  // If editing, load design after fetching design data
  useEffect(() => {
    if (!Boolean(designEditId) || productData.length === 0) return;

    if (props.editType === DesignEditType.EditOwnDesign) {
      setDesignName(designData.name);
      setOriginalName(designData.name);
    } else if (props.editType === DesignEditType.BaseOnOwnDesign) {
      setDesignName(`Copia de ${designData.name}`);
    } else if (props.editType === DesignEditType.BaseOnTemplate) {
      setDesignName(designData.name);
    }
    const loadedOpen = OPENINGS.find((op) => op.code === designData.open);
    setOpeningType(loadedOpen);
    if (loadedOpen.catLimit) {
      setCurrentCat(loadedOpen.catLimit[0]);
    }
    setPanelCount(designData.pan);
    const itemsToEdit = designData.items.map((item, index) => {
      const product = productData.find(
        (product) => product._id === item.prodId
      );
      return {
        itemId: index,
        prodId: item.prodId,
        code: product.code,
        name: product.name,
        diff: item.diff,
        diffSign: item.diff === 0 ? -1 : Math.sign(item.diff),
        mul: item.mul,
        ori: item.ori,
        ang: product.ang ? (item.ang ? item.ang : 45) : item.ang,
        cat: product.cat,
        wgt: product.wgt,
        prodOrient: product.ori,
        prodAng: product.ang,
        count: item.count,
      };
    });
    nextItemIndex.current = itemsToEdit.length;
    setItems(itemsToEdit);
    const savedCrossbar = designData.crossId;
    const savedRevest = designData.revId;
    setGlobalOps({
      crossbar: savedCrossbar ?? "",
      crossbarDiff: {
        width: designData.crossDiff ? designData.crossDiff[0] : 0,
        height: designData.crossDiff ? designData.crossDiff[1] : 0,
      },
      revest: savedRevest ?? "",
      revestDiff: {
        width: designData.revDiff ? Math.abs(designData.revDiff[0]) : 0,
        height: designData.revDiff ? Math.abs(designData.revDiff[1]) : 0,
      },
      mosquitoDiff: {
        width: designData.mosDiff ? designData.mosDiff[0] : 0,
        height: designData.mosDiff ? designData.mosDiff[1] : 0,
      },
      tejidoDiff: {
        width: designData.tejDiff ? Math.abs(designData.tejDiff[0]) : 0,
        height: designData.tejDiff ? Math.abs(designData.tejDiff[1]) : 0,
      },
      premarcDiff: {
        width: designData.premDiff ? Math.abs(designData.premDiff[0]) : 0,
        height: designData.premDiff ? Math.abs(designData.premDiff[1]) : 0,
      },
      moduleDiff: {
        width: designData.moduDiff ? Math.abs(designData.moduDiff[0]) : 0,
        height: designData.moduDiff ? Math.abs(designData.moduDiff[1]) : 0,
      },
      guiaDiff: designData.guiDiff || 0,
      cvdPerCross: designData.cvdMul || 0,
      laborTime: designData.laborTime || 0,
      ok: true,
    });
    const laborH = Math.floor(designData.laborTime / 60);
    const laborM = designData.laborTime % 60;
    setLaborEdit({ h: laborH, m: laborM });
  }, [designEditId, productData, designData, props.editType]);

  const saveDesignMutation = useMutation(
    (body: DesignSaveBody) => {
      if (props.editType !== DesignEditType.EditOwnDesign) {
        return saveNewDesign(body, userCtx.token);
      } else if (designEditId) {
        return editDesign(designEditId, body, userCtx.token);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("designs");
        if (props.editType !== DesignEditType.EditOwnDesign) {
          showNotification({
            title: "Nuevo diseño",
            message: "Diseño guardado exitosamente.",
            color: "green",
            icon: <BiCheck size={32} />,
          });
          navigate(AFTER_SAVE_REDIRECT);
        } else if (designEditId) {
          showNotification({
            title: "Editar diseño",
            message: "Diseño guardado exitosamente.",
            color: "green",
            icon: <BiCheck size={32} />,
          });
          navigate(AFTER_SAVE_REDIRECT);
        }
      },
      onError: (error: Error) => {
        showNotification({
          title: "Guardar diseño",
          message: error.message,
          color: "red",
          icon: <BiError size={32} />,
        });
      },
    }
  );

  // Prepare edit design at start (if designId param is set)
  useEffect(() => {
    const editId = params.designId;
    if (editId) {
      setDesignEditId(editId);
    }
  }, [params.designId]);

  // Change item index when adding new items
  useEffect(() => {
    if (items.length > 0) nextItemIndex.current += 1;
    else nextItemIndex.current = 0;
  }, [items.length]);

  const openingTypeChangeHandler = (newValue: string) => {
    const open = OPENINGS.find((i) => i.code === newValue);
    setOpeningType(open);
    setPanelCount(open.minPan);
    if (open.catLimit) {
      setCurrentCat(open.catLimit[0]);
    }
  };
  const brandChangeHandler = (newValue: string) => {
    const selectedBrand = newValue === "" ? undefined : newValue;
    setBrand(selectedBrand);
    setLine(undefined);
  };
  const lineChangeHandler = (newValue: string) => {
    const selectedLine = newValue === "" ? undefined : newValue;
    setLine(selectedLine);
  };
  const panelCountChangeHandler = (newValue: string) => {
    const selectedPanel = +newValue;
    setPanelCount(selectedPanel);
  };
  const startClickHandler = async () => {
    if (!brand || !line) return;
    if (productData.length > 0) {
      setItems([]);
    }
    setStarted(true);
  };

  const designNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDesignName(e.target.value);
  };
  const selectionChangeHandler = (value: string) => {
    setSelectedProductId(value);
  };
  const categoryChangeHandler = (value: string) => {
    setCurrentCat(value);
    setSelectedProductId(undefined);
  };

  const addItemHandler = () => {
    if (!selectedProductId) return;

    const product = productData.find(
      (product) => product._id === selectedProductId
    );
    if (product.cat !== currentCat) return;

    const hasMultiplier =
      product.ori === "m" || product.ori === "x" || product.ori === "y";

    setItems((prevState) => {
      const prodOrient = product.ori;
      const prodAngle = product.ang;
      const newItem = {
        itemId: nextItemIndex.current,
        prodId: product._id,
        code: product.code,
        name: product.name,
        diff: prodOrient ? 0 : null,
        diffSign: -1,
        mul: hasMultiplier ? 1 : null,
        ori: prodOrient
          ? prodOrient === "s" || prodOrient === "m"
            ? "h"
            : prodOrient.replace("x", "h").replace("y", "v")
          : undefined,
        ang: prodAngle === -1 ? 45 : prodAngle,
        wgt: product.wgt,
        cat: product.cat,
        prodOrient: prodOrient,
        prodAng: prodAngle,
        count: 1,
      };
      return [...prevState, newItem];
    });
  };

  const modifyItemHandler = (itemId: number, values: {}) => {
    setItems((prevState) => {
      const index = prevState.findIndex((x) => x.itemId === itemId);
      if (index !== -1) {
        let items = [...prevState];
        let item = { ...items[index], ...values };
        items[index] = item;
        return items;
      }
    });
  };

  const removeItemHandler = (itemId: number) => {
    setItems((prevState) => {
      return prevState.filter((item) => item.itemId !== itemId);
    });
  };

  const crossbarChangeHandler = (value: string) => {
    setGlobalOps((prev) => {
      return { ...prev, crossbar: value };
    });
  };
  const crossbarWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        crossbarDiff: { height: prev.crossbarDiff.height, width: value },
      };
    });
  };
  const crossbarHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        crossbarDiff: { width: prev.crossbarDiff.width, height: value },
      };
    });
  };
  const revestChangeHandler = (value: string) => {
    setGlobalOps((prev) => {
      return { ...prev, revest: value };
    });
  };
  const revestWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        revestDiff: { height: prev.revestDiff.height, width: value },
      };
    });
  };
  const revestHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        revestDiff: { width: prev.revestDiff.width, height: value },
      };
    });
  };
  const mosquitoWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        mosquitoDiff: { height: prev.mosquitoDiff.height, width: value },
      };
    });
  };
  const mosquitoHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        mosquitoDiff: { width: prev.mosquitoDiff.width, height: value },
      };
    });
  };
  const premarcWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        premarcDiff: { height: prev.premarcDiff.height, width: value },
      };
    });
  };
  const premarcHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        premarcDiff: { width: prev.premarcDiff.width, height: value },
      };
    });
  };
  const moduleWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        moduleDiff: { height: prev.moduleDiff.height, width: value },
      };
    });
  };
  const moduleHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        moduleDiff: { width: prev.moduleDiff.width, height: value },
      };
    });
  };
  const tejidoWidthDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        tejidoDiff: { height: prev.tejidoDiff.height, width: value },
      };
    });
  };
  const tejidoHeightDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return {
        ...prev,
        tejidoDiff: { width: prev.tejidoDiff.width, height: value },
      };
    });
  };
  const guiaDiffChangeHandler = (value: number) => {
    setGlobalOps((prev) => {
      return { ...prev, guiaDiff: value };
    });
  };
  const cvdPerCrossChangeHandler = (option: string) => {
    setGlobalOps((prev) => {
      return { ...prev, cvdPerCross: +option };
    });
  };

  // SAVE DESIGN
  const saveDesignHandler = async () => {
    if (designName.trim().length === 0 || items.length === 0) return;

    const compactItems = items.map((fullItem) => {
      const diff =
        fullItem.cat === ProductCategory.ACC ? undefined : fullItem.diff;
      const mul = fullItem.mul ? fullItem.mul : undefined;

      let ang = undefined;
      if (fullItem.prodAng) {
        if (
          fullItem.ang === 45 ||
          fullItem.ang === 90 ||
          fullItem.ang === 4590
        ) {
          ang = fullItem.ang;
        } else {
          ang = 45;
        }
      }

      let ori = undefined;
      if (fullItem.ori === "h" || fullItem.ori === "v") ori = fullItem.ori;
      else if (fullItem.ori === "s" || fullItem.ori === "m") ori = "h";

      const count = fullItem.count > 0 ? +fullItem.count : 1;

      return {
        prodId: fullItem.prodId,
        diff: diff,
        mul: mul,
        ang: ang,
        ori: ori,
        count: count,
      };
    });

    const minuteLaborTime = laborEdit.h * 60 + laborEdit.m;

    saveDesignMutation.mutate({
      name: designName.trim(),
      brand: brand,
      line: line,
      pan: panelCount,
      items: compactItems,
      open: openingType.code,
      revId: globalOps.revest,
      crossId: globalOps.crossbar,
      revDiff: [-globalOps.revestDiff.width, -globalOps.revestDiff.height],
      crossDiff: [globalOps.crossbarDiff.width, globalOps.crossbarDiff.height],
      mosDiff: [globalOps.mosquitoDiff.width, globalOps.mosquitoDiff.height],
      tejDiff: [-globalOps.tejidoDiff.width, -globalOps.tejidoDiff.height],
      premDiff: [globalOps.premarcDiff.width, globalOps.premarcDiff.height],
      moduDiff: [-globalOps.moduleDiff.width, -globalOps.moduleDiff.height],
      guiDiff: globalOps.guiaDiff,
      cvdMul: globalOps.cvdPerCross,
      laborTime: minuteLaborTime,
      ok: globalOps.ok,
    });
  };

  const brandList =
    brandData.length === 0
      ? []
      : brandData.map((b) => {
          return { value: b.code, label: b.name };
        });
  const lineList = !brand
    ? []
    : brandData
        .find((b) => b.code === brand)
        .lines.map((l) => {
          return { value: l.code, label: l.name };
        });
  let panels: number[] = [];
  for (let p = openingType.minPan; p <= openingType.maxPan; p++) {
    panels.push(p);
  }
  const panelsList = panels.map((p: number) => p.toString());

  // Display brand & line
  const selectedBrandObj = brand
    ? brandData.find((x) => x.code === brand)
    : undefined;
  const displayBrand = brand ? selectedBrandObj.name : "-";
  const displayLine = line
    ? selectedBrandObj.lines.find((y) => y.code === line).name
    : "-";

  // Category tabs (filter by opening's cat limit and hidden cats)
  const filteredCategories = DESIGN_CATEGORIES.filter((cat) =>
    openingType.catLimit ? openingType.catLimit.includes(cat[0]) : true
  ).filter(
    (cat) =>
      !HIDDEN_CATS.includes(cat[0] as ProductCategory) ||
      (HIDDEN_CATS.includes(cat[0] as ProductCategory) &&
        openingType.catLimit?.includes(cat[0]))
  );
  const categoryTabs = filteredCategories.map((c, index) => {
    return {
      index: index,
      value: c[0],
      label: c[1],
      count: items?.filter((i) => i.cat === c[0])?.length || 0,
    };
  });

  const tableCaption = (
    <strong>{DESIGN_CATEGORIES.find((x) => x[0] === currentCat)[1]}</strong>
  );

  // Error label
  let errorSubmit = "";
  if (!designName) {
    errorSubmit = "Ingrese un nombre válido para este diseño.";
  } else if (designName.length > MAX_NAME_LENGTH) {
    errorSubmit = "El nombre del diseño es demasiado largo.";
  } else if (items.length === 0) {
    errorSubmit = "Añada al menos un producto a la lista de ítems.";
  } else if (items.length > MAX_ITEMS_LENGTH) {
    errorSubmit = "El diseño supera la cantidad máxima de ítems.";
  } else if (openingType.extraPost && !globalOps.revest) {
    errorSubmit =
      "Este tipo de abertura requiere seleccionar un revestimiento en la categoría Hoja.";
  } else if (saveDesignMutation.isError) {
    errorSubmit = "Ocurrió un error. Por favor inténtelo otra vez.";
  }

  const openingOptions = OPENINGS.filter((op) => !op.hidden).map((op) => {
    return {
      value: op.code,
      label: op.name,
      disabled: op.code === "fijm",
    };
  });

  const isLoading = loadingProducts || loadingDesign;

  return (
    <div className={styles.newDesignRoot}>
      <div className={styles.divLinks}>
        <Link to={AFTER_SAVE_REDIRECT}>
          <Group spacing={4} align="center">
            <BiArrowBack size="20" />
            <Text>Volver a Mis Diseños</Text>
          </Group>
        </Link>
      </div>
      <h2>{pageTitle}</h2>
      {originalName && <p>{`Editando diseño: ${originalName}`}</p>}
      {props.editType === DesignEditType.BaseOnTemplate && (
        <Alert
          mb="xl"
          title="Atención"
          color="yellow"
          icon={<BiError size={32} />}
        >
          Usted está creando un diseño basado en una plantilla. Recuerde
          verificar los productos y descuentos de acuerdo a sus preferencias.
          Una vez guardado, podrá encontrar este diseño en la sección Mis
          Diseños.
        </Alert>
      )}
      <Paper mb={36} p="xl" shadow="xs">
        <Group mb="md" spacing="xl">
          <TextInput
            label="Nombre del diseño"
            size="md"
            sx={{ width: "32em" }}
            maxLength={MAX_NAME_LENGTH}
            value={designName}
            onChange={designNameChangeHandler}
            disabled={isLoading}
          />
          <Select
            label="Tipo de abertura"
            size="md"
            maxDropdownHeight={340}
            data={openingOptions}
            value={openingType.code}
            onChange={openingTypeChangeHandler}
            disabled={isLoading || started || Boolean(designEditId)}
          />
        </Group>
        <Group align="flex-end" spacing="xl">
          <Select
            label="Marca"
            size="md"
            sx={{ width: "15em" }}
            maxDropdownHeight={300}
            data={brandList}
            value={brand}
            onChange={brandChangeHandler}
            disabled={
              isLoading ||
              started ||
              Boolean(designEditId) ||
              brandData.length === 0
            }
          />
          <Select
            label="Línea"
            size="md"
            sx={{ width: "15.5em" }}
            maxDropdownHeight={280}
            data={lineList}
            value={line}
            onChange={lineChangeHandler}
            disabled={isLoading || started || Boolean(designEditId) || !brand}
          />
          <Stack spacing={2}>
            <Text>Hojas</Text>
            <SegmentedControl
              size="md"
              styles={{ control: { width: "5em" } }}
              color="brand"
              data={panelsList}
              value={panelCount.toString()}
              onChange={panelCountChangeHandler}
              disabled={isLoading || panelsList.length < 2}
            />
          </Stack>
          {!started && !designEditId && (
            <Button
              size="md"
              onClick={startClickHandler}
              loading={isLoading}
              disabled={!brand || !line || isLoading}
            >
              Comenzar
            </Button>
          )}
        </Group>
      </Paper>

      {isLoading && (
        <Stack mt={60} align="center">
          <Text size="xl">Cargando productos...</Text>
          <Loader variant="dots" size="xl" />
        </Stack>
      )}
      {productData.length > 0 && (started || designEditId) && !isLoading && (
        <Fragment>
          <Tabs
            variant="outline"
            value={currentCat}
            onTabChange={categoryChangeHandler}
            styles={{
              tab: {
                height: "3em",
                color: "dimgray",
                "&:hover": {
                  backgroundColor: "whitesmoke",
                },
                "&[data-active]": {
                  color: "black",
                  backgroundColor: "white",
                },
              },
            }}
          >
            <Tabs.List position="center">
              {categoryTabs.map((category) => (
                <Tabs.Tab key={category.value} value={category.value}>
                  <Indicator
                    radius="xl"
                    size={14}
                    offset={10}
                    label={category.count > 9 ? "9+" : category.count}
                    disabled={category.count === 0}
                  >
                    <Text mx={28} size="lg" align="center">
                      {category.label}
                    </Text>
                  </Indicator>
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
          <Paper mb={15} p="xl" shadow="xs">
            <Group align="flex-start" noWrap>
              <MediaQuery
                query="(max-width: 1250px)"
                styles={{ width: "250px" }}
              >
                <Stack align="stretch" sx={{ width: "400px" }}>
                  <CatalogueList
                    products={productData.filter((p) => p.cat === currentCat)}
                    selectedProd={selectedProductId}
                    brand={displayBrand}
                    line={displayLine}
                    onSelectionChange={selectionChangeHandler}
                  />
                  <Button
                    size="md"
                    fullWidth
                    onClick={addItemHandler}
                    disabled={!selectedProductId}
                  >
                    + Añadir
                  </Button>
                </Stack>
              </MediaQuery>
              <Stack sx={{ flexGrow: 1 }}>
                <DesignTable
                  items={items.filter((i) => i.cat === currentCat)}
                  caption={tableCaption}
                  openingType={openingType.code}
                  accesoriesTable={currentCat === ProductCategory.ACC}
                  intl={userCtx.data?.intl ?? false}
                  onItemsChange={modifyItemHandler}
                  onItemsRemove={removeItemHandler}
                />
                {currentCat === "mar" && (
                  <Stack>
                    {(!openingType.noMos || openingType.monorail) && (
                      <Group>
                        <Text size="sm">Descuento mosquitero:</Text>
                        <Text>A</Text>
                        <DiffInput
                          width="5em"
                          size="sm"
                          value={globalOps.mosquitoDiff.width}
                          onChange={mosquitoWidthDiffChangeHandler}
                        />
                        <Text>H</Text>
                        <DiffInput
                          width="5em"
                          size="sm"
                          value={globalOps.mosquitoDiff.height}
                          onChange={mosquitoHeightDiffChangeHandler}
                        />
                      </Group>
                    )}
                    {!openingType.noPrem && (
                      <Group>
                        <Text size="sm">Descuento premarco:</Text>
                        <Text>A</Text>
                        <NumberInput
                          sx={{ width: "6em" }}
                          size="sm"
                          icon={<BiPlus size={18} />}
                          min={0}
                          max={500}
                          value={globalOps.premarcDiff.width}
                          onChange={premarcWidthDiffChangeHandler}
                        />
                        <Text>H</Text>
                        <NumberInput
                          sx={{ width: "6em" }}
                          size="sm"
                          icon={<BiPlus size={18} />}
                          min={0}
                          max={500}
                          value={globalOps.premarcDiff.height}
                          onChange={premarcHeightDiffChangeHandler}
                        />
                      </Group>
                    )}
                    {openingType.modularPanel && (
                      <Group>
                        <Text size="sm">Descuento hoja patagónica:</Text>
                        <Text>A</Text>
                        <NumberInput
                          sx={{ width: "6em" }}
                          size="sm"
                          icon={<BiMinus size={18} />}
                          min={0}
                          max={500}
                          value={globalOps.moduleDiff.width}
                          onChange={moduleWidthDiffChangeHandler}
                        />
                        <Text>H</Text>
                        <NumberInput
                          sx={{ width: "6em" }}
                          size="sm"
                          icon={<BiMinus size={18} />}
                          min={0}
                          max={500}
                          value={globalOps.moduleDiff.height}
                          onChange={moduleHeightDiffChangeHandler}
                        />
                      </Group>
                    )}
                  </Stack>
                )}
                {currentCat === "hoj" && (
                  <Stack>
                    {!openingType.noCrossbar && (
                      <Group align="flex-end" spacing="xl">
                        <Select
                          label="Travesaño"
                          size="sm"
                          value={globalOps.crossbar}
                          data={[{ value: "", label: "-" }].concat(
                            productData
                              .filter((p) => p.spc === "tra")
                              .map((p) => {
                                return {
                                  value: p._id,
                                  label: `[${p.code}] ${p.name}`,
                                };
                              })
                          )}
                          onChange={crossbarChangeHandler}
                        />
                        <Stack spacing={0}>
                          <Text size="sm">Descuento travesaños</Text>
                          <Group>
                            <Text>A</Text>
                            <DiffInput
                              width="5em"
                              size="sm"
                              disabled={!globalOps.crossbar}
                              value={globalOps.crossbarDiff.width}
                              onChange={crossbarWidthDiffChangeHandler}
                            />
                            <Text>H</Text>
                            <DiffInput
                              width="5em"
                              size="sm"
                              disabled={!globalOps.crossbar}
                              value={globalOps.crossbarDiff.height}
                              onChange={crossbarHeightDiffChangeHandler}
                            />
                          </Group>
                        </Stack>
                        <Select
                          sx={{ width: "12em" }}
                          size="sm"
                          label="Contravidrios p/ travesaño"
                          data={CVD_PER_CROSSBAR_OPTIONS}
                          value={globalOps.cvdPerCross.toString()}
                          onChange={cvdPerCrossChangeHandler}
                        />
                      </Group>
                    )}
                    <Group align="flex-end" spacing="xl">
                      {!openingType.noRevest && (
                        <Select
                          label={
                            openingType.onlyPost
                              ? "Tablilla postigón"
                              : "Revestimiento"
                          }
                          size="sm"
                          value={globalOps.revest}
                          data={[{ value: "", label: "-" }].concat(
                            productData
                              .filter(
                                (p) =>
                                  (!openingType.onlyPost &&
                                    p.cat === ProductCategory.REVEST) ||
                                  p.cat === ProductCategory.POST
                              )
                              .map((p) => {
                                return {
                                  value: p._id,
                                  label: `[${p.code}] ${p.name}`,
                                };
                              })
                          )}
                          onChange={revestChangeHandler}
                        />
                      )}
                      <Stack spacing={0}>
                        <Text size="sm">
                          {!openingType.noRevest
                            ? "Descuento vidrio/revestimiento"
                            : "Descuento vidrio/policarbonato"}
                        </Text>
                        <Group>
                          <Text>A</Text>
                          <NumberInput
                            sx={{ width: "6.75em" }}
                            size="sm"
                            icon={<BiMinus size={18} />}
                            min={0}
                            max={500}
                            value={globalOps.revestDiff.width}
                            onChange={revestWidthDiffChangeHandler}
                          />
                          <Text>H</Text>
                          <NumberInput
                            sx={{ width: "6.75em" }}
                            size="sm"
                            icon={<BiMinus size={18} />}
                            min={0}
                            max={500}
                            value={globalOps.revestDiff.height}
                            onChange={revestHeightDiffChangeHandler}
                          />
                        </Group>
                      </Stack>
                    </Group>
                  </Stack>
                )}
                {currentCat === "mos" && (
                  <Stack>
                    <Group align="flex-end">
                      <Select
                        label="Travesaño mosquitero"
                        size="sm"
                        value={globalOps.crossbar}
                        data={[{ value: "", label: "-" }].concat(
                          productData
                            .filter((p) => p.cat === ProductCategory.MOSQUITO)
                            .map((p) => {
                              return {
                                value: p._id,
                                label: `[${p.code}] ${p.name}`,
                              };
                            })
                        )}
                        onChange={crossbarChangeHandler}
                      />
                      <NumberInput
                        label="Descuento"
                        sx={{ width: "6em" }}
                        size="sm"
                        icon={<BiMinus size={18} />}
                        min={0}
                        max={500}
                        disabled={!globalOps.crossbar}
                        value={globalOps.crossbarDiff.width}
                        onChange={crossbarWidthDiffChangeHandler}
                      />
                    </Group>
                    <Group>
                      <Text size="sm">Descuento tejido mosquitero:</Text>
                      <Text>A</Text>
                      <NumberInput
                        sx={{ width: "6em" }}
                        size="sm"
                        icon={<BiMinus size={18} />}
                        min={0}
                        max={500}
                        value={globalOps.tejidoDiff.width}
                        onChange={tejidoWidthDiffChangeHandler}
                      />
                      <Text>H</Text>
                      <NumberInput
                        sx={{ width: "6em" }}
                        size="sm"
                        icon={<BiMinus size={18} />}
                        min={0}
                        max={500}
                        value={globalOps.tejidoDiff.height}
                        onChange={tejidoHeightDiffChangeHandler}
                      />
                    </Group>
                    <Group spacing="xs">
                      <BiInfoCircle size="20" />
                      <Text>
                        Los descuentos del marco del mosquitero se toman de la
                        abertura de destino.
                      </Text>
                    </Group>
                  </Stack>
                )}
                {currentCat === "pre" && (
                  <Group spacing="xs">
                    <BiInfoCircle size="20" />
                    <Text>
                      Los descuentos del premarco se toman de la abertura de
                      destino.
                    </Text>
                  </Group>
                )}
                {currentCat === "cor" && (
                  <Stack>
                    <Group>
                      <Text size="sm">Descuento guía cortina:</Text>
                      <NumberInput
                        sx={{ width: "6em" }}
                        size="sm"
                        icon={<BiPlus size={18} />}
                        min={0}
                        max={500}
                        value={globalOps.guiaDiff}
                        onChange={guiaDiffChangeHandler}
                      />
                    </Group>
                    <Group spacing="xs">
                      <BiInfoCircle size="20" />
                      <Text>
                        Las guías de cortina se pueden elegir al momento de
                        hacer el presupuesto.
                      </Text>
                    </Group>
                  </Stack>
                )}
              </Stack>
            </Group>
          </Paper>
          <Group align="center">
            <Group sx={{ flexGrow: 1 }} spacing={4}>
              <Text mt="lg" mr="sm">
                Mano de obra:
              </Text>
              <NumberInput
                sx={{ width: "4.5em" }}
                size="sm"
                min={0}
                max={99}
                label="Horas"
                value={laborEdit.h}
                onChange={(value) =>
                  setLaborEdit((prev) => ({ ...prev, h: value || 0 }))
                }
              />
              <Text mt="lg">:</Text>
              <NumberInput
                sx={{ width: "4.5em" }}
                size="sm"
                min={0}
                max={59}
                label="Minutos"
                value={laborEdit.m}
                onChange={(value) =>
                  setLaborEdit((prev) => ({ ...prev, m: value || 0 }))
                }
              />
              <Tooltip
                position="right"
                width={300}
                multiline
                withArrow
                label="Opcional. Si desea utilizar esta función, recuerde definir el costo de la mano de obra en Configuración."
              >
                <ThemeIcon mt="lg" ml="sm" variant="light" radius="xl">
                  <BiQuestionMark size={18} />
                </ThemeIcon>
              </Tooltip>
            </Group>
            {errorSubmit && (
              <Alert
                mr="xl"
                icon={<BiErrorCircle size="20" />}
                title="No se puede guardar"
                variant="light"
                color="red"
              >
                {errorSubmit}
              </Alert>
            )}
            <Button
              size="lg"
              onClick={saveDesignHandler}
              loading={saveDesignMutation.isLoading}
              disabled={
                designName.trim().length === 0 ||
                designName.length > MAX_NAME_LENGTH ||
                items.length === 0 ||
                items.length > MAX_ITEMS_LENGTH ||
                (openingType.extraPost && !globalOps.revest) ||
                isLoading
              }
            >
              <BiSave size="24" />
              <Text ml="6px">Guardar diseño</Text>
            </Button>
          </Group>
        </Fragment>
      )}
    </div>
  );
};

export default NewDesign;
