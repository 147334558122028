import Product from "../../models/product";

import { useRef, useState } from "react";
import Item from "../../models/item";

import {
  Alert,
  Box,
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Table,
} from "@mantine/core";
import { BiErrorCircle } from "react-icons/bi";
import RemoveButton from "./RemoveButton";

type AcopInputProps = {
  products: Product[];
  table: Item[];
  brand: string;
  onSubmitItem: (item: Item) => void;
  onRemoveItem: (acopId: number) => void;
};

const MAX_ACOP_LENGTH = 6000;

const AcopInput = (props: AcopInputProps) => {
  const { table = [] } = props;

  const inputLengthRef = useRef<HTMLInputElement>(null);
  const inputCountRef = useRef<HTMLInputElement>(null);

  const [selectedAcop, setSelectedAcop] = useState<string>("");

  const buttonAddHandler = () => {
    const prodId = selectedAcop;
    const length = +inputLengthRef.current.value;
    const count = +inputCountRef.current.value;
    if (!prodId || length <= 0 || count <= 0) {
      return;
    }
    props.onSubmitItem({
      prodId: prodId,
      length: length,
      count: count,
    });
  };

  const buttonRemoveHandler = (val: number) => {
    props.onRemoveItem(val);
  };

  const acopDropdownContent = props.products
    .filter((p) => p.brd === props.brand)
    .map((p) => {
      return {
        value: p._id,
        label: `[${p.code}] ${p.name}`,
      };
    });

  return (
    <Stack>
      {table.length > 0 && (
        <Box p="xs" sx={{ width: "800px", backgroundColor: "white" }}>
          <Table striped>
            <thead>
              <tr>
                <th>Código</th>
                <th>Nombre</th>
                <th>Longitud</th>
                <th>Cantidad</th>
                <th>Quitar</th>
              </tr>
            </thead>
            <tbody>
              {table.map((a) => (
                <tr key={a.itemId}>
                  <td>{a.code}</td>
                  <td>{a.name}</td>
                  <td>{a.length}</td>
                  <td>{a.count}</td>
                  <td>
                    <RemoveButton
                      size={20}
                      value={a.itemId}
                      onClick={buttonRemoveHandler}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      )}
      <Group align="end">
        <Select
          label="Acoples"
          size="md"
          maxDropdownHeight={300}
          sx={{ width: "24em" }}
          data={acopDropdownContent}
          value={selectedAcop}
          onChange={(newValue) => setSelectedAcop(newValue)}
          disabled={acopDropdownContent.length === 0}
          searchable
          clearable
        />
        <NumberInput
          size="md"
          sx={{ width: "8em" }}
          min={10}
          max={MAX_ACOP_LENGTH}
          defaultValue={1000}
          label="Longitud (mm)"
          ref={inputLengthRef}
          disabled={acopDropdownContent.length === 0}
        />
        <NumberInput
          size="md"
          sx={{ width: "6em" }}
          min={1}
          max={99}
          defaultValue={1}
          label="Cantidad"
          ref={inputCountRef}
          disabled={acopDropdownContent.length === 0}
        />
        <Button size="md" onClick={buttonAddHandler} disabled={!selectedAcop}>
          + Añadir acople
        </Button>
      </Group>
      <Alert
        icon={<BiErrorCircle size={24} />}
        sx={{ width: "700px" }}
        color="blue"
        title="Nota"
      >
        Tenga en cuenta el espesor del acople para la medida final de la
        abertura.
      </Alert>
    </Stack>
  );
};

export default AcopInput;
