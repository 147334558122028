import { Card, Image, Text } from "@mantine/core";

type FeatureCardProps = {
  image: string;
  text: string;
};

const FeatureCard = (props: FeatureCardProps) => {
  return (
    <Card shadow="xs" bg="brand.0">
      <Card.Section withBorder bg="white">
        <Image src={props.image} withPlaceholder width={400} height={250} />
      </Card.Section>
      <Text p="md" weight="bold">
        {props.text}
      </Text>
    </Card>
  );
};

export default FeatureCard;
