import {
  Checkbox,
  MantineSize,
  Select,
  SelectItem,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";

type ModuleRevestProps = {
  index: number;
  revestProdName: string | undefined;
  revest: string | string[];
  glasses: SelectItem[];
  canHaveSubmodules: boolean;
  vertCrossbarCount: number;
  isCut: boolean;
  onRevestChange: (index: number, value: string | string[]) => void;
  onSubRevestChange: (index: number, subIndex: number, value: string) => void;
};

type SelectRevestProps = {
  index: number;
  label: string;
  data: SelectItem[];
  value: string;
  onChange: (value: string) => void;
  size?: MantineSize;
  maxDropdownHeight?: number;
};

const SelectRevest = (props: SelectRevestProps) => {
  return (
    <Select
      sx={{ width: "15em" }}
      label={`${props.label} ${props.index + 1}`}
      data={props.data}
      size={props.size || "md"}
      value={props.value}
      onChange={props.onChange}
      maxDropdownHeight={props.maxDropdownHeight || 280}
    />
  );
};

const ModuleRevest = (props: ModuleRevestProps) => {
  const { glasses } = props;
  let allGlasses: SelectItem[] = [];

  const revestChangeHandler = (newValue: string | string[]) => {
    props.onRevestChange(props.index, newValue);
  };

  const subRevestChangeHandler = (subIndex: number, newValue: string) => {
    props.onSubRevestChange(props.index, subIndex, newValue);
  };

  const submodulesCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!Array.isArray(props.revest)) {
      props.onRevestChange(
        props.index,
        Array(props.vertCrossbarCount + 1 || 1).fill(props.revest)
      );
    } else {
      props.onRevestChange(props.index, props.revest[0]);
    }
  };

  if (props.revestProdName) {
    allGlasses = glasses.concat({ value: "rev", label: props.revestProdName });
  } else allGlasses = glasses;

  return (
    <Stack
      spacing="xs"
      p={8}
      sx={{ backgroundColor: "whitesmoke", borderRadius: "4px" }}
    >
      {!Array.isArray(props.revest) ? (
        <SelectRevest
          label="Módulo"
          maxDropdownHeight={200}
          index={props.index}
          data={allGlasses}
          value={props.revest}
          onChange={revestChangeHandler}
        />
      ) : (
        <>
          <Text>{`Módulo ${props.index + 1}`}</Text>
          <Stack spacing="xs">
            {props.revest.map((rev, idx) => (
              <SelectRevest
                key={idx}
                label="Submódulo"
                size="sm"
                maxDropdownHeight={200}
                index={idx}
                data={allGlasses}
                value={rev}
                onChange={(value: string) => subRevestChangeHandler(idx, value)}
              />
            ))}
          </Stack>
        </>
      )}
      {props.vertCrossbarCount > 0 &&
        props.canHaveSubmodules &&
        props.isCut && (
          <Checkbox
            label="Submódulos"
            checked={Array.isArray(props.revest)}
            onChange={submodulesCheckedHandler}
          />
        )}
    </Stack>
  );
};

export default ModuleRevest;
