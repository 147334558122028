import {
  Button,
  Group,
  Modal,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import React, { useState } from "react";
import { BiError } from "react-icons/bi";

type AccountDeleteProps = {
  opened: boolean;
  loading: boolean;
  error: boolean;
  onConfirm: (email: string, pass: string) => void;
  onClose: () => void;
};

const AccountDelete = (props: AccountDeleteProps) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const passChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value);
  };

  const confirmHandler = () => {
    props.onConfirm(email, pass);
  };
  const closeHandler = () => {
    setEmail("");
    setPass("");
    props.onClose();
  };

  return (
    <Modal
      opened={props.opened}
      onClose={closeHandler}
      size="lg"
      padding={32}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Stack align="center">
        <Text size="xl" weight="bold">
          Borrar cuenta
        </Text>
        <Text>
          ¿Confirma que desea eliminar su cuenta y todos los datos asociados?
        </Text>
        <Group>
          <ThemeIcon color="red" variant="light" size="xl">
            <BiError size={30} />
          </ThemeIcon>
          <Text size="lg" color="red">
            <strong>Este proceso no puede revertirse.</strong>
          </Text>
          <ThemeIcon color="red" variant="light" size="xl">
            <BiError size={30} />
          </ThemeIcon>
        </Group>

        <Stack my="md">
          <Text>
            Si está seguro, ingrese su correo electrónico y contraseña:
          </Text>
          <TextInput
            placeholder="Correo electrónico"
            value={email}
            onChange={emailChangeHandler}
          />
          <PasswordInput
            placeholder="Contraseña"
            value={pass}
            onChange={passChangeHandler}
          />
        </Stack>

        {props.error && (
          <Text mb="md" color="red">
            Datos incorrectos.
          </Text>
        )}

        <Group>
          <Button
            color="red"
            onClick={confirmHandler}
            loading={props.loading}
            disabled={email === "" || pass === ""}
          >
            Sí, deseo borrar mi cuenta
          </Button>
          <Button
            sx={{ width: "16em" }}
            onClick={closeHandler}
            disabled={props.loading}
          >
            Cancelar
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default AccountDelete;
