import { NavLink, Stack } from "@mantine/core";
import { Link } from "react-router-dom";
import PageWrapper from "../components/common/PageWrapper";

const NotFoundPage = () => {
  return (
    <PageWrapper>
      <Stack spacing="xs" sx={{ width: "fit-content" }}>
        <h1>Página no encontrada</h1>
        <NavLink
          active={true}
          variant="subtle"
          label="Volver al inicio"
          component={Link}
          to="/"
        />
      </Stack>
    </PageWrapper>
  );
};

export default NotFoundPage;
