import { Accordion } from "@mantine/core";

type HelpFaqProps = {
  faq: {
    question: string;
    answer: string;
  }[];
};

const HelpFaq = (props: HelpFaqProps) => {
  return (
    <Accordion
      variant="contained"
      styles={(theme) => ({
        label: { fontWeight: "bold" },
        item: { backgroundColor: "white" },
        content: {
          backgroundColor: theme.colors.brand[0],
          padding: "1em 1em 2em",
        },
      })}
    >
      {props.faq.map((qa, index) => (
        <Accordion.Item key={index} value={String(index)}>
          <Accordion.Control>{qa.question}</Accordion.Control>
          <Accordion.Panel>{qa.answer}</Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default HelpFaq;
