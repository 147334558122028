import {
  ActionIcon,
  Group,
  Select,
  SpacingValue,
  SystemProp,
  Tooltip,
} from "@mantine/core";
import { BiFilter, BiX } from "react-icons/bi";
import BrandLines from "../../models/brand-lines";
import { OPENINGS } from "../../utils/globalConstants";

export type DesignFilterValues = {
  brand?: string;
  line?: string;
  type?: string;
};

type DesignFilterProps = {
  className?: string;
  filter: DesignFilterValues;
  brands: BrandLines[];
  onChange: (values: DesignFilterValues) => void;
  onClear: () => void;
  disableBrandLine?: boolean;
  leftIcon?: boolean;
  filledBg?: boolean;
  marginSides?: SystemProp<SpacingValue>;
  marginBottom?: SystemProp<SpacingValue>;
};

const selectStyles = {
  input: {
    "::placeholder": {
      color: "gray",
    },
  },
};

const DesignFilter = (props: DesignFilterProps) => {
  const brandList = props.brands.map((b) => {
    return { value: b.code, label: b.name };
  });
  const lineList = props.filter.brand
    ? props.brands
        .find((b) => b.code === props.filter.brand)
        .lines.map((b) => {
          return { value: b.code, label: b.name };
        })
    : [];

  return (
    <Group
      mb={props.marginBottom}
      mx={props.marginSides}
      px="xl"
      py="sm"
      position="center"
      spacing="xs"
      noWrap
      sx={(theme) => ({
        backgroundColor: props.filledBg ? theme.colors.brand[2] : "white",
        borderRadius: props.filledBg ? "4px" : null,
        width: "fit-content",
      })}
    >
      {props.leftIcon !== false && <BiFilter size={24} />}
      <Select
        size="sm"
        placeholder="Marca"
        data={brandList}
        value={props.filter.brand}
        sx={{ maxWidth: "12em" }}
        onChange={(value: string) => props.onChange({ brand: value, line: "" })}
        clearable={!props.disableBrandLine}
        disabled={props.disableBrandLine}
        styles={selectStyles}
      />
      <Select
        size="sm"
        placeholder="Línea"
        data={lineList}
        value={props.filter.line}
        sx={{ maxWidth: "12em" }}
        onChange={(value: string) => props.onChange({ line: value })}
        clearable={!props.disableBrandLine}
        disabled={lineList.length === 0 || props.disableBrandLine}
        styles={selectStyles}
      />
      <Select
        size="sm"
        placeholder="Tipo"
        data={OPENINGS.filter((op) => !op.hidden).map((op) => {
          return { value: op.code, label: op.name };
        })}
        value={props.filter.type}
        sx={{ maxWidth: "12em" }}
        onChange={(value: string) => props.onChange({ type: value })}
        clearable
        styles={selectStyles}
      />
      <Tooltip
        label="Quitar filtros"
        position="right"
        transitionProps={{ transition: "fade" }}
        withArrow
      >
        <ActionIcon
          color="white"
          radius="lg"
          variant="subtle"
          onClick={props.onClear}
          disabled={
            !props.filter.brand && !props.filter.line && !props.filter.type
          }
        >
          <BiX size={24} />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default DesignFilter;
