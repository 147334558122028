import { createContext, ReactNode, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import UserData from "../models/user-data";
import { useQuery, useQueryClient } from "react-query";
import { getUserData } from "../services/services";

const AFTER_LOGIN_REDIRECT = "/quote";
const AFTER_LOGIN_FIRST_TIME = "/help";
const AFTER_LOGIN_TERMS = "/terms";
const AFTER_LOGOUT_REDIRECT = "/login";

export interface IUserContext {
  token: string | undefined;
  data: UserData | undefined;
  loggedIn: boolean;
  isGuest: boolean;
  isAdmin: boolean;
  editData: (newData: UserData) => void;
  onLogin: (token: string, data: UserData) => void;
  onLogout: () => void;
}

type ContextProps = {
  children: ReactNode;
};
type UserState = {
  data?: UserData;
  token?: string;
  loggedIn: boolean;
  guest: boolean;
  admin: boolean;
};

type TokenData = {
  exp: number;
  iat: number;
  userId: string;
};

const UserContext = createContext<IUserContext | null>(null);

const STORAGE_NAME = "user";

export const UserContextProvider = (props: ContextProps) => {
  const queryClient = useQueryClient();

  const [foundToken, setFoundToken] = useState<string>(undefined);
  const [userState, setUserState] = useState<UserState>({
    data: undefined,
    token: undefined,
    loggedIn: false,
    guest: false,
    admin: false,
  });

  const navigate = useNavigate();
  useQuery("user-data", () => getUserData(foundToken), {
    enabled: !!foundToken,
    onSuccess: (response) => {
      if (response) {
        setFoundToken(undefined);
        setUserState({
          loggedIn: true,
          token: foundToken,
          data: response,
          guest: isGuest(response.role),
          admin: isAdmin(response.role),
        });
      }
    },
  });

  const parseTokenDate = (numericDate: number) => {
    return new Date(numericDate * 1000);
  };

  const loginHandler = (token: string, data: UserData) => {
    localStorage.setItem(STORAGE_NAME, token);
    setUserState({
      loggedIn: true,
      token,
      data,
      guest: isGuest(data.role),
      admin: isAdmin(data.role),
    });
    if (data.terms === true) {
      if (data.firstTime === true) {
        navigate(AFTER_LOGIN_REDIRECT);
      } else {
        navigate(AFTER_LOGIN_FIRST_TIME);
      }
    } else {
      navigate(AFTER_LOGIN_TERMS);
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem(STORAGE_NAME);
    setUserState({
      loggedIn: false,
      token: undefined,
      data: undefined,
      guest: false,
      admin: false,
    });
    navigate(AFTER_LOGOUT_REDIRECT);
    queryClient.removeQueries();
  };

  const editDataHandler = (newData: UserData) => {
    setUserState((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          ...newData,
          config: { ...prev.data.config, ...newData.config },
        },
      };
    });
  };

  const isGuest = (role: string) => {
    return role === "guest";
  };

  const isAdmin = (role: string) => {
    return role === "admin";
  };

  useEffect(() => {
    const storedToken = localStorage.getItem(STORAGE_NAME);
    if (storedToken) {
      const storedTokenData: TokenData = jwtDecode(storedToken);
      // Check stored token expiration date
      if (parseTokenDate(storedTokenData.exp) > new Date())
        setFoundToken(storedToken);
      else localStorage.removeItem(STORAGE_NAME);
    }
  }, []);

  const contextValue: IUserContext = {
    data: userState.data,
    token: userState.token,
    loggedIn: userState.loggedIn,
    isGuest: userState.guest,
    isAdmin: userState.admin,
    editData: editDataHandler,
    onLogin: loginHandler,
    onLogout: logoutHandler,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
