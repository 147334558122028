import {
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import React, { Fragment } from "react";

const MODE_NONE = "";
const MODE_CENTERED = "center";
const MODE_MANUAL = "manual";

const CUT_NONE = 0;
const CUT_SUP = 1;
const CUT_INF = -1;

type CrossbarSelectorProps = {
  label: string;
  canBeCut?: boolean;
  disabled?: boolean;
  maxCut?: number;

  crossbarMode: string;
  crossbarValue?: number | number[];
  cutValue?: number;
  onModeChange: (value: string) => void;
  onValueChange: (value: number | number[]) => void;
  onCutChange?: (value: number) => void;
  onCutLengthChange?: (value: number) => void;
};

const CrossbarSelector = (props: CrossbarSelectorProps) => {
  const { crossbarMode: mode } = props;
  const cut =
    props.cutValue && props.cutValue !== 0
      ? props.cutValue > 0
        ? CUT_SUP
        : CUT_INF
      : CUT_NONE;

  const selectChangeHandler = (newValue: string) => {
    props.onModeChange(newValue);
    props.onValueChange(undefined);
    if (props.onCutChange && newValue === MODE_NONE) {
      props.onCutChange(CUT_NONE);
    }
  };
  const manualInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const values = e.target.value
      .trim()
      .replace("-", "")
      .split(",")
      .map((v) => (v ? +v : undefined));

    if (values.length === 0) {
      return props.onValueChange(undefined);
    }
    return props.onValueChange(values);
  };
  const centeredInputChangeHandler = (val: number | undefined) => {
    const clamped = val || 1;
    return props.onValueChange(clamped);
  };
  const verticalCutChangeHandler = (newValue: string) => {
    return props.onCutChange(+newValue);
  };
  const verticalLengthChangeHandler = (val: number | undefined) => {
    const clamped = val || 1;
    return props.onCutLengthChange(clamped);
  };

  return (
    <Stack spacing={0}>
      <Text>{props.label}</Text>
      <Group align="flex-end">
        <Select
          mt="xs"
          sx={{ width: "8em" }}
          size="md"
          data={[
            { value: MODE_NONE, label: "No" },
            { value: MODE_CENTERED, label: "Centrados" },
            { value: MODE_MANUAL, label: "Manual" },
          ]}
          value={mode}
          onChange={selectChangeHandler}
          disabled={props.disabled}
        />
        {mode === MODE_CENTERED && (
          <NumberInput
            label="Cantidad"
            sx={{ width: "7em" }}
            styles={{
              label: {
                fontSize: "12px",
              },
            }}
            size="md"
            min={1}
            max={4}
            value={
              typeof props.crossbarValue === "number"
                ? props.crossbarValue
                : undefined
            }
            onChange={centeredInputChangeHandler}
          />
        )}
        {mode === MODE_MANUAL && (
          <TextInput
            label="Posiciones (mm)"
            sx={{ width: "12em" }}
            styles={{
              label: {
                fontSize: "12px",
              },
            }}
            size="md"
            placeholder="Ej.: 250,500,750"
            maxLength={20}
            value={
              Array.isArray(props.crossbarValue)
                ? props.crossbarValue.join(",")
                : ""
            }
            onChange={manualInputChangeHandler}
          />
        )}
        {mode !== MODE_NONE && props.canBeCut && (
          <Fragment>
            <Select
              label="Corte"
              sx={{ width: "8em" }}
              styles={{
                label: {
                  fontSize: "12px",
                },
              }}
              size="md"
              data={[
                { value: CUT_NONE.toString(), label: "Entero" },
                { value: CUT_SUP.toString(), label: "Superior" },
                { value: CUT_INF.toString(), label: "Inferior" },
              ]}
              value={cut.toString()}
              onChange={verticalCutChangeHandler}
            />
            {cut !== CUT_NONE && (
              <NumberInput
                label="Longitud (módulos)"
                sx={{ width: "8em" }}
                styles={{
                  label: {
                    fontSize: "12px",
                  },
                }}
                size="md"
                min={1}
                max={props.maxCut ?? 4}
                value={props.cutValue ? Math.abs(props.cutValue) : undefined}
                onChange={verticalLengthChangeHandler}
                disabled={!props.maxCut}
              />
            )}
          </Fragment>
        )}
      </Group>
    </Stack>
  );
};

export default CrossbarSelector;
