import { Fragment, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import MainFooter from "./components/Layout/MainFooter";
import MainHeader from "./components/Layout/MainHeader";
import QuotePage from "./pages/QuotePage";
import HomePage from "./pages/HomePage";
import AccountPage from "./pages/AccountPage";
import DesignPage from "./pages/DesignPage";
import ConfigPage from "./pages/ConfigPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import NotFoundPage from "./pages/NotFoundPage";

import UserContext from "./store/user-context";
import InactivePage from "./pages/InactivePage";
import MaintenancePage from "./pages/MaintenancePage";
import SiteConfigContext from "./store/site-config-context";
import HelpPage from "./pages/HelpPage";
import TermsPage from "./pages/TermsPage";
import HistoryPage from "./pages/HistoryPage";
import AccountWarning from "./components/common/AccountWarning";

const paths = {
  home: "/",
  quote: "/quote",
  design: "/design",
  history: "/history",
  help: "/help",
  login: "/login",
  signup: "/signup",
  account: "/account",
  config: "/config",
  inactive: "/inactive",
  terms: "/terms",
};

const PHONE = process.env.REACT_APP_CONTACT_PHONE;
const WARNING_DATE = Number(process.env.REACT_APP_WARNING_DATE);
const DUE_DATE = Number(process.env.REACT_APP_DUE_DATE);

function App() {
  const userCtx = useContext(UserContext);
  const siteConfigCtx = useContext(SiteConfigContext);

  const [accountWarningModal, setAccountWarningModal] = useState(false);

  useEffect(() => {
    if (userCtx.data && !userCtx.data.intl) {
      const todayDate = new Date().getDate();
      if (todayDate >= WARNING_DATE && todayDate < DUE_DATE)
        setAccountWarningModal(userCtx.data.active && !userCtx.data.monthPaid);
    }
  }, [userCtx.data]);

  let routes;
  if (userCtx.loggedIn) {
    if (userCtx.data.active) {
      routes = (
        <Fragment>
          <Route path={paths.quote + "/*"} element={<QuotePage />} />
          <Route path={paths.design + "/*"} element={<DesignPage />} />
          <Route path={paths.history} element={<HistoryPage />} />
          <Route path={paths.config} element={<ConfigPage />} />
          <Route path={paths.account} element={<AccountPage />} />
          <Route path={paths.help} element={<HelpPage />} />
          <Route path={paths.terms} element={<TermsPage />} />
        </Fragment>
      );
    } else {
      routes = (
        <Fragment>
          <Route path={paths.quote} element={<InactivePage />} />
          <Route path={paths.design} element={<InactivePage />} />
          <Route path={paths.history} element={<InactivePage />} />
          <Route path={paths.config} element={<InactivePage />} />
          <Route path={paths.account} element={<AccountPage />} />
          <Route path={paths.help} element={<HelpPage />} />
          <Route path={paths.terms} element={<TermsPage />} />
        </Fragment>
      );
    }
  } else {
    routes = (
      <Fragment>
        <Route path={paths.signup} element={<SignupPage />} />
        <Route path={paths.login} element={<LoginPage />} />
      </Fragment>
    );
  }

  return (
    <div className="App">
      <MainHeader paths={paths} maintenance={siteConfigCtx.maintenance} />
      <main>
        <AccountWarning
          opened={accountWarningModal}
          onClose={() => setAccountWarningModal(false)}
          adminPhone={PHONE || ""}
          userEmail={userCtx?.data?.email || ""}
          isGuest={userCtx?.isGuest}
        />
        <Routes>
          {siteConfigCtx.maintenance && (
            <Route path="*" element={<MaintenancePage />} />
          )}
          {!siteConfigCtx.maintenance && (
            <Fragment>
              <Route path={paths.home} element={<HomePage />} />
              {routes}
              <Route path="*" element={<NotFoundPage />} />
            </Fragment>
          )}
        </Routes>
      </main>
      <MainFooter />
    </div>
  );
}

export default App;
