import styles from "./MainFooter.module.css";

const MainFooter = () => {
  return (
    <footer className={styles.footer}>
      <p>AluDig - 2024</p>
    </footer>
  );
};

export default MainFooter;
