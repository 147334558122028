import { Paper, Text, Title } from "@mantine/core";

type PaperConfigSectionProps = {
  title: string;
  description: string;
  children: React.ReactNode;
};

const PaperConfigSection = (props: PaperConfigSectionProps) => {
  return (
    <Paper
      p="xl"
      sx={{ margin: "0 auto", minWidth: "850px", maxWidth: "1000px" }}
    >
      <>
        <Title order={2}>{props.title}</Title>
        <Text mb="xl" color="gray">
          {props.description}
        </Text>
        {props.children}
      </>
    </Paper>
  );
};

export default PaperConfigSection;
