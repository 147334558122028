import { Box } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import UnifiedAddButton from "./UnifiedAddButton";
import UnifiedGridBlock from "./UnifiedGridBlock";

export type UnifiedGridItem = {
  designId: string;
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  text?: string;
};

type UnifiedGridLayoutProps = {
  items: UnifiedGridItem[];
  designs: any[];
  drawScale: number;
  onItemAdd: (
    designId: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) => void;
  onItemRemove: (id: string) => void;
};

const UnifiedGridLayout = (props: UnifiedGridLayoutProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(null);
  const [selectedFreeSides, setSelectedFreeSides] = useState<string[]>([]);

  const checkFreePoint = useCallback(
    (x: number, y: number) => {
      return !props.items.some(
        (item) =>
          x >= item.x &&
          x < item.x + item.w &&
          y >= item.y &&
          y < item.y + item.h
      );
    },
    [props.items]
  );

  const selectHandler = useCallback(
    (id: string) => {
      const selected = props.items.find((item) => item.id === id);
      if (!selected) return;

      const free = [];
      // Left
      if (
        checkFreePoint(selected.x - 1, selected.y) &&
        checkFreePoint(selected.x - 1, selected.y + selected.h - 1)
      )
        free.push("left");
      // Right
      if (
        checkFreePoint(selected.x + selected.w + 1, selected.y) &&
        checkFreePoint(selected.x + selected.w + 1, selected.y + selected.h - 1)
      )
        free.push("right");
      // Top
      if (
        checkFreePoint(selected.x, selected.y - 1) &&
        checkFreePoint(selected.x + selected.w - 1, selected.y - 1)
      )
        free.push("top");
      // Bottom
      if (
        checkFreePoint(selected.x, selected.y + selected.h + 1) &&
        checkFreePoint(selected.x + selected.w - 1, selected.y + selected.h + 1)
      )
        free.push("bottom");

      setSelectedItem(id);
      setSelectedFreeSides(free);
    },
    [props.items, checkFreePoint]
  );

  const addHandler = (
    designId: string,
    width: number,
    height: number,
    x: number = 0,
    y: number = 0
  ) => {
    props.onItemAdd(designId, x, y, width, height);
  };

  const removeHandler = (id: string) => {
    if (selectedItem === id) setSelectedItem(null);
    props.onItemRemove(id);
  };

  useEffect(() => {
    if (props.items.length > 0) {
      const length = props.items.length;
      selectHandler(props.items[length - 1].id);
    }
  }, [props.items, selectHandler]);

  let totalWidth = 100;
  let totalHeight = 100;
  props.items.forEach((item) => {
    const xw = item.x + item.w;
    const yh = item.y + item.h;
    if (xw > totalWidth) totalWidth = xw;
    if (yh > totalHeight) totalHeight = yh;
  });

  return (
    <>
      {props.items.length > 0 ? (
        <Box
          style={{
            position: "relative",
            marginLeft: `-${totalWidth * props.drawScale}px`,
            marginTop: `-${totalHeight * props.drawScale}px`,
          }}
        >
          {props.items.map((item) => (
            <UnifiedGridBlock
              key={item.id}
              id={item.id}
              x={item.x}
              y={item.y}
              w={item.w}
              h={item.h}
              designs={props.designs}
              scale={props.drawScale}
              selected={item.id === selectedItem}
              freeSides={item.id === selectedItem ? selectedFreeSides : []}
              text={item.text}
              onSelect={selectHandler}
              onAdd={addHandler}
              onRemove={removeHandler}
            />
          ))}
        </Box>
      ) : (
        <UnifiedAddButton
          position="none"
          side="none"
          designs={props.designs}
          onAddFirst={addHandler}
        />
      )}
    </>
  );
};

export default UnifiedGridLayout;
