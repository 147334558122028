import { ScrollArea, Table } from "@mantine/core";
import { useEffect, useRef } from "react";

import Item from "../../models/item";
import DesignItem from "./DesignItem";

import styles from "./DesignTable.module.css";
import { ProductCategory } from "../../utils/globalConstants";

type DesignTableProps = {
  items: Item[];
  openingType: string;
  caption: string | JSX.Element;
  accesoriesTable: boolean;
  intl?: boolean;
  onItemsChange: (itemId: number, values: {}) => void;
  onItemsRemove: (itemId: number) => void;
};

const DOOR_CATS = [
  ProductCategory.FRAME,
  ProductCategory.PANEL,
  ProductCategory.TAPAJUN,
];
const DOOR_OPENINGS = ["pcor", "preb"];

const DesignTable = (props: DesignTableProps) => {
  const tableViewportRef = useRef<HTMLDivElement>();
  const currentRows = useRef<number>(0);

  const designItemChangeHandler = (itemId: number, values: {}) => {
    props.onItemsChange(itemId, values);
  };

  const designItemRemoveHandler = (itemId: number) => {
    props.onItemsRemove(itemId);
  };

  useEffect(() => {
    if (props.items.length > currentRows.current) {
      tableViewportRef.current.scrollTo({
        top: tableViewportRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    currentRows.current = props.items.length;
  }, [props.items.length]);

  return (
    <div className={styles.divDesignTable}>
      <ScrollArea sx={{ height: "390px" }} viewportRef={tableViewportRef}>
        <Table className={styles.designTable} verticalSpacing={4}>
          <thead>
            <tr>
              <th>Código</th>
              <th>Producto</th>
              <th>Orientación</th>
              <th>{!props.accesoriesTable ? "Descuento" : "Longitud"}</th>
              {!props.accesoriesTable && <th>Corte</th>}
              {!props.accesoriesTable && <th>Kg/m</th>}
              <th>Cant.</th>
              <th>Quitar</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item) => (
              <DesignItem
                key={item.itemId}
                item={item}
                acc={props.accesoriesTable}
                isDoor={
                  DOOR_OPENINGS.includes(props.openingType) &&
                  DOOR_CATS.includes(item.cat as ProductCategory)
                }
                hLetter={props.intl ? "X" : "A"}
                vLetter={props.intl ? "Y" : "H"}
                onItemChange={designItemChangeHandler}
                onItemRemove={designItemRemoveHandler}
              />
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </div>
  );
};

export default DesignTable;
