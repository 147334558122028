import React from "react";

type LogoProps = {
  className?: string;
  width?: string;
};

const Logo = (props: LogoProps) => {
  return (
    <div style={{ width: props.width }}>
      <svg
        enableBackground="new 0 0 1080 314.12"
        version="1.1"
        viewBox="0 0 1080 314.12"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style type="text/css">
          {`.st0{fill:#5A6E8F;}
        .st1{fill:#ADBACE;}`}
        </style>

        <polygon
          className="st0"
          points="111.46 92.85 111.46 203.03 79.74 203.03 79.74 61.13 221.64 61.13 221.64 92.85"
        />
        <path
          className="st0"
          d="m220.14 62.63v28.72h-110.18v110.18h-28.72v-138.9h138.9m3-3h-144.9v144.9h34.72v-110.18h110.18z"
        />
        <path
          className="st1"
          d="m213.74 106.83h-88.3v144.91h144.9v-144.91zm21.88 110.18h-75.46v-75.47h75.47v75.47z"
        />
        <path
          className="st0"
          d="m326.2 232.26 44.53-141.7h61.35l44.33 141.7h-42.95l-6.73-21.57h-50.86l-6.73 21.57zm57.98-51.26h34.43l-16.23-55.02h-1.98z"
        />
        <path className="st0" d="m487.1 232.26v-147.63h37.01v147.64h-37.01z" />
        <path
          className="st0"
          d="m644.04 130.14v102.12h-37.01v-15.04h-1.98c-8.11 12.07-16.62 17.41-28.7 17.41-21.18 0-36.02-17.02-36.02-40.77v-63.72h37.01v56.8c0 9.89 5.54 16.82 14.25 16.82s15.44-7.13 15.44-17.42v-56.21h37.01z"
        />
        <path
          className="st1"
          d="m664.04 232.26v-141.7h57.79c48.68 0 81.14 28.3 81.14 70.85s-32.46 70.85-81.14 70.85zm40.38-31.47h17.42c23.75 0 39.38-15.44 39.38-39.38 0-23.95-15.64-39.38-39.38-39.38h-17.42z"
        />

        <path
          className="st1"
          d="m815.65 98.68c0-11.88 8.91-20.19 20.98-20.19 12.47 0 21.18 8.31 21.18 20.19 0 11.87-8.71 20.38-21.18 20.38-12.07 0-20.98-8.51-20.98-20.38zm2.57 133.58v-102.12h37.01v102.12z"
        />

        <path
          className="st1"
          d="m869.48 242.95 35.62-4.95c2.38 8.71 9.9 14.05 19.79 14.05 12.86 0 20.98-8.71 20.98-21.77v-10.49h-1.98c-7.52 8.51-16.82 13.26-29.69 13.26-26.71 0-45.12-19.99-45.12-51.85 0-33.84 18.21-53.43 44.92-53.43 14.05 0 23.75 5.34 31.27 15.04h1.98v-12.67h35.62v101.52c0 30.87-21.57 48.69-56.21 48.69-32.04 0-53.42-13.26-57.18-37.4zm77.98-61.75c0-13.06-8.71-22.56-20.58-22.56-12.07 0-20.58 9.5-20.58 22.56 0 12.86 8.51 22.17 20.58 22.17 11.87 0 20.58-9.3 20.58-22.17z"
        />
      </svg>
    </div>
  );
};

export default Logo;
