import {
  Button,
  CloseButton,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import { BiDollar, BiEdit, BiSearchAlt2 } from "react-icons/bi";
import Product from "../../models/product";
import { formatCurrencyDisplay } from "../../utils/amountFormat";
import { normalizeText } from "../../utils/stringConversion";
import PrintSafeTextInput from "../common/PrintSafeTextInput";

type CustomProductTableProps = {
  products: Product[];
  roundCosts: boolean;
  onCustomSave: (id: string, prod: any) => Promise<Product>;
  onPercentageApply?: (percentage: number) => Promise<boolean>;
  mt?: number;
  showPercentageButton?: boolean;
  usd?: boolean;
  displayUnits?: boolean;
  fixedUnits?: string;
  hideCost?: boolean;
  showSpecialTag?: boolean;
  showColor?: boolean;
};

const MIN_PERCENTAGE = -100;
const MAX_PERCENTAGE = 100;
const SPC_CAMERA = "cam";

const CustomProductTable = (props: CustomProductTableProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const [selectedProd, setSelectedProd] = useState<Product>(null);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalPercentageOpen, setModalPercentageOpen] =
    useState<boolean>(false);

  const [editPercentage, setEditPercentage] = useState<number>(undefined);

  const [editCode, setEditCode] = useState<string>("");
  const [editName, setEditName] = useState<string>("");
  const [editPrice, setEditPrice] = useState<number>(0);
  const [editPriceTwo, setEditPriceTwo] = useState<number>(0);

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setSelectedProd(null);
  }, [props.products, searchText]);

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const rowClickHandler = (prod: Product) => {
    setSelectedProd(prod);
    // setEditCode(prod.code);
    // setEditName(prod.name);
    // setEditPrice(prod.pri);
  };

  const percentageButtonHandler = () => {
    setError(false);
    setEditPercentage(undefined);
    setModalPercentageOpen(true);
  };
  const editButtonHandler = () => {
    setEditCode(selectedProd.code);
    setEditName(selectedProd.name);
    setEditPrice(selectedProd.pri ?? 0);
    setEditPriceTwo(selectedProd.pri2 ?? 0);
    setError(false);
    setModalEditOpen(true);
  };

  const codeChangeHandler = (value: string) => {
    setEditCode(value);
  };
  const nameChangeHandler = (value: string) => {
    setEditName(value);
  };
  const priceChangeHandler = (value: number) => {
    setEditPrice(value);
  };
  const priceTwoChangeHandler = (value: number) => {
    setEditPriceTwo(value);
  };

  const saveButtonHandler = async () => {
    const editedData = {
      code: editCode !== selectedProd.code ? editCode : undefined,
      name: editName !== selectedProd.name ? editName : undefined,
      pri: editPrice !== selectedProd.pri ? editPrice : undefined,
      pri2:
        (editPriceTwo || editPriceTwo === 0) &&
        editPriceTwo !== selectedProd.pri2
          ? editPriceTwo
          : undefined,
    };
    if (Object.values(editedData).every((x) => x === undefined)) {
      return;
    }
    const processedEditedData = JSON.parse(JSON.stringify(editedData));
    setSaving(true);
    const saveResponse = await props.onCustomSave(
      selectedProd._id,
      processedEditedData
    );
    if (saveResponse) {
      setModalEditOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  const applyPercentageHandler = async () => {
    setSaving(true);
    const saveResponse = await props.onPercentageApply(editPercentage);
    if (saveResponse) {
      setModalPercentageOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  const lowercaseSearch = normalizeText(searchText);
  const filteredData = lowercaseSearch
    ? props.products.filter(
        (p) =>
          normalizeText(p.name).includes(lowercaseSearch) ||
          p.code.toLowerCase().includes(lowercaseSearch)
      )
    : props.products;

  return (
    <Stack>
      <Modal
        title="Editar producto"
        size="54em"
        padding="xl"
        opened={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Stack spacing={0}>
          <Group align="start" noWrap>
            <PrintSafeTextInput
              label="Código"
              minLength={1}
              maxLength={16}
              value={editCode}
              onChange={(value: string) => codeChangeHandler(value)}
              sx={{ maxWidth: "10em" }}
              disabled={saving}
            />
            <PrintSafeTextInput
              label="Nombre"
              minLength={3}
              maxLength={64}
              value={editName}
              onChange={(value: string) => nameChangeHandler(value)}
              sx={{ flex: 1 }}
              disabled={saving}
            />
            {!props.hideCost && selectedProd && selectedProd.spc !== "pvc" && (
              <NumberInput
                label="Costo"
                icon={<BiDollar size="20" />}
                min={0}
                max={999999}
                precision={props.roundCosts ? 0 : 2}
                decimalSeparator=","
                value={editPrice}
                onChange={priceChangeHandler}
                sx={{ maxWidth: "10em" }}
                disabled={saving}
              />
            )}
          </Group>
          {!props.hideCost &&
            (props.displayUnits || props.fixedUnits) &&
            selectedProd &&
            selectedProd.spc !== "pvc" && (
              <Group mt={0} position="right">
                <Text>
                  {props.fixedUnits
                    ? `/${props.fixedUnits}`
                    : selectedProd.ori === "m"
                    ? "/metro"
                    : "/unidad"}
                </Text>
              </Group>
            )}
          {selectedProd && selectedProd.spc === SPC_CAMERA && (
            <>
              <Group position="right">
                <Text>Cámara</Text>
                <NumberInput
                  icon={<BiDollar size="20" />}
                  min={0}
                  max={999999}
                  precision={props.roundCosts ? 0 : 2}
                  decimalSeparator=","
                  value={editPriceTwo}
                  onChange={priceTwoChangeHandler}
                  sx={{ maxWidth: "10em" }}
                  disabled={saving}
                />
              </Group>
              <Group position="right">/m</Group>
            </>
          )}
          <Group mt={16} position="right">
            <Button
              sx={{ width: "10em" }}
              onClick={saveButtonHandler}
              loading={saving}
              disabled={!editCode || !editName}
            >
              Guardar
            </Button>
            <Button
              sx={{ width: "8em" }}
              variant="outline"
              onClick={() => setModalEditOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <Modal
        size="lg"
        padding="xl"
        opened={modalPercentageOpen}
        onClose={() => setModalPercentageOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Stack align="center">
          <Text>{`Ingrese un porcentaje para modificar en conjunto el costo de todos los productos de la tabla actual. Puede ingresar un porcentaje negativo.`}</Text>
          <NumberInput
            sx={{ width: "7em" }}
            step={1}
            min={MIN_PERCENTAGE}
            max={MAX_PERCENTAGE}
            rightSection={<AiOutlinePercentage color="gray" />}
            value={editPercentage}
            onChange={(value: number) => setEditPercentage(value)}
            disabled={saving}
          />
          <Group mt="sm">
            <Button
              sx={{ width: "10em" }}
              loading={saving}
              disabled={
                !editPercentage ||
                editPercentage < MIN_PERCENTAGE ||
                editPercentage > MAX_PERCENTAGE ||
                editPercentage === 0
              }
              onClick={applyPercentageHandler}
            >
              Confirmar
            </Button>
            <Button
              sx={{ width: "10em" }}
              variant="outline"
              onClick={() => setModalPercentageOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <ScrollArea
        mt={props.mt}
        style={{ height: "400px", border: "1px solid darkgray" }}
        styles={{
          scrollbar: { paddingTop: "38px", backgroundColor: "transparent" },
        }}
      >
        <Table color="brand" highlightOnHover sx={{ userSelect: "none" }}>
          <thead
            style={{
              position: "sticky",
              top: "0px",
              backgroundColor: "var(--secondary)",
            }}
          >
            <tr>
              <th>Código</th>
              <th>Nombre</th>
              {props.showSpecialTag && <th></th>}
              {props.showColor && <th>Muestra</th>}
              {!props.hideCost && (
                <>
                  <th>Costo</th>
                  {(props.displayUnits || props.fixedUnits) && <th></th>}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((prod) => (
              <tr
                key={prod._id}
                onClick={() => rowClickHandler(prod)}
                style={{
                  backgroundColor:
                    selectedProd && prod._id === selectedProd._id
                      ? "lightblue"
                      : prod.pri === 0
                      ? "lightgray"
                      : "white",
                }}
              >
                <td width="120px">{prod.code}</td>
                <td>
                  {prod.spc === SPC_CAMERA ? (
                    <Stack spacing={0}>
                      <span>{prod.name}</span>
                      <span>Cámara</span>
                    </Stack>
                  ) : (
                    <span>{prod.name}</span>
                  )}
                </td>
                {props.showSpecialTag && (
                  <td width="200px">{prod.spc?.toUpperCase()}</td>
                )}
                {props.showColor && (
                  <td>
                    <div
                      style={{
                        width: "48px",
                        height: "16px",
                        backgroundColor: prod.col,
                        border: "1px solid black",
                      }}
                    ></div>
                  </td>
                )}
                {!props.hideCost && (
                  <>
                    <td width="60px">
                      {prod.spc !== "pvc" && (
                        <>
                          {prod.spc === SPC_CAMERA ? (
                            <Stack spacing={0}>
                              <span>
                                {formatCurrencyDisplay(
                                  prod.pri ?? 0,
                                  props.usd ?? false
                                )}
                              </span>
                              <span>
                                {formatCurrencyDisplay(
                                  prod.pri2 ?? 0,
                                  props.usd ?? false
                                )}
                              </span>
                            </Stack>
                          ) : (
                            <span>
                              {formatCurrencyDisplay(
                                prod.pri ?? 0,
                                props.usd ?? false
                              )}
                            </span>
                          )}
                        </>
                      )}
                    </td>
                    {(props.displayUnits || props.fixedUnits) && !prod.spc && (
                      <td width="80px" style={{ verticalAlign: "top" }}>
                        {props.fixedUnits
                          ? `/${props.fixedUnits}`
                          : prod.ori === "m"
                          ? "/m"
                          : "/unidad"}
                      </td>
                    )}
                    {prod.spc && <td></td>}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
      <Group>
        <TextInput
          value={searchText}
          onChange={searchChangeHandler}
          maxLength={40}
          icon={<BiSearchAlt2 size={24} />}
          rightSection={
            searchText ? (
              <CloseButton
                radius="xl"
                size="md"
                onClick={() => setSearchText("")}
              />
            ) : null
          }
          size="sm"
          sx={{ width: "20em" }}
        />

        <Group position="right" sx={{ flexGrow: 1 }}>
          {!props.hideCost && props.showPercentageButton && (
            <Button variant="outline" onClick={percentageButtonHandler}>
              Modificar costos con porcentaje
            </Button>
          )}
          <Button
            sx={{ width: "10em" }}
            onClick={editButtonHandler}
            disabled={!selectedProd}
          >
            <BiEdit size={20} />
            <Text size="sm" ml={4}>
              Editar
            </Text>
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};

export default CustomProductTable;
