import { ProductCategory } from "../../utils/globalConstants";

const STROKE_COLOR_DARK = "#333333";
const STROKE_COLOR_LIGHT = "#cccccc";
const GLASS_COLOR = "#87cefa88"; // Glass with transparency
const POLIC_COLOR = "#b0b0ee88"; // Polic with transparency
const BG_COLOR = "white";
const LINES_COLOR = "gray";
const MEASURES_COLOR = "black";
const MEASURES_FONT = "bold 16px sans-serif";

const REVEST_REAL_HEIGHT = 80;
const CURTAIN_REAL_HEIGHT = 40;
const CROSSBAR_OFFSET = 1; // Offset crossbars to hide it behind the frame

export enum Ironwork {
  None,
  HandleHorizontal,
  HandleVertical,
  Hinges,
}

export type GraphicOptions = {
  w: number; // Canvas width
  h: number; // Canvas height
  fr: number; // Frame width
  color: string;
  modules: (string | string[])[];
  mosquito: boolean;
  tapajun: boolean;
  guide: boolean;
  panels: number;
  frameAng: number;
  panelAng: number;
  side?: number;
  crossbars?: [number[], number[], number[]];
  crossCut?: number;
  scale?: number;
  modularPanel?: {
    openingType: string;
    side: number;
    length: number;
    openSide?: number;
  };
  monorail?: {
    side: number;
    length: number;
  };
  corrSpc?: "copo" | "coab";
};

const getStrokeStyleFromColor = (color: string, inverted = false) => {
  return color === "#000000" || color === "#85200c"
    ? inverted
      ? STROKE_COLOR_DARK
      : STROKE_COLOR_LIGHT
    : inverted
    ? STROKE_COLOR_LIGHT
    : STROKE_COLOR_DARK;
};

export const drawRevest = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  x: number,
  bottomY: number,
  w: number,
  h: number
) => {
  ctx.strokeStyle = getStrokeStyleFromColor(ops.color, true);
  ctx.fillStyle = ops.color;
  const tableHeight = REVEST_REAL_HEIGHT * ops.scale;
  const tableCount = Math.ceil((h - ops.fr) / tableHeight);
  let usedHeight = 0;
  for (let t = 0; t < tableCount; t++) {
    const height = Math.min(tableHeight, h - usedHeight);
    ctx.beginPath();
    ctx.rect(x, bottomY - usedHeight - height, w, height);
    ctx.fill();
    ctx.stroke();
    usedHeight += tableHeight;
  }
};

const drawGlass = (
  ctx: CanvasRenderingContext2D,
  x: number,
  bottomY: number,
  w: number,
  h: number,
  catOrColor: string
) => {
  ctx.fillStyle = catOrColor.startsWith("#")
    ? catOrColor
    : catOrColor === ProductCategory.POLIC
    ? POLIC_COLOR
    : GLASS_COLOR;
  ctx.beginPath();
  ctx.rect(x, bottomY - h, w, h);
  ctx.fill();
};

const drawIronwork = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  fr: number,
  color: string,
  scale: number
) => {
  const ironColor = getStrokeStyleFromColor(color);
  drawRectangle(ctx, x + fr * 0.35, y, 15 * scale, 100 * scale, ironColor);
};

const drawFramePart = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  length: number,
  width: number,
  rot: number,
  color: string,
  angStart = 90,
  angEnd = 90
) => {
  ctx.save();

  ctx.translate(x, y);
  ctx.rotate(Math.PI * 0.5 * rot);
  ctx.strokeStyle = getStrokeStyleFromColor(color);
  ctx.fillStyle = color;

  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(length, 0);
  if (angEnd === 90) {
    ctx.lineTo(length, width);
  } else {
    ctx.lineTo(length - width, width);
  }
  if (angStart === 90) {
    ctx.lineTo(0, width);
  } else {
    ctx.lineTo(width, width);
  }
  ctx.lineTo(0, 0);
  ctx.fill();
  ctx.stroke();

  ctx.restore();
};

export const drawFrame = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  fr: number,
  color: string,
  angle = 45,
  bottom = true,
  ironwork = Ironwork.None,
  ironworkLeft = true,
  scale = 1
) => {
  const angTop = angle === 4590 ? 45 : angle;
  const angBottom = angle === 4590 ? 90 : angle;
  drawFramePart(ctx, x, y, w, fr, 0, color, angTop, angTop); // Top
  if (bottom)
    drawFramePart(ctx, x + w, y + h, w, fr, 2, color, angBottom, angBottom); // Bottom
  drawFramePart(ctx, x + w, y, h, fr, 1, color, angTop, angBottom); // Right
  drawFramePart(ctx, x, y + h, h, fr, 3, color, angBottom, angTop); // Left
  // Ironworks
  if (ironwork === Ironwork.HandleVertical) {
    drawIronwork(ctx, x + (ironworkLeft ? 0 : w - fr), h * 0.5, fr, color, scale);
  }
};

// < >
export const drawHLines = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  fr: number,
  side: number
) => {
  if (side < 0) drawDashedLines(ctx, x + w, h * 0.5 + fr, x, fr, x, h + fr);
  else drawDashedLines(ctx, x, h * 0.5 + fr, x + w, fr, x + w, h + fr);
};

// v ^
export const drawVLines = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  fr: number,
  side: number
) => {
  if (side < 0) drawDashedLines(ctx, x + w * 0.5, h + fr, x, fr, x + w, fr);
  else drawDashedLines(ctx, x + w * 0.5, fr, x, h + fr, x + w, h + fr);
};

export const drawBg = (
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) => {
  ctx.fillStyle = BG_COLOR;
  ctx.beginPath();
  ctx.rect(0, 0, width, height);
  ctx.fill();
};

export const drawGuides = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  windowWidth: number
) => {
  ctx.fillStyle = ops.color;
  ctx.strokeStyle = getStrokeStyleFromColor(ops.color);
  // Left
  ctx.beginPath();
  ctx.rect(0, -ops.fr, ops.fr, ops.fr);
  ctx.fill();
  ctx.stroke();
  // Right
  ctx.beginPath();
  ctx.rect(ops.w - ops.fr, -ops.fr, ops.fr, ops.fr);
  ctx.fill();
  ctx.stroke();
};

const drawSubmodule = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  moduleHeight: number,
  currentHeight: number,
  fill: string,
  submoduleOffset?: number,
  submoduleWidth?: number
) => {
  if (fill === ProductCategory.REVEST) {
    drawRevest(
      ctx,
      ops,
      submoduleOffset || 0,
      ops.h - currentHeight,
      submoduleWidth || ops.w,
      moduleHeight
    );
  } else {
    drawGlass(
      ctx,
      submoduleOffset || 0,
      ops.h - currentHeight,
      submoduleWidth || ops.w,
      moduleHeight,
      fill
    );
  }
};

export const drawModules = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions
) => {
  const horiz = ops.crossbars[0];
  const verts = ops.crossbars[1];
  const modls = ops.modules;
  for (let pan = 0; pan < ops.panels; pan++) {
    const panelWidth = ops.w / ops.panels;
    const panelOffset = panelWidth * pan;
    let currentHeight = 0;
    modls.forEach((fill, index) => {
      const moduleHeight =
        typeof horiz[index] !== "undefined"
          ? horiz[index] - currentHeight
          : ops.h - currentHeight;
      if (fill) {
        if (!Array.isArray(fill)) {
          drawSubmodule(
            ctx,
            ops,
            moduleHeight,
            currentHeight,
            fill,
            panelOffset,
            panelWidth
          );
        } else {
          let currentSubWidth = 0;
          fill.forEach((subFill, subIndex) => {
            const submoduleWidth =
              typeof verts[subIndex] !== "undefined"
                ? verts[subIndex] - currentSubWidth
                : ops.w / ops.panels - currentSubWidth;
            if (subFill) {
              drawSubmodule(
                ctx,
                ops,
                moduleHeight,
                currentHeight,
                subFill,
                currentSubWidth + panelOffset,
                submoduleWidth
              );
            }
            currentSubWidth += submoduleWidth;
          });
        }
      }
      currentHeight += moduleHeight;
    });
  }
};

export const drawArrow = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  scale = 1,
  rot = 0
) => {
  ctx.save();

  const length = 150 * scale;
  ctx.strokeStyle = LINES_COLOR;
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.rotate(Math.PI * 0.5 * rot);

  ctx.moveTo(length * -0.5, 0);
  ctx.lineTo(length, 0);
  ctx.lineTo(length * 0.5, -length * 0.5);
  ctx.moveTo(length, 0);
  ctx.lineTo(length * 0.5, +length * 0.5);
  ctx.stroke();

  ctx.restore();
};

export const drawDashedLines = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  x0: number,
  y0: number,
  x1: number,
  y1: number
) => {
  ctx.save();

  ctx.strokeStyle = LINES_COLOR;
  ctx.beginPath();
  ctx.setLineDash([7, 7]);
  ctx.moveTo(x, y);
  ctx.lineTo(x0, y0);
  ctx.moveTo(x, y);
  ctx.lineTo(x1, y1);
  ctx.stroke();

  ctx.restore();
};

export const drawCrossbars = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  frameX: number = 0,
  frameW: number = ops.w,
  frameH: number = ops.h
) => {
  let horiz = ops.crossbars[0];
  let verts = ops.crossbars[1];

  // Vertical crossbars
  if (verts) {
    let vertHeight = frameH;
    let vertY = 0;
    // Vertical crossbars being cut
    if (ops.crossCut !== 0 && horiz) {
      if (ops.crossCut > 0) {
        vertHeight = frameH - horiz[horiz.length - ops.crossCut];
      } else {
        const cutValue = Math.abs(ops.crossCut);
        vertHeight = horiz[cutValue - 1];
        vertY = frameH - horiz[cutValue - 1];
      }
    }
    verts.forEach((crossX) => {
      drawFramePart(
        ctx,
        frameX + crossX + ops.fr * 0.5,
        vertY + CROSSBAR_OFFSET,
        vertHeight - CROSSBAR_OFFSET * 2,
        ops.fr,
        1,
        ops.color
      );
    });
  }
  // Horizontal crossbars
  if (horiz) {
    horiz.forEach((crossY) => {
      drawFramePart(
        ctx,
        frameX + ops.fr,
        frameH - crossY - ops.fr * 0.5,
        frameW - ops.fr - CROSSBAR_OFFSET,
        ops.fr,
        0,
        ops.color
      );
    });
  }
};

export const drawMosquito = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  patternRef: React.MutableRefObject<HTMLImageElement>,
  width: number,
  height: number,
  yOffset: number = 0,
  xOffset: number = 0
) => {
  const pattern = ctx.createPattern(patternRef.current, "repeat");
  ctx.fillStyle = pattern;
  ctx.fillRect(xOffset, yOffset, width, height);
  // Mosq crossbars
  const crossbars = ops.crossbars[2];
  if (crossbars) {
    crossbars.forEach((cross) => {
      drawFramePart(
        ctx,
        ops.fr,
        height - cross - ops.fr * 0.5,
        width - ops.fr,
        ops.fr,
        0,
        ops.color
      );
    });
  }
};

export const drawTapacint = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  windowWidth: number,
  windowHeight: number,
  side: number
) => {
  const w = ops.fr * 1.5;
  ctx.fillStyle = ops.color;
  ctx.strokeStyle = getStrokeStyleFromColor(ops.color);
  if (side === -1 || side === 2) {
    ctx.beginPath();
    ctx.rect(0, 0, w, windowHeight);
    ctx.fill();
    ctx.stroke();
    ctx.translate(w, 0);
  }
  if (side === 1 || side === 2) {
    ctx.beginPath();
    ctx.rect(windowWidth, 0, w, windowHeight);
    ctx.fill();
    ctx.stroke();
  }
};

const drawMeasureLine = (
  ctx: CanvasRenderingContext2D,
  fromX: number,
  fromY: number,
  toX: number,
  toY: number,
  height: number
) => {
  ctx.beginPath();
  ctx.moveTo(fromX, fromY);
  ctx.lineTo(toX, toY);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(fromX, fromY - height);
  ctx.lineTo(fromX, fromY + height);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(toX, toY - height);
  ctx.lineTo(toX, toY + height);
  ctx.stroke();
};

export const drawMeasures = (
  ctx: CanvasRenderingContext2D,
  realWidth: number,
  realHeight: number,
  xSize: number,
  ySize: number,
  xOffset: number = 0,
  yOffset: number = 0,
  rectOffset: number = 0
) => {
  const w = ctx.canvas.width;
  const h = ctx.canvas.height;
  const widthText = realWidth.toString();
  const heightText = realHeight.toString();

  ctx.font = MEASURES_FONT;
  ctx.fillStyle = MEASURES_COLOR;
  const wPos = (w - xSize) * 0.5 - ctx.measureText(widthText).width * 0.5;
  const hPos = (h + ySize) * 0.5 - ctx.measureText(heightText).width * 0.5;

  // Width text & lines
  ctx.fillText(
    widthText,
    wPos + xOffset + rectOffset,
    ySize * 0.5 - rectOffset
  );
  drawMeasureLine(
    ctx,
    xOffset + rectOffset,
    ySize * 0.65 - rectOffset * 1.25,
    w - xSize + xOffset + rectOffset,
    ySize * 0.65 - rectOffset * 1.25,
    6
  );

  ctx.save();
  ctx.translate(w, 0);
  ctx.rotate(Math.PI * 0.5);
  // Height text & lines
  ctx.fillText(
    heightText,
    hPos - rectOffset + yOffset,
    ySize * 0.5 - rectOffset
  );
  drawMeasureLine(
    ctx,
    ySize - rectOffset + yOffset,
    ySize * 0.65 - rectOffset * 1.25,
    h - rectOffset,
    ySize * 0.65 - rectOffset * 1.25,
    6
  );

  ctx.restore();
};

export const drawModularCrossbars = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions
) => {
  if (ops.modularPanel) {
    const verts = [];
    if (ops.modularPanel.side < 0 || ops.modularPanel.side > 1)
      verts.push(ops.modularPanel.length);
    if (ops.modularPanel.side > 0) verts.push(ops.w - ops.modularPanel.length);
    verts.forEach((crossX) => {
      drawFramePart(
        ctx,
        crossX + ops.fr * 0.5,
        CROSSBAR_OFFSET,
        ops.h - CROSSBAR_OFFSET * 2,
        ops.fr,
        1,
        ops.color
      );
    });
  }
};

export const drawRectangle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  color: string
) => {
  ctx.fillStyle = color;
  ctx.strokeStyle = getStrokeStyleFromColor(color);
  ctx.beginPath();
  ctx.rect(x, y, w, h);
  ctx.fill();
  ctx.stroke();
};

export const drawCurtain = (
  ctx: CanvasRenderingContext2D,
  ops: GraphicOptions,
  x: number,
  bottomY: number,
  w: number,
  h: number
) => {
  ctx.strokeStyle = getStrokeStyleFromColor(ops.color, true);
  ctx.fillStyle = ops.color;
  const tableHeight = CURTAIN_REAL_HEIGHT * ops.scale;
  const tableCount = Math.ceil((h - ops.fr) / tableHeight);
  let usedHeight = 0;
  for (let t = 0; t < tableCount; t++) {
    const height = Math.min(tableHeight, h - usedHeight);
    ctx.beginPath();
    ctx.rect(x, bottomY - usedHeight - height, w, height);
    ctx.fill();
    ctx.stroke();
    usedHeight += tableHeight;
  }
  ctx.strokeStyle = getStrokeStyleFromColor(ops.color);
  ctx.rect(x, 0, w, h);
  ctx.stroke();
};
