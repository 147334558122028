import { Group, Paper, Text, ThemeIcon, Title } from "@mantine/core";
import { useEffect } from "react";
import { BiPowerOff, BiLogoWhatsapp } from "react-icons/bi";
import PageWrapper from "../components/common/PageWrapper";

const PHONE = process.env.REACT_APP_CONTACT_PHONE;

const InactivePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper>
      <Group my="lg" spacing={8}>
        <BiPowerOff size={36} />
        <Title order={1}>Active su cuenta</Title>
      </Group>

      <Paper mb={36} p={30} shadow="xs" sx={{ width: "fit-content" }}>
        <Text mb={16}>
          Para poder utilizar esta herramienta, su cuenta debe ser{" "}
          <strong>activada</strong>.
        </Text>
        <Text>
          Por favor comuníquese a{" "}
          <Group
            ml={4}
            align="center"
            spacing={6}
            style={{ display: "inline" }}
          >
            <ThemeIcon mr={4} color="green" radius="xl" size="sm">
              <BiLogoWhatsapp size={16} />
            </ThemeIcon>
            <Text fw="bold" align="center" span inherit>
              {PHONE}
            </Text>
            <Text ml={4} color="gray" span inherit>
              (Argentina)
            </Text>
          </Group>{" "}
          para iniciar el proceso de activación de la cuenta.
        </Text>
      </Paper>
    </PageWrapper>
  );
};

export default InactivePage;
