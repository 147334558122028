import {
  AspectRatio,
  BackgroundImage,
  Box,
  MediaQuery,
  Paper,
  Skeleton,
  Text,
} from "@mantine/core";
import { useState } from "react";

import stock from "../../assets/img/stock_03.jpg";

const HomeBottomBanner = () => {
  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true);

  return (
    <Box>
      <BackgroundImage src={stock} py={32} h={510}>
        <MediaQuery
          query="(max-width: 900px)"
          styles={{ right: "0%", width: "100%" }}
        >
          <Paper p={30} pt={20} w="45%" radius="xs" pos="absolute" right="15%">
            <Text align="center" weight="bold">
              Tutorial básico
            </Text>
            <Skeleton visible={loadingTutorial}>
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src="https://www.youtube.com/embed/Dq0xOeoaTr4"
                  title="Tutorial Básico"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={() => setLoadingTutorial(false)}
                />
              </AspectRatio>
            </Skeleton>
          </Paper>
        </MediaQuery>
      </BackgroundImage>
    </Box>
  );
};

export default HomeBottomBanner;
