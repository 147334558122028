import {
  Button,
  Group,
  Modal,
  PasswordInput,
  Stack,
  Text,
} from "@mantine/core";
import React, { useState } from "react";
import PasswordEditInput from "../Signup/PasswordEditInput";

type AccountChangePassProps = {
  opened: boolean;
  loading: boolean;
  error: boolean;
  onConfirm: (oldPass: string, newPass: string) => Promise<boolean>;
  onClose: () => void;
};

const AccountChangePass = (props: AccountChangePassProps) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passValid, setPassValid] = useState(false);

  const [errorLabel, setErrorLabel] = useState("");

  const oldPassChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const newPassChangeHandler = (value: string) => {
    setNewPassword(value);
  };

  const repeatPassChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(e.target.value);
  };

  const confirmHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== repeatPassword) {
      setErrorLabel("La contraseña no coincide.");
      return;
    }
    if (newPassword === oldPassword) {
      setErrorLabel("La nueva contraseña debe ser distinta de la anterior.");
      return;
    }
    if (!passValid) {
      setErrorLabel("La nueva contraseña no cumple los requisitos mínimos.");
      return;
    }
    setErrorLabel("");

    const result = await props.onConfirm(oldPassword, newPassword);
    if (result === true) {
      closeHandler();
    }
  };

  const closeHandler = () => {
    setOldPassword("");
    setNewPassword("");
    setRepeatPassword("");
    setErrorLabel("");
    props.onClose();
  };

  return (
    <Modal
      opened={props.opened}
      onClose={closeHandler}
      padding={32}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Stack align="center">
        <Text size="xl" weight="bold">
          Cambiar contraseña
        </Text>
        <form id="changePassForm" onSubmit={confirmHandler}>
          <Stack sx={{ width: "350px" }}>
            <PasswordInput
              label="Contraseña anterior"
              value={oldPassword}
              onChange={oldPassChangeHandler}
              required
            />
            <PasswordEditInput
              label="Nueva contraseña"
              value={newPassword}
              onChange={newPassChangeHandler}
              onValidityChange={(valid: boolean) => setPassValid(valid)}
              required
            />
            <PasswordInput
              label="Confirmar nueva contraseña"
              value={repeatPassword}
              onChange={repeatPassChangeHandler}
              required
            />
          </Stack>
        </form>

        {errorLabel && (
          <Text mt="sm" color="red">
            {errorLabel}
          </Text>
        )}

        <Group mt="xl" grow sx={{ width: "350px" }}>
          <Button
            type="submit"
            form="changePassForm"
            disabled={
              oldPassword === "" || newPassword === "" || repeatPassword === ""
            }
            loading={props.loading}
          >
            Guardar
          </Button>
          <Button
            variant="outline"
            onClick={closeHandler}
            disabled={props.loading}
          >
            Cancelar
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default AccountChangePass;
