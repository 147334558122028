import React, { useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";

import { Button, Group, NumberInput } from "@mantine/core";

type DiffInputProps = {
  value: number;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  width?: string;
  defaultPositive?: boolean;
  disabled?: boolean;
  onChange: (value: number) => void;
};

const DiffInput = (props: DiffInputProps) => {
  const sanitizedValue = props.value && !isNaN(props.value) ? props.value : 0;

  const [internalSign, setInternalSign] = useState(
    Math.sign(sanitizedValue) || -1
  );

  const sign = sanitizedValue === 0 ? internalSign : Math.sign(sanitizedValue);
  const digit = Math.abs(sanitizedValue);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newSign = sign > 0 ? -1 : 1;
    setInternalSign(newSign);
    props.onChange(newSign * digit ?? 0);
  };

  const diffChangeHandler = (newValue: number) => {
    props.onChange(sign * newValue ?? 0);
  };

  const buttonContent = sign > 0 ? <BiPlus size={18} /> : <BiMinus size={18} />;

  return (
    <Group spacing={0} noWrap>
      <Button
        size={props.size ?? "xs"}
        variant="outline"
        disabled={props.disabled}
        value={sign}
        styles={{
          root: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            padding: 4,
          },
        }}
        onClick={clickHandler}
      >
        {buttonContent}
      </Button>
      <NumberInput
        size={props.size ?? "xs"}
        sx={{ width: props.width || "5em" }}
        styles={{
          input: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
        min={0}
        max={500}
        disabled={props.disabled}
        value={digit}
        onChange={diffChangeHandler}
      />
    </Group>
  );
};

export default DiffInput;
