import React, { useEffect, useRef, useState } from "react";

import Product from "../../models/product";
import { Stack, Text, TextInput } from "@mantine/core";
import { normalizeText } from "../../utils/stringConversion";

type CatalogueListProps = {
  products: Product[];
  selectedProd: string;
  brand: string;
  line: string;
  onSelectionChange: (text: string) => void;
};

const CatalogueList = ({
  products,
  selectedProd,
  brand,
  line,
  onSelectionChange,
}: CatalogueListProps) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const selectRef = useRef<HTMLSelectElement>();

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.currentTarget.value);
    onSelectionChange(undefined);
    selectRef.current.selectedIndex = -1;
  };
  const selectionChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value;
    onSelectionChange(newValue);
  };

  useEffect(() => {
    setSearchFilter("");
  }, [products.length]);

  const lowerSearch = normalizeText(searchFilter);
  const productList =
    searchFilter === ""
      ? products
      : products.filter((prod) => {
          return (
            normalizeText(prod.name).includes(lowerSearch) ||
            prod.code.toLowerCase().includes(lowerSearch)
          );
        });
  // const productListContent = productList.map((product) => {
  //   return { value: product._id, label: `[${product.code}] ${product.name}` };
  // });
  const productListContent = productList.map((product) => (
    <option key={product._id} value={product._id}>
      [{product.code}] {product.name}
    </option>
  ));

  return (
    <Stack spacing={0} sx={{ width: "100%" }}>
      <Text size="md">{`Catálogo: ${brand} ${line}`}</Text>
      <TextInput
        mb="xs"
        placeholder="Búsqueda (nombre o código)"
        maxLength={32}
        sx={{ width: "100%" }}
        value={searchFilter}
        onChange={searchChangeHandler}
      />
      <select
        style={{ font: "inherit", fontSize: "16px", flexGrow: 1 }}
        size={9}
        value={selectedProd}
        onChange={selectionChangeHandler}
        ref={selectRef}
      >
        {productListContent}
      </select>
    </Stack>
  );
};

export default CatalogueList;
