import React, { useContext, useState } from "react";
import { BiLogInCircle } from "react-icons/bi";

import UserContext from "../store/user-context";

import {
  Button,
  Group,
  PasswordInput,
  TextInput,
  Paper,
  Title,
  Text,
  Stack,
} from "@mantine/core";
import { useMutation } from "react-query";
import { loginUser } from "../services/services";
import PageWrapper from "../components/common/PageWrapper";

const LoginPage = () => {
  const userCtx = useContext(UserContext);
  const [email, setEmail] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const [error, setError] = useState<string>("");

  const loginMutation = useMutation(() => loginUser(email, pass), {
    onSuccess: (data) => userCtx.onLogin(data.token, data.user),
    onError: (error: Error) =>
      setError(
        error.message ||
          "Ocurrió un error inesperado. Intente de nuevo en un momento."
      ),
  });

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (loginMutation.error) loginMutation.reset();
  };

  const passChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value);
    if (loginMutation.error) loginMutation.reset();
  };

  const loginFormHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    loginMutation.mutate();
  };

  return (
    <PageWrapper>
      <Paper p="xl" style={{ margin: "40px auto", maxWidth: "500px" }}>
        <form id="loginForm" onSubmit={loginFormHandler}>
          <Stack align="center" spacing="lg">
            <Group my="sm" spacing={6}>
              <BiLogInCircle size="28" />
              <Title order={3}>Acceda a su cuenta</Title>
            </Group>
            <Stack align="stretch" spacing="sm" sx={{ width: "24em" }}>
              <TextInput
                label="Correo electrónico"
                form="loginForm"
                type="email"
                value={email}
                onChange={emailChangeHandler}
                maxLength={64}
                required
              />
              <PasswordInput
                label="Contraseña"
                form="loginForm"
                value={pass}
                onChange={passChangeHandler}
                maxLength={32}
                required
              />
            </Stack>
            {error && <Text color="red">{error}</Text>}
            <Button
              my="md"
              size="md"
              form="loginForm"
              type="submit"
              loading={loginMutation.isLoading}
            >
              Iniciar sesión
            </Button>
          </Stack>
        </form>
      </Paper>
    </PageWrapper>
  );
};

export default LoginPage;
