import { useContext, useEffect } from "react";
import UserContext from "../store/user-context";

import HomeTopBanner from "../components/Home/HomeTopBanner";
import HomeMidBanner from "../components/Home/HomeMidBanner";
import HomeBottomBanner from "../components/Home/HomeBottomBanner";
import { Box, Group, Stack, Text, ThemeIcon, Title } from "@mantine/core";
import { BiLogoWhatsapp } from "react-icons/bi";

const PHONE = process.env.REACT_APP_CONTACT_PHONE;

const HomePage = () => {
  const userCtx = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HomeTopBanner isLoggedIn={userCtx.loggedIn} />
      <HomeMidBanner />
      <HomeBottomBanner />
      <Box bg="white" h={200}>
        <Stack
          align="center"
          justify="center"
          px="md"
          spacing="sm"
          sx={{ height: "100%" }}
        >
          <Title order={2}>Contacto</Title>
          <Group spacing={0}>
            <ThemeIcon mr={8} color="green" radius="xl">
              <BiLogoWhatsapp size={20} />
            </ThemeIcon>
            <Text fw="bold" color="gray" align="center">
              {PHONE}
            </Text>
            <Text ml={4} color="dimmed">(Argentina)</Text>
          </Group>
        </Stack>
      </Box>
    </div>
  );
};

export default HomePage;
