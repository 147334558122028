import { useState } from "react";
import { TextInput } from "@mantine/core";
import { PRINT_SAFE_FILTER } from "../../utils/globalConstants";

const PrintSafeTextInput = (props: any) => {
  const [error, setError] = useState("");

  const onTextChange = (value: string) => {
    props.onChange(value);
    if (!PRINT_SAFE_FILTER.test(value))
      setError("El campo contiene caracteres no permitidos.");
    else setError("");
  };

  return (
    <TextInput
      {...props}
      onChange={(e) => onTextChange(e.target.value)}
      error={error}
    />
  );
};

export default PrintSafeTextInput;
