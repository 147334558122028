import UnifiedAddButton, { UnifiedBlockPosition } from "./UnifiedAddButton";

type UnifiedGridBlockProps = {
  id: string;
  text: string;
  x: number;
  y: number;
  w: number;
  h: number;
  designs: any[];
  scale: number;
  selected: boolean;
  freeSides: string[];
  onSelect: (id: string) => void;
  onAdd: (
    designId: string,
    width: number,
    height: number,
    x: number,
    y: number
  ) => void;
  onRemove: (id: string) => void;
};

const UnifiedGridBlock = (props: UnifiedGridBlockProps) => {
  const { freeSides = [] } = props;

  const addHandler = (
    id: string,
    width: number,
    height: number,
    position: UnifiedBlockPosition,
    margin: number = 0,
  ) => {
    let x = props.x;
    let y = props.y;
    switch (position) {
      case "right":
        x += props.w;
        y += margin;
        break;
      case "left":
        x -= width;
        y += margin;
        break;
      case "bottom":
        y += props.h;
        x += margin;
        break;
      case "top":
        y -= height;
        x += margin;
        break;
      default:
        x = 0;
        y = 0;
        break;
    }
    props.onAdd(id, width, height, x, y);
  };

  return (
    <div
      style={{
        backgroundColor: "whitesmoke",
        border: "2px solid gray",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "absolute",
        boxSizing: "border-box",
        left: `${props.x * props.scale}px`,
        top: `${props.y * props.scale}px`,
        width: `${props.w * props.scale}px`,
        height: `${props.h * props.scale}px`,
      }}
      onClick={(e: any) => props.onSelect(props.id)}
    >
      {/* <CloseButton
        color="red"
        onClick={(e: any) => props.onRemove(props.id)}
        style={{ position: "absolute", top: "0px", right: "0px" }}
      /> */}
      <p
        style={{
          textAlign: "center",
          padding: "8px",
          overflow: "clip",
          fontSize: "12px",
        }}
      >
        {props.text}
      </p>
      {props.selected && (
        <>
          {freeSides.includes("left") && (
            <UnifiedAddButton
              position="left"
              side="horizontal"
              maxMargin={props.h}
              designs={props.designs}
              onAdd={addHandler}
              style={{
                position: "absolute",
                left: "-44px",
                top: "0px",
              }}
            />
          )}
          {freeSides.includes("right") && (
            <UnifiedAddButton
              position="right"
              side="horizontal"
              maxMargin={props.h}
              designs={props.designs}
              onAdd={addHandler}
              style={{
                position: "absolute",
                right: "-44px",
                top: "0px",
              }}
            />
          )}
          {freeSides.includes("top") && (
            <UnifiedAddButton
              position="top"
              side="vertical"
              maxMargin={props.w}
              designs={props.designs}
              onAdd={addHandler}
              style={{
                position: "absolute",
                top: "-42px",
                left: "0px",
              }}
            />
          )}
          {freeSides.includes("bottom") && (
            <UnifiedAddButton
              position="bottom"
              side="vertical"
              maxMargin={props.w}
              designs={props.designs}
              onAdd={addHandler}
              style={{
                position: "absolute",
                bottom: "-42px",
                left: "0px",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UnifiedGridBlock;
