import {
  Group,
  List,
  Stack,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { BiCheck, BiChevronRight } from "react-icons/bi";
import { IoMdRibbon } from "react-icons/io";

type PlanButtonProps = {
  plan: {
    code: string;
    name: string;
    description: string[];
    recommended?: boolean;
    price?: number;
    priceUsd?: number;
  };
  onClick: (value: string) => void;
  selected?: boolean;
  usd?: boolean;
};

const PlanButton = (props: PlanButtonProps) => {
  const formatPriceString = (price: number, usd: boolean) => {
    return price.toLocaleString("es-AR", {
      style: "currency",
      currency: usd ? "USD" : "ARS",
      maximumFractionDigits: 0,
    });
  };

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick(e.currentTarget.value);
  };

  return (
    <UnstyledButton
      value={props.plan.code}
      onClick={clickHandler}
      sx={(theme) => ({
        backgroundColor: props.selected ? "white" : theme.colors.gray[0],
        borderRadius: "4px",
        border: props.selected
          ? `3px solid ${theme.colors.brand[5]}`
          : "1px solid lightgray",
        display: "flex",
        justifyContent: "flex-start",
        boxShadow: "0 4px 6px lightgray",
        overflow: "visible",
      })}
    >
      <Stack
        p="md"
        pb={20}
        spacing="sm"
        justify="stretch"
        sx={{ width: "100%" }}
      >
        <Group>
          <Group spacing={6} sx={{ flex: 1 }}>
            <Title
              order={4}
              sx={(theme: any) => ({
                color: props.selected
                  ? theme.colors.brand[7]
                  : theme.colors.gray[5],
              })}
            >
              {props.plan.name}
            </Title>
            {props.plan.recommended && (
              <IoMdRibbon
                size={24}
                color={props.selected ? "gold" : "lightgray"}
              />
            )}
          </Group>
          {props.selected && (
            <ThemeIcon color="green" radius="xl">
              <BiCheck size={24} />
            </ThemeIcon>
          )}
        </Group>
        <List
          size="sm"
          icon={<BiChevronRight />}
          spacing={6}
          sx={(theme) => ({
            flex: 1,
            paddingRight: 6,
            color: props.selected ? theme.colors.gray[7] : theme.colors.gray[5],
          })}
          styles={(theme) => ({
            itemIcon: {
              marginRight: 6,
            },
          })}
        >
          {props.plan.description.map((x, index) => (
            <List.Item key={index}>{x}</List.Item>
          ))}
        </List>

        {props.plan.price && (
          <Group
            spacing={6}
            align="baseline"
            sx={(theme: any) => ({
              alignSelf: "flex-end",
              color: props.selected
                ? theme.colors.gray[8]
                : theme.colors.gray[5],
            })}
          >
            <Text size="xl">{`${formatPriceString(
              props.usd ? props.plan.priceUsd : props.plan.price,
              props.usd
            )}`}</Text>
            <Text>{props.usd ? "/año" : "/mes"}</Text>
          </Group>
        )}
      </Stack>
    </UnstyledButton>
  );
};

export default PlanButton;
