import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Burger, Drawer, Stack, Text, UnstyledButton } from "@mantine/core";
import { IUserContext } from "../../store/user-context";

import styles from "./HeaderNav.module.css";

type HeaderNavProps = {
  context: IUserContext;
  paths: any;
};

type SideNavButtonProps = {
  text: string;
  to: any;
  onClick: () => void;
};

enum NavButtonText {
  SIGN_IN = "Ingresar",
  SIGN_UP = "Registrarse",
  QUOTE = "Presupuesto",
  DESIGN = "Diseño",
  CONFIG = "Configuración",
  HISTORY = "Historial",
}

const SideNavButton = (props: SideNavButtonProps) => {
  return (
    <UnstyledButton
      className={styles.navSideButton}
      component={Link}
      to={props.to}
      onClick={props.onClick}
      sx={(theme) => ({
        padding: "0 20px",
        lineHeight: "52px",
        color: theme.colors.brand[8],
        fontSize: "18px",
        ":active": {
          backgroundColor: theme.colors.brand[1],
        },
        ":hover": {
          backgroundColor: theme.colors.brand[0],
        },
      })}
    >
      {props.text}
    </UnstyledButton>
  );
};

const HeaderNav = (props: HeaderNavProps) => {
  const { context: userCtx } = props;

  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const closeSideMenu = () => setSideMenuOpen(false);

  const navBarContent = (
    <>
      {!userCtx.loggedIn && (
        <>
          <NavLink to={props.paths.login}>
            <Text>{NavButtonText.SIGN_IN}</Text>
          </NavLink>
          <NavLink to={props.paths.signup}>{NavButtonText.SIGN_UP}</NavLink>
        </>
      )}
      {userCtx.loggedIn && userCtx.data.terms === true && (
        <>
          <NavLink to={props.paths.quote}>{NavButtonText.QUOTE}</NavLink>
          {!userCtx.isGuest && (
            <>
              <NavLink to={props.paths.design}>{NavButtonText.DESIGN}</NavLink>
              <NavLink to={props.paths.config}>{NavButtonText.CONFIG}</NavLink>
            </>
          )}
          <NavLink to={props.paths.history}>{NavButtonText.HISTORY}</NavLink>
        </>
      )}
    </>
  );

  const navMenuContent = (
    <Stack pt={50} spacing={0} align="stretch">
      {!userCtx.loggedIn && (
        <>
          <SideNavButton
            text={NavButtonText.SIGN_IN}
            to={props.paths.login}
            onClick={closeSideMenu}
          />
          <SideNavButton
            text={NavButtonText.SIGN_UP}
            to={props.paths.signup}
            onClick={closeSideMenu}
          />
        </>
      )}
      {userCtx.loggedIn && userCtx.data.terms === true && (
        <>
          <SideNavButton
            text={NavButtonText.QUOTE}
            to={props.paths.quote}
            onClick={closeSideMenu}
          />
          {!userCtx.isGuest && (
            <>
              <SideNavButton
                text={NavButtonText.DESIGN}
                to={props.paths.design}
                onClick={closeSideMenu}
              />
              <SideNavButton
                text={NavButtonText.CONFIG}
                to={props.paths.config}
                onClick={closeSideMenu}
              />
            </>
          )}
          <SideNavButton
            text={NavButtonText.HISTORY}
            to={props.paths.history}
            onClick={closeSideMenu}
          />
        </>
      )}
    </Stack>
  );
  return (
    <nav className={styles.navRoot}>
      <div className={styles.navBar}>{navBarContent}</div>

      <Burger
        className={styles.navBurger}
        color="white"
        opened={sideMenuOpen}
        onClick={() => setSideMenuOpen((open) => !open)}
      />
      <Drawer
        size={250}
        opened={sideMenuOpen}
        lockScroll
        withCloseButton={false}
        onClose={closeSideMenu}
      >
        {navMenuContent}
      </Drawer>
    </nav>
  );
};

export default HeaderNav;
