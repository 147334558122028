import { Button, Group, Modal, Stack, Text, ThemeIcon } from "@mantine/core";
import { BiError } from "react-icons/bi";

type AccountWarningProps = {
  opened: boolean;
  onClose: () => void;
  userEmail: string;
  adminPhone: string;
  isGuest: boolean;
};

const AccountWarning = (props: AccountWarningProps) => {
  const daysLeft =
    Number(process.env.REACT_APP_DUE_DATE) - new Date().getDate();

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size={900}
      padding="xl"
      title="Recordatorio"
      withCloseButton={false}
    >
      <Stack align="center" spacing={0}>
        <Group mx="xl" mb="xl" align="center" spacing="xl" noWrap>
          <ThemeIcon variant="light" color="red" size={48}>
            <BiError size={48} />
          </ThemeIcon>
          <Stack align="center">
            <Text align="center">
              Estimado cliente, el servicio vence{" "}
              {daysLeft > 2 ? (
                <Text span inherit>
                  en{" "}
                  <Text
                    span
                    inherit
                    color="red"
                    fw="bold"
                  >{` ${daysLeft} días`}</Text>
                </Text>
              ) : (
                <Text span inherit color="red" fw="bold">
                  {daysLeft > 1 ? "mañana" : "hoy"}
                </Text>
              )}
              .
            </Text>
            <Text color="red" fw="bold">
              Vencido este plazo la facturación tendrá un 10% de recargo.
            </Text>
            <Text align="center">
              La factura será enviada a{" "}
              {props.isGuest ? (
                "el correo electrónico de la cuenta principal"
              ) : props.userEmail ? (
                <strong>{props.userEmail}</strong>
              ) : (
                "su correo electrónico"
              )}{" "}
              una vez realizado el pago de la misma.
            </Text>
            <Text align="center">
              Para ver el estado de su cuenta, en el menú de la esquina superior
              derecha (donde figura su nombre) ingrese a Mi Cuenta, donde podrá
              ver el detalle de facturación.
            </Text>
            <Text weight="bold" align="center">
              Recuerde que la reactivación del servicio puede demorar hasta 24
              hs.
            </Text>
          </Stack>
          <ThemeIcon variant="light" color="red" size={48}>
            <BiError size={48} />
          </ThemeIcon>
        </Group>
        <Button size="md" onClick={props.onClose}>
          Aceptar
        </Button>
      </Stack>
    </Modal>
  );
};

export default AccountWarning;
