import { MantineSize, Select, Sx } from "@mantine/core";

type SelectDesignProps = {
  label?: string;
  placeholder?: string;
  data: any;
  value: string;
  size?: MantineSize;
  limit?: number;
  searchable?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  maxDropdownHeight?: number;
  onChange: (value: string) => void;
  sx?: Sx;
};

const SelectDesign = (props: SelectDesignProps) => {
  return (
    <Select
      sx={props.sx}
      label={props.label ?? "Diseños"}
      placeholder={
        props.placeholder ?? props.limit
          ? props.data.length > 0
            ? `Buscar un diseño (${props.data.length} encontrados${
                props.data.length > props.limit
                  ? ` - mostrando primeros ${props.limit}`
                  : ""
              })`
            : "No hay coincidencias con los filtros seleccionados"
          : ""
      }
      data={props.data}
      value={props.value}
      size={props.size}
      limit={props.limit}
      nothingFound="No hay coincidencias."
      searchable={props.searchable ?? true}
      clearable={props.clearable ?? true}
      disabled={props.disabled ?? false}
      initiallyOpened={false}
      maxDropdownHeight={props.maxDropdownHeight ?? 200}
      onChange={props.onChange}
      styles={{
        input: {
          "::placeholder": {
            color: "gray",
          },
        },
      }}
    />
  );
};

export default SelectDesign;
