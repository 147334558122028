import { IMaskInput } from "react-imask";
import { Input, MantineSize, Sx } from "@mantine/core";

type CodeInputProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
  form?: string;
  maxLength?: number;
  size?: MantineSize;
  sx?: Sx;
};

const CodeInput = (props: CodeInputProps) => {
  const codeRegex = new RegExp(`^[a-zA-Z0-9-_/]{1,${props.maxLength || 4}}$`, "g");

  return (
    <Input.Wrapper
      label={props.label}
      description={props.description}
      error={props.error}
      required={props.required}
      size={props.size || "md"}
    >
      <Input
        sx={[props.sx]}
        component={IMaskInput}
        mask={codeRegex}
        value={props.value}
        onChange={() => {}}
        onAccept={(value: string, mask: any) => props.onChange(value)}
        required={props.required}
        size={props.size || "md"}
      />
    </Input.Wrapper>
  );
};

export default CodeInput;
