import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { BiIdCard, BiCheck, BiError, BiInfoCircle } from "react-icons/bi";
import { Alert, Button, Grid, Group, Paper, Stack, Title } from "@mantine/core";
import UserContext from "../store/user-context";
import AccountChangePass from "../components/Account/AccountChangePass";
import {
  getPlans,
  changeUserPassword,
  deleteUserAccount,
  editUserData,
} from "../services/services";
import AccountDelete from "../components/Account/AccountDelete";
import { showNotification } from "@mantine/notifications";
import AccountUserInfo from "../components/Account/AccountUserInfo";
import AccountBillingInfo from "../components/Account/AccountBillingInfo";
import PageWrapper from "../components/common/PageWrapper";

const AccountPage = () => {
  const userCtx = useContext(UserContext);

  const showSuccessNotification = (title: string, message: string) => {
    showNotification({
      title,
      message,
      color: "green",
      icon: <BiCheck size={32} />,
    });
  };

  const showFailureNotification = (title: string, message: string) => {
    showNotification({
      title,
      message,
      color: "red",
      icon: <BiError size={20} />,
    });
  };

  const { data: plansData } = useQuery("plans", getPlans, {
    placeholderData: [],
  });
  const editUserMutation = useMutation(
    (newInfo: {
      firstName: string;
      lastName: string;
      phone: string;
      city: string;
    }) =>
      editUserData(
        userCtx.token,
        newInfo.firstName,
        newInfo.lastName,
        newInfo.phone,
        newInfo.city
      ),
    {
      onSuccess: (data) => {
        userCtx.editData({
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          city: data.city,
        });
        showSuccessNotification(
          "Editar datos",
          "Datos actualizados exitosamente."
        );
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Editar datos",
          error.message || "No se han podido actualizar los datos."
        );
      },
    }
  );
  const changePassMutation = useMutation(
    (passChange: { oldPass: string; newPass: string }) =>
      changeUserPassword(passChange.oldPass, passChange.newPass, userCtx.token),
    {
      onSuccess: () =>
        showSuccessNotification(
          "Cambiar contraseña",
          "La contraseña se ha cambiado exitosamente."
        ),
      onError: (error: Error) =>
        showFailureNotification(
          "Cambiar contraseña",
          error.message || "No se ha podido cambiar la contraseña."
        ),
    }
  );
  const deleteAccountMutation = useMutation(
    (deleteData: { email: string; pass: string }) =>
      deleteUserAccount(deleteData.email, deleteData.pass, userCtx.token)
  );

  const [changePassModal, setChangePassModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const changePassModalOpen = () => {
    changePassMutation.reset();
    setChangePassModal(true);
  };
  // const deleteAccountModalOpen = () => {
  //   deleteAccountMutation.reset();
  //   setDeleteAccountModal(true);
  // };

  const editUserInfoHandler = (
    firstName: string,
    lastName: string,
    phone: string,
    city: string
  ) => {
    editUserMutation.mutate({ firstName, lastName, phone, city });
  };
  const changePassHandler = async (oldPass: string, newPass: string) => {
    const result = await changePassMutation.mutateAsync({ oldPass, newPass });
    if (result === true) {
      showSuccessNotification(
        "Cambiar contraseña",
        "La contraseña se ha cambiado exitosamente."
      );
    }
    return result;
  };
  const deleteAccountHandler = async (email: string, pass: string) => {
    const result = await deleteAccountMutation.mutateAsync({ email, pass });
    if (result === true) {
      userCtx.onLogout();
    }
  };

  return (
    <PageWrapper>
      <AccountChangePass
        opened={changePassModal}
        loading={changePassMutation.isLoading}
        error={changePassMutation.isError}
        onConfirm={changePassHandler}
        onClose={() => setChangePassModal(false)}
      />
      <AccountDelete
        opened={deleteAccountModal}
        loading={deleteAccountMutation.isLoading}
        error={deleteAccountMutation.isError}
        onConfirm={deleteAccountHandler}
        onClose={() => setDeleteAccountModal(false)}
      />

      <Group my="lg" spacing={8}>
        <BiIdCard size={36} />
        <Title order={1}>Mi Cuenta</Title>
      </Group>

      <Grid gutter="xl" grow>
        <Grid.Col lg={6} xl={4}>
          {!userCtx.isGuest && (
            <Stack spacing="xl">
              <AccountUserInfo
                data={userCtx.data}
                loading={editUserMutation.isLoading}
                onSaveUserInfo={editUserInfoHandler}
              />
              <AccountBillingInfo data={userCtx.data} plans={plansData} />
            </Stack>
          )}
          {userCtx.isGuest && (
            <Alert
              pr="xl"
              title="Nota"
              color="blue"
              icon={<BiInfoCircle size={32} />}
            >
              Esta es una cuenta de empleado. Para ver el estado de la cuenta y
              la información de facturación, ingrese a la cuenta principal.
            </Alert>
          )}
        </Grid.Col>

        <Grid.Col lg={6} xl={2}>
          <Paper p="xl" w="fit-content" h="fit-content">
            <Stack>
              <Title order={2}>Acciones</Title>
              <Button sx={{ width: "16em" }} onClick={changePassModalOpen}>
                Cambiar contraseña
              </Button>
              {/* <Divider size="xs" />
          <Button
            sx={{ width: "16em" }}
            color="red"
            onClick={deleteAccountModalOpen}
            disabled={userCtx.data.role === "admin"}
          >
            Borrar cuenta
          </Button> */}
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </PageWrapper>
  );
};

export default AccountPage;
