import { Group, Tabs, Text, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import {
  BiBriefcaseAlt2,
  BiCheck,
  BiCog,
  BiCollection,
  BiError,
  BiFile,
  BiGroup,
  BiPieChart,
} from "react-icons/bi";

import UserConfig from "../models/user-config";
import UserContext from "../store/user-context";

import ConfigClientQuote from "../components/Config/ConfigClientQuote";
import ConfigCustomizeProds from "../components/Config/ConfigCustomizeProds";
import ConfigGains from "../components/Config/ConfigGains";
import { CostSections, ProductCategory } from "../utils/globalConstants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getBannerImage,
  getBrands,
  getCustomProducts,
  saveBanner,
  saveConfig,
  deleteBanner,
  editCustomProduct,
  editProductsCostByPercentage,
  getCustomAluminum,
  editCustomAluPrices,
  editAluCostByPercentage,
  getGuests,
  createGuestUser,
  deleteGuestUser,
} from "../services/services";
import { showNotification } from "@mantine/notifications";
import ConfigAluminum, { AluPrice } from "../components/Config/ConfigAluminum";
import PageWrapper from "../components/common/PageWrapper";
import ConfigGuests from "../components/Config/ConfigGuests";

type ProductEdit = {
  id: string;
  data: any;
};

enum ConfigTabs {
  QUOTE = "quote",
  GAINS = "gains",
  BARS = "bars",
  PRODUCTS = "products",
  GUESTS = "guests",
}

const ConfigPage = () => {
  const userCtx = useContext(UserContext);

  const queryClient = useQueryClient();

  const [currentSection, setCurrentSection] = useState<string>(
    ConfigTabs.QUOTE
  );

  const showSuccessNotification = (title: string, message: string) => {
    showNotification({
      title,
      message,
      color: "green",
      icon: <BiCheck size={32} />,
    });
  };

  const showFailureNotification = (title: string, message: string) => {
    showNotification({
      title,
      message,
      color: "red",
      icon: <BiError size={20} />,
    });
  };

  const { data: brandList } = useQuery(
    "brands",
    () => getBrands(userCtx.token),
    { placeholderData: [] }
  );
  const { data: customProds, status: customStatus } = useQuery(
    "custom",
    () => getCustomProducts(userCtx.token),
    { placeholderData: [] }
  );
  const { data: customAlu } = useQuery(
    "custom-alu",
    () => getCustomAluminum(userCtx.token),
    { placeholderData: [] }
  );
  const { data: bannerData } = useQuery(
    "banner",
    () => getBannerImage(userCtx.token),
    { placeholderData: "" }
  );
  const { data: guestsData, status: guestsStatus } = useQuery(
    "guests",
    () => getGuests(userCtx.data.id, userCtx.token),
    { placeholderData: [], enabled: userCtx.data.advFeatures }
  );
  const saveConfigMutation = useMutation(
    (newConfig: UserConfig) => saveConfig(newConfig, userCtx.token),
    {
      onSuccess: (data, variables) => {
        userCtx.editData({ config: variables });
        showSuccessNotification(
          "Guardar configuración",
          "La nueva configuración se ha guardado exitosamente."
        );
        queryClient.invalidateQueries("config");
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Guardar configuración",
          error.message ||
            "Ha ocurrido un error al guardar la nueva configuración. Intente de nuevo más tarde."
        );
      },
    }
  );
  const saveProductMutation = useMutation(
    (edit: ProductEdit) => editCustomProduct(edit.id, edit.data, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("custom");
        showSuccessNotification(
          "Editar producto",
          "El producto ha sido modificado."
        );
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Editar producto",
          error.message || "Ha ocurrido un error."
        );
      },
    }
  );
  const saveAluPricesMutation = useMutation(
    (edit: ProductEdit) =>
      editCustomAluPrices(edit.id, edit.data, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("custom-alu");
        showSuccessNotification(
          "Editar perfil",
          "El perfil ha sido modificado."
        );
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Editar perfil",
          error.message || "Ha ocurrido un error."
        );
      },
    }
  );
  const applyPercentageMutation = useMutation(
    ({
      type,
      brand,
      percentage,
    }: {
      type: string;
      brand: string;
      percentage: number;
    }) =>
      type === "alu"
        ? editAluCostByPercentage(brand, percentage, userCtx.token)
        : editProductsCostByPercentage(type, brand, percentage, userCtx.token),
    {
      onSuccess: (data, variables) =>
        queryClient.invalidateQueries(
          variables.type === "alu" ? "custom-alu" : "custom"
        ),
    }
  );
  const saveBannerMutation = useMutation(
    (newBanner: File) => saveBanner(newBanner, userCtx.token),
    {
      onSuccess: (data) => {
        queryClient.setQueryData("banner", data);
      },
    }
  );
  const deleteBannerMutation = useMutation(() => deleteBanner(userCtx.token), {
    onSuccess: () => {
      queryClient.setQueryData("banner", "");
    },
  });
  const createGuestMutation = useMutation(
    ({ name, email, pass }: { name: string; email: string; pass: string }) =>
      createGuestUser(name, email, pass, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("guests");
        showSuccessNotification(
          "Añadir empleado",
          "La cuenta de empleado ha sido creada."
        );
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Añadir empleado",
          error.message || "Ha ocurrido un error."
        );
      },
    }
  );
  const deleteGuestMutation = useMutation(
    (id: string) => deleteGuestUser(id, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("guests");
        showSuccessNotification(
          "Borrar empleado",
          "La cuenta de empleado ha sido eliminada."
        );
      },
      onError: (error: Error) => {
        showFailureNotification(
          "Borrar empleado",
          error.message || "Ha ocurrido un error."
        );
      },
    }
  );

  const [userConfigData, setUserConfigData] = useState<UserConfig>(
    userCtx.data.config
  );

  useEffect(() => {
    if (userCtx.data.config) {
      setUserConfigData({
        ...userCtx.data.config,
      });
    }
  }, [userCtx]);

  const saveConfigHandler = async (newConfig: UserConfig) => {
    saveConfigMutation.mutate(newConfig);
  };

  const saveBannerHandler = async (newBanner: File) => {
    const response = await saveBannerMutation.mutateAsync(newBanner);
    if (response) {
      showSuccessNotification(
        "Cargar imagen",
        "La nueva imagen se ha guardado correctamente."
      );
    } else {
      showFailureNotification(
        "Cargar imagen",
        "Ha ocurrido un error al intentar cargar la nueva imagen."
      );
    }
  };

  const deleteBannerHandler = async () => {
    const response = await deleteBannerMutation.mutateAsync();
    if (response) {
      showSuccessNotification(
        "Borrar imagen",
        "La imagen se ha borrado correctamente."
      );
    } else {
      showFailureNotification(
        "Borrar imagen",
        "Ha ocurrido un error al intentar borrar la imagen."
      );
    }
  };

  const saveCustomProductHandler = async (id: string, prod: any) => {
    try {
      const response = await saveProductMutation.mutateAsync({
        id,
        data: prod,
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const applyCustomPercentageHandler = async (
    type: string,
    brand: string,
    percentage: number
  ) => {
    const response = await applyPercentageMutation.mutateAsync({
      type,
      brand,
      percentage,
    });
    if (response) {
      showSuccessNotification(
        "Modificar con porcentaje",
        "El porcentaje ha sido aplicado correctamente."
      );
    }
    return response;
  };

  const saveCustomAluPricesHandler = async (id: string, data: any) => {
    try {
      const response = await saveAluPricesMutation.mutateAsync({
        id,
        data,
      });
      return response;
    } catch (err) {
      return null;
    }
  };

  const submitQuoteConfigHandler = (newConfig: UserConfig) => {
    saveConfigHandler({
      saveQuotes: newConfig.saveQuotes,
      hideBrand: newConfig.hideBrand,
      roundCosts: newConfig.roundCosts,
      currency: newConfig.currency,
      convertCost: newConfig.convertCost,
      storeName: newConfig.storeName.trim(),
      quoteFoot: newConfig.quoteFoot.trim(),
    });
  };

  const submitGainsHandler = (gains: CostSections) => {
    saveConfigHandler({ gains });
  };

  const submitAluminumHandler = (
    prices: AluPrice[],
    barLoss: number,
    cutLoss: number
  ) => {
    const newConfig: UserConfig = {
      cutLossPerBar: barLoss,
      cutLossPerCut: cutLoss,
    };
    if (prices && prices.length > 0) newConfig.aluPrices = prices;

    saveConfigHandler(newConfig);
  };

  const submitProdsHandler = (tejP: number, labP: number) => {
    saveConfigHandler({ tejP, labP });
  };

  const createGuestHandler = (name: string, email: string, pass: string) => {
    createGuestMutation.mutate({ name, email, pass });
  };

  const deleteGuestHandler = (id: string) => {
    deleteGuestMutation.mutate(id);
  };

  return (
    <PageWrapper>
      <Group my="lg" spacing={8}>
        <BiCog size={36} />
        <Title order={1}>Configuración</Title>
      </Group>

      <Text>
        Modifique estos valores para adaptar la aplicación a sus necesidades.
      </Text>
      <Tabs
        my="xl"
        mb="xl"
        variant="outline"
        keepMounted={false}
        value={currentSection}
        onTabChange={(value: string) => setCurrentSection(value)}
        styles={{
          tab: {
            height: "3em",
            padding: "0 20px",
            color: "dimgray",
            "&:hover": {
              backgroundColor: "whitesmoke",
            },
            "&[data-active]": {
              color: "black",
              backgroundColor: "white",
            },
          },
          tabsList: {
            border: "none",
          },
        }}
      >
        <Tabs.List position="center">
          <Tabs.Tab value={ConfigTabs.QUOTE} icon={<BiFile size="24" />}>
            <Text size="lg">Presupuesto</Text>
          </Tabs.Tab>
          <Tabs.Tab value={ConfigTabs.GAINS} icon={<BiPieChart size="24" />}>
            <Text size="lg">Ganancias</Text>
          </Tabs.Tab>
          <Tabs.Tab value={ConfigTabs.BARS} icon={<BiCollection size="24" />}>
            <Text size="lg">Barras</Text>
          </Tabs.Tab>
          <Tabs.Tab
            value={ConfigTabs.PRODUCTS}
            icon={<BiBriefcaseAlt2 size="24" />}
          >
            <Text size="lg">Productos</Text>
          </Tabs.Tab>
          {userCtx.data.advFeatures && (
            <Tabs.Tab value={ConfigTabs.GUESTS} icon={<BiGroup size="24" />}>
              <Text size="lg">Empleados</Text>
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value={ConfigTabs.QUOTE}>
          <ConfigClientQuote
            isLoading={
              customStatus === "loading" ||
              saveBannerMutation.isLoading ||
              deleteBannerMutation.isLoading ||
              saveConfigMutation.isLoading
            }
            intl={userCtx.data.intl ?? false}
            imgData={bannerData}
            storeName={userConfigData?.storeName}
            quoteFoot={userConfigData?.quoteFoot}
            saveQuotes={userConfigData?.saveQuotes}
            hideBrand={userConfigData?.hideBrand}
            roundCosts={userConfigData?.roundCosts}
            currencyCode={userConfigData?.currency}
            convertCost={userConfigData?.convertCost}
            onSubmit={submitQuoteConfigHandler}
            onSaveBanner={saveBannerHandler}
            onDeleteBanner={deleteBannerHandler}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ConfigTabs.GAINS}>
          <ConfigGains
            isLoading={
              customStatus === "loading" || saveConfigMutation.isLoading
            }
            gains={userConfigData?.gains}
            intl={userCtx.data.intl ?? false}
            onSubmit={submitGainsHandler}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ConfigTabs.BARS}>
          <ConfigAluminum
            isLoading={customStatus === "loading"}
            brandLines={brandList}
            canOptimizeCuts={userCtx.data.advFeatures}
            roundCosts={userCtx.data.config.roundCosts}
            prices={userCtx.data.intl ? [] : userConfigData?.aluPrices}
            cutLossPerBar={userConfigData?.cutLossPerBar}
            cutLossPerCut={userConfigData?.cutLossPerCut}
            intl={userCtx.data.intl ?? false}
            usd={userConfigData?.currency === "USD"}
            customAlu={customAlu}
            colors={customProds.filter(
              (p) =>
                p.cat === ProductCategory.PAINT ||
                p.cat === ProductCategory.ANOD
            )}
            onSubmit={submitAluminumHandler}
            onCustomSave={saveCustomAluPricesHandler}
            onPercentageApply={applyCustomPercentageHandler}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ConfigTabs.PRODUCTS}>
          <ConfigCustomizeProds
            isLoading={customStatus === "loading"}
            brands={brandList.map((bl) => {
              return { value: bl.code, label: bl.name };
            })}
            brandLines={brandList}
            aluP={userConfigData?.aluP}
            tejP={userConfigData?.tejP}
            labP={userConfigData?.labP}
            usd={userConfigData?.currency === "USD"}
            customProds={customProds}
            intl={userCtx.data.intl ?? false}
            roundCosts={userCtx.data.config.roundCosts}
            onSubmit={submitProdsHandler}
            onCustomSave={saveCustomProductHandler}
            onPercentageApply={applyCustomPercentageHandler}
          />
        </Tabs.Panel>
        <Tabs.Panel value={ConfigTabs.GUESTS}>
          <ConfigGuests
            guests={guestsData}
            onCreate={createGuestHandler}
            onDelete={deleteGuestHandler}
            loading={guestsStatus === "loading"}
            submitting={
              createGuestMutation.isLoading || deleteGuestMutation.isLoading
            }
          />
        </Tabs.Panel>
      </Tabs>
    </PageWrapper>
  );
};

export default ConfigPage;
