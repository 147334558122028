import { DefaultMantineColor, SelectItem, Tabs } from "@mantine/core";

type PillTabsProps = {
  brands: SelectItem[];
  value: string;
  color?: DefaultMantineColor;
  onChange: (value: string) => void;
};

const PillTabs = (props: PillTabsProps) => {
  return (
    <Tabs
      mt="lg"
      mb="xs"
      variant="pills"
      color={props.color || "brand.4"}
      value={props.value}
      onTabChange={(value: string) => props.onChange(value)}
      styles={(theme) => ({
        tab: {
          border: `1px solid gray`,
        },
      })}
    >
      <Tabs.List>
        {props.brands.map((b) => (
          <Tabs.Tab key={b.value} value={b.value} sx={{ padding: "12px 2em" }}>
            {b.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
    </Tabs>
  );
};

export default PillTabs;
