import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { SiteConfigContextProvider } from "./store/site-config-context";
import { UserContextProvider } from "./store/user-context";
import { MantineProvider } from "@mantine/core";
import { IconContext } from "react-icons/lib";

import "dayjs/locale/es";
import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";
import { Notifications } from "@mantine/notifications";
import { DatesProvider } from "@mantine/dates";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SiteConfigContextProvider>
          <UserContextProvider>
            <MantineProvider
              theme={{
                colors: {
                  brand: [
                    "#d0d7e1",
                    "#b1bcce",
                    "#91a1ba",
                    "#7286a6",
                    "#596c8d",
                    "#5a6e8f",
                    "#45546e",
                    "#313c4e",
                    "#1e242f",
                    "#0a0c10",
                  ],
                },
                primaryColor: "brand",
                colorScheme: "light",
                fontFamily: "Noto Sans JP, sans-serif",
                components: {
                  Paper: {
                    defaultProps: { shadow: "sm" },
                  },
                  Input: {
                    defaultProps: { size: "md" },
                  },
                  NumberInput: {
                    defaultProps: { size: "md" },
                  },
                  TextInput: {
                    defaultProps: { size: "md" },
                  },
                  PasswordInput: {
                    defaultProps: { size: "md" },
                  },
                  Textarea: {
                    defaultProps: { size: "md" },
                  },
                  Select: {
                    defaultProps: {
                      size: "md",
                      styles: { dropdown: { overscrollBehaviorY: "contain" } },
                    },
                  },
                  Checkbox: {
                    defaultProps: { size: "md" },
                  },
                  Switch: {
                    defaultProps: { size: "md" },
                  },
                  DatePicker: {
                    defaultProps: { size: "md" },
                  },
                  SegmentedControl: {
                    defaultProps: { size: "md" },
                  },
                  Divider: {
                    defaultProps: { size: "md" },
                  },
                  Indicator: {
                    defaultProps: { sx: { zIndex: "5" } },
                  },
                  Modal: {
                    defaultProps: { centered: true, xOffset: 0 },
                  },
                },
              }}
            >
              <IconContext.Provider value={{ className: "react-icons" }}>
                <DatesProvider settings={{ locale: "es", firstDayOfWeek: 0 }}>
                  <Notifications />
                  <App />
                </DatesProvider>
              </IconContext.Provider>
            </MantineProvider>
          </UserContextProvider>
        </SiteConfigContextProvider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
