import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  MediaQuery,
  NumberInput,
  Paper,
  Select,
  SelectItem,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useLocalStorage } from "@mantine/hooks";
import {
  BiAbacus,
  BiCalendar,
  BiCheckCircle,
  BiDollar,
  BiInfoCircle,
  BiPlusCircle,
  BiUser,
} from "react-icons/bi";

import BrandLines from "../../models/brand-lines";
import QuoteTotalTable from "./QuoteTotalTable";
import DesignFilter, { DesignFilterValues } from "../UI/DesignFilter";
import UnifiedPlanModal from "./Unified/UnifiedPlanModal";
import SelectDesign from "../UI/SelectDesign";
import { COMPACT_MODELS, QuotePartLayout } from "../../utils/globalConstants";
import Product from "../../models/product";
import PrintSafeTextarea from "../common/PrintSafeTextarea";
import PrintSafeTextInput from "../common/PrintSafeTextInput";
import { formatCurrencyDisplay } from "../../utils/amountFormat";
import PercentageInput from "../common/PercentageInput";

type QuoteControlBoxProps = {
  quoteCount: number;
  compactCount: number;
  designs: {
    value: string;
    label: string;
    brand: string;
    line: string;
    type: string;
  }[];
  custom: Product[];
  quoteNames: SelectItem[];
  brands: BrandLines[];
  onAddQuote: (designId: string | QuotePartLayout[]) => void;
  onAddCompact: (compactModelId: string) => void;
  onAddBonusItem: (name: string, count: number, cost: number) => void;
  onPrecalculateCost: (
    priceModifier: number,
    includeLaborCost: boolean
  ) => void;
  onFinalizeQuote: (
    quoteClientName: string,
    quoteDate: Date,
    priceModifier: number,
    quoteExtraText: string,
    calculateCutting: boolean,
    includeLaborCost: boolean
  ) => void;
  precalculatedCost: number;
  isPrecalculating: boolean;
  isLoading: boolean;
  maxQuoteCount: number;
  canAddQuotes: boolean;
  advancedFeatures: boolean;
  roundCosts: boolean;
  editQuoteData?: {
    client: string;
    text: string;
  };
};

const DESIGN_DROPDOWN_COUNT = 50;

const QuoteControlBox = (props: QuoteControlBoxProps) => {
  const { quoteCount, compactCount } = props;

  const [filter, setFilter] = useState<DesignFilterValues>({
    brand: "",
    line: "",
    type: "",
  });
  const [selectedDesign, setSelectedDesign] = useState<string>("");
  const [selectedCompact, setSelectedCompact] = useState<string>("");
  const [quoteClientName, setQuoteClientName] = useState<string>("");
  const [quoteDate, setQuoteDate] = useState<Date>(new Date());
  const [quotePriceModifier, setQuotePriceModifier] = useState<number>(0);
  const [quoteExtraText, setQuoteExtraText] = useState<string>("");

  const [unifModal, setUnifModal] = useState(false);

  const [bonusItem, setBonusItem] = useState({
    name: "",
    count: 1,
    cost: 0,
  });

  const [checkCutting, setCheckCutting] = useLocalStorage<boolean>({
    key: "option_doCutting",
    defaultValue: false,
  });

  const [checkLabor, setCheckLabor] = useLocalStorage<boolean>({
    key: "option_doLabor",
    defaultValue: false,
  });

  const changeFilter = (values: DesignFilterValues) => {
    setFilter((prev) => {
      return { ...prev, ...values };
    });
    setSelectedDesign("");
  };

  const clearFilter = () => {
    setFilter({
      brand: "",
      line: "",
      type: "",
    });
    setSelectedDesign("");
  };

  const addQuoteHandler = () => {
    props.onAddQuote(selectedDesign);
    setSelectedDesign("");
  };

  const addCompactHandler = () => {
    props.onAddCompact(selectedCompact);
    setSelectedCompact("");
  };

  const addUnifiedQuoteHandler = (unifiedDesigns: QuotePartLayout[]) => {
    props.onAddQuote(unifiedDesigns);
  };

  const addBonusItemHandler = () => {
    props.onAddBonusItem(
      bonusItem.name || "",
      bonusItem.count || 0,
      bonusItem.cost || 0
    );
    setBonusItem({
      name: "",
      count: 1,
      cost: 0,
    });
  };

  const changeCuttingCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckCutting(e.target.checked);
  };
  const changeLaborCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckLabor(e.target.checked);
  };

  const precalculateCostHandler = () => {
    props.onPrecalculateCost(quotePriceModifier, checkLabor);
  };

  const finalizeQuoteHandler = () => {
    props.onFinalizeQuote(
      quoteClientName,
      quoteDate,
      quotePriceModifier,
      quoteExtraText,
      checkCutting,
      checkLabor
    );
  };

  const filteredDesigns = props.designs.filter(
    (d) =>
      (filter.brand ? d.brand === filter.brand : true) &&
      (filter.line ? d.line === filter.line : true) &&
      (filter.type ? d.type === filter.type : true)
  );

  useEffect(() => {
    if (props.editQuoteData) {
      setQuoteClientName(props.editQuoteData.client);
      setQuoteExtraText(props.editQuoteData.text);
    }
  }, [props.editQuoteData]);

  return (
    <Group align="start" noWrap>
      <UnifiedPlanModal
        opened={unifModal}
        brands={props.brands}
        onConfirm={addUnifiedQuoteHandler}
        onClose={() => setUnifModal(false)}
        designs={props.designs.filter(
          (d) => d.type !== "mosq" && d.type !== "prem"
        )}
      />

      <Paper p="xl" sx={{ minWidth: "700px", maxWidth: "770px" }}>
        <Group mb="md" align="center" spacing={6}>
          <BiPlusCircle size="20" />
          <Title order={3}>Añadir al presupuesto</Title>
        </Group>
        <DesignFilter
          filter={filter}
          brands={props.brands}
          onChange={changeFilter}
          onClear={clearFilter}
          marginBottom="md"
          filledBg={true}
        />
        <Stack>
          {!props.canAddQuotes && (
            <Text color="red">{`Se ha alcanzado el límite de artículos en un solo presupuesto (${props.maxQuoteCount}).`}</Text>
          )}
          <Group align="end">
            <SelectDesign
              label="Diseños"
              sx={{ flexGrow: 1 }}
              data={filteredDesigns}
              value={selectedDesign}
              onChange={(value) => setSelectedDesign(value)}
              disabled={filteredDesigns.length === 0}
              limit={DESIGN_DROPDOWN_COUNT}
              maxDropdownHeight={300}
            />
            <Button
              size="md"
              onClick={addQuoteHandler}
              disabled={!selectedDesign || !props.canAddQuotes}
            >
              + Añadir diseño
            </Button>
          </Group>
          <Group align="end">
            <Select
              label="Compactos"
              placeholder="Buscar un compacto"
              sx={{ flexGrow: 1 }}
              data={COMPACT_MODELS.map((c) => ({
                value: c.code,
                label: c.name,
              }))}
              value={selectedCompact}
              onChange={(value) => setSelectedCompact(value)}
              clearable
              maxDropdownHeight={500}
              styles={{
                input: {
                  "::placeholder": {
                    color: "gray",
                  },
                },
              }}
            />
            <Button
              size="md"
              onClick={addCompactHandler}
              disabled={!selectedCompact || !props.canAddQuotes}
            >
              + Añadir compacto
            </Button>
          </Group>
          <Group position="right" style={{ flexGrow: 1 }}>
            <Button
              size="md"
              onClick={() => setUnifModal(true)}
              disabled={!props.canAddQuotes}
            >
              + Añadir abertura unificada
            </Button>
          </Group>
        </Stack>
        <Group mt="sm" mb="xl" align="start" noWrap>
          <PrintSafeTextInput
            label="Producto extra"
            maxLength={48}
            value={bonusItem.name}
            onChange={(value: string) =>
              setBonusItem((prev) => ({ ...prev, name: value }))
            }
          />
          <NumberInput
            label="Cant."
            min={0}
            max={999}
            value={bonusItem.count}
            onChange={(value: number) =>
              setBonusItem((prev) => ({ ...prev, count: value }))
            }
            sx={{ width: "6em" }}
          />
          <NumberInput
            label="Costo total"
            min={0}
            max={9999999}
            precision={props.roundCosts ? 0 : 2}
            decimalSeparator=","
            value={bonusItem.cost}
            onChange={(value: number) =>
              setBonusItem((prev) => ({ ...prev, cost: value }))
            }
            icon={<BiDollar size={20} />}
            sx={{ width: "11em" }}
          />
          <Button
            mt="xl"
            size="md"
            disabled={bonusItem.name.trim().length === 0}
            onClick={addBonusItemHandler}
          >
            + Añadir
          </Button>
        </Group>

        <Divider my={24} />

        <Group mb="md" align="center" spacing={6}>
          <BiInfoCircle size="20" />
          <Title order={3}>Información</Title>
        </Group>

        <Stack align="stretch">
          <Group noWrap>
            <PrintSafeTextInput
              label="Cliente"
              maxLength={32}
              icon={<BiUser size="20" />}
              value={quoteClientName}
              onChange={(value: string) => setQuoteClientName(value)}
              sx={{ flex: 1 }}
            />
            <DatePickerInput
              size="md"
              label="Fecha"
              sx={{ width: "16em", flexGrow: 0 }}
              clearable={false}
              maxLevel="month"
              icon={<BiCalendar size="20" />}
              value={quoteDate}
              onChange={(date) => setQuoteDate(date)}
            />
            {props.advancedFeatures && (
              <PercentageInput
                label="Descuento/Recargo"
                sx={{ width: "10em" }}
                min={-100}
                max={100}
                value={quotePriceModifier}
                onChange={(value) => setQuotePriceModifier(value || 0)}
              />
            )}
          </Group>
          <Box>
            <PrintSafeTextarea
              label="Texto adicional"
              description="Puede incluir un texto que se ubicará al final de este presupuesto específico (pero antes del texto de cierre definido en Configuración)."
              minRows={4}
              maxRows={8}
              maxLength={2000}
              autosize
              value={quoteExtraText}
              onChange={(value: string) => setQuoteExtraText(value)}
            />
            <Text
              align="right"
              color="gray"
              size="sm"
            >{`${quoteExtraText.length}/2000`}</Text>
          </Box>
        </Stack>
      </Paper>

      {(quoteCount > 0 || compactCount > 0) && (
        <MediaQuery query="(max-width: 1250px)" styles={{ maxWidth: "270px" }}>
          <Stack align="stretch" spacing="lg" sx={{ maxWidth: "400px" }}>
            <QuoteTotalTable quoteNames={props.quoteNames} />
            {props.advancedFeatures && (
              <Checkbox
                px="xs"
                size="sm"
                label="Calcular optimización de cortes de perfiles"
                description="Activar esta opción aumentará el tiempo de procesamiento y el tamaño del archivo Orden de Trabajo."
                checked={checkCutting}
                onChange={changeCuttingCheck}
              />
            )}
            <Checkbox
              px="xs"
              size="sm"
              label="Incluir costos de mano de obra"
              description="Para que esta función tenga efecto, debe especificar el costo de la mano de obra en Configuración, y el tiempo en cada Diseño."
              checked={checkLabor}
              onChange={changeLaborCheck}
            />
            {props.advancedFeatures && (
              <Button
                size="md"
                color="blue"
                variant="light"
                fullWidth
                onClick={precalculateCostHandler}
                loading={props.isPrecalculating}
              >
                <BiAbacus size="20" />
                <Text ml="6px">Estimar precio final</Text>
              </Button>
            )}
            {props.precalculatedCost === -1 && <Text>Calculando...</Text>}
            {props.precalculatedCost > 0 && (
              <Text>
                <Text mr="sm" fw="bold" span inherit>
                  Precio final estimado:
                </Text>
                {formatCurrencyDisplay(props.precalculatedCost, false)}
              </Text>
            )}
            <Button
              size="lg"
              color="green"
              fullWidth
              onClick={finalizeQuoteHandler}
              disabled={props.isPrecalculating}
              loading={props.isLoading}
            >
              <BiCheckCircle size="20" />
              <Text ml="6px">Finalizar presupuesto</Text>
            </Button>
            {/* <Text size="sm" align="center" color="dimmed">
              Confirmar y generar archivos para impresión
            </Text> */}
          </Stack>
        </MediaQuery>
      )}
    </Group>
  );
};

export default QuoteControlBox;
