import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FileButton,
  Group,
  Image,
  Input,
  Loader,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
} from "@mantine/core";
import bytes from "bytes";
import { BiSave } from "react-icons/bi";
import UserConfig from "../../models/user-config";
import PrintSafeTextarea from "../common/PrintSafeTextarea";
import PrintSafeTextInput from "../common/PrintSafeTextInput";
import PaperConfigSection from "./PaperConfigSection";

type ConfigClientQuoteProps = {
  isLoading: boolean;
  imgData: string;
  storeName: string;
  quoteFoot: string;
  saveQuotes: boolean;
  hideBrand: boolean;
  roundCosts: boolean;
  currencyCode: string;
  convertCost: number;
  intl: boolean;
  onSubmit: (config: UserConfig) => void;
  onSaveBanner: (newBanner: File) => void;
  onDeleteBanner: () => void;
};

const BANNER_FILE_FORMATS = ["image/png", "image/jpeg"];

const ConfigClientQuote = (props: ConfigClientQuoteProps) => {
  const { isLoading } = props;

  const [saveQuotes, setSaveQuotes] = useState(props.saveQuotes);
  const [hideBrand, setHideBrand] = useState(props.hideBrand);
  const [roundCosts, setRoundCosts] = useState(props.roundCosts);
  const [currencyCode, setCurrencyCode] = useState(props.currencyCode || "ARS");
  const [convertCost, setConvertCost] = useState(props.convertCost > 0);
  const [costMultiplier, setCostMultiplier] = useState(props.convertCost || 0);
  const [storeName, setStoreName] = useState(props.storeName ?? "");
  const [footText, setFootText] = useState(props.quoteFoot ?? "");

  const [fileError, setFileError] = useState("");

  const saveQuotesCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveQuotes(e.target.checked);
  };

  const hideBrandCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHideBrand(e.target.checked);
  };

  const roundCostsCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoundCosts(e.target.checked);
  };

  const convertCostsCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setCostMultiplier(checked ? 1 : 0);
    setConvertCost(checked);
  };

  const filePickedHandler = (file: File) => {
    if (!BANNER_FILE_FORMATS.includes(file.type)) {
      setFileError("El archivo seleccionado no es de un formato permitido.");
    } else if (file.size > bytes("1mb")) {
      setFileError("El archivo seleccionado excede el peso máximo (1 MB).");
    } else {
      setFileError("");
      props.onSaveBanner(file);
    }
  };

  const deleteBannerHandler = () => {
    setFileError("");
    props.onDeleteBanner();
  };

  const submitHandler = () => {
    props.onSubmit({
      saveQuotes,
      roundCosts,
      hideBrand,
      currency: currencyCode,
      convertCost: costMultiplier,
      storeName,
      quoteFoot: footText,
    });
  };

  return (
    <PaperConfigSection
      title="Presupuesto"
      description="Opciones de presentación para el presupuesto impreso que se entrega al
        cliente."
    >
      <form>
        <Stack>
          <Group>
            <Stack spacing={0} sx={{ flexGrow: 1 }}>
              <Text>Guardar los archivos de mis presupuestos en la nube</Text>
              <Text size="sm" color="dimmed">
                {`Encontrará los últimos ${process.env.REACT_APP_SAVED_QUOTES} archivos PDF generados en la
                sección Historial.`}
              </Text>
            </Stack>
            <Switch
              size="xl"
              onLabel="SI"
              offLabel="NO"
              checked={saveQuotes}
              onChange={saveQuotesCheckHandler}
            />
          </Group>

          <Divider size="xs" />

          <Group>
            <Stack spacing={0} sx={{ flexGrow: 1 }}>
              <Text>Redondear costos</Text>
              <Text size="sm" color="dimmed">
                La herramienta omitirá los centavos, redondeando hacia arriba.
              </Text>
            </Stack>
            <Switch
              size="xl"
              onLabel="SI"
              offLabel="NO"
              checked={roundCosts}
              onChange={roundCostsCheckHandler}
            />
          </Group>

          <Divider size="xs" />

          <Group>
            <Stack spacing={0} sx={{ flexGrow: 1 }}>
              <Text>Símbolo monetario</Text>
              <Text size="sm" color="dimmed">
                El tipo de símbolo que se imprimirá en el presupuesto junto a
                cada costo.
              </Text>
            </Stack>
            <Select
              value={currencyCode}
              onChange={(value: string) => setCurrencyCode(value)}
              data={[
                { value: "ARS", label: "$" },
                { value: "USD", label: "US$" },
              ]}
              sx={{ width: "11em" }}
            />
          </Group>

          <Divider size="xs" />

          {!props.intl && (
            <>
              <Group>
                <Stack spacing={0} sx={{ flexGrow: 1 }}>
                  <Text>{"Convertir a pesos (Cliente)"}</Text>
                  <Text size="sm" color="dimmed">
                    En el presupuesto para el cliente se convertirán los precios
                    con la cotización ingresada.
                  </Text>
                </Stack>
                <Group noWrap>
                  {convertCost && (
                    <NumberInput
                      min={0}
                      max={9999}
                      step={1}
                      precision={props.roundCosts ? 0 : 2}
                      decimalSeparator=","
                      icon={<Text>x</Text>}
                      value={costMultiplier}
                      onChange={(value) => setCostMultiplier(value || 0)}
                      sx={{ width: "10em" }}
                    />
                  )}
                  <Switch
                    size="xl"
                    onLabel="SI"
                    offLabel="NO"
                    checked={convertCost}
                    onChange={convertCostsCheckHandler}
                  />
                </Group>
              </Group>

              <Divider size="xs" />
            </>
          )}

          <Group>
            <Stack spacing={0} sx={{ flexGrow: 1 }}>
              <Text>Ocultar marcas</Text>
              <Text size="sm" color="dimmed">
                No se mostrará la marca de los artículos en el presupuesto.
              </Text>
            </Stack>
            <Switch
              size="xl"
              onLabel="SI"
              offLabel="NO"
              checked={hideBrand}
              onChange={hideBrandCheckHandler}
            />
          </Group>

          <Divider size="xs" />

          <Group spacing={4}>
            <Input.Wrapper
              label="Imagen de cabecera"
              description="Banner a imprimir al principio de cada presupuesto."
              size="md"
            >
              <Box my="xs" pl="xs" sx={{ borderLeft: "3px solid lightgray" }}>
                <Text size="sm" color="dimmed">
                  Preferiblemente una imagen apaisada (más ancha que alta).
                </Text>
                <Text size="sm" color="dimmed">
                  Tamaño recomendado: 900 x 180 px
                </Text>
                <Text size="sm" color="dimmed">
                  Formato: PNG o JPEG
                </Text>
              </Box>
              {isLoading && <Loader m="md" />}
              {!isLoading && props.imgData && (
                <Image
                  src={props.imgData}
                  style={{ border: "1px solid lightgray" }}
                />
              )}
              {!isLoading && !props.imgData && (
                <Text color="gray">{`(No se ha cargado ninguna imagen)`}</Text>
              )}
              {fileError && <Text color="red">{fileError}</Text>}
              <Group mt="sm">
                <FileButton
                  onChange={filePickedHandler}
                  accept={BANNER_FILE_FORMATS.join(",")}
                  disabled={isLoading}
                  multiple={false}
                >
                  {(props) => (
                    <Button {...props} loading={isLoading}>
                      Cargar imagen
                    </Button>
                  )}
                </FileButton>
                {props.imgData && (
                  <Button
                    color="red"
                    disabled={isLoading}
                    onClick={deleteBannerHandler}
                    loading={props.isLoading}
                  >
                    Borrar imagen
                  </Button>
                )}
              </Group>
            </Input.Wrapper>
          </Group>

          <Divider size="xs" />

          <PrintSafeTextInput
            sx={{ width: "32em" }}
            maxLength={64}
            label="Nombre del comercio"
            description="Título para imprimir a falta de una imagen de cabecera."
            value={storeName}
            onChange={(value: string) => setStoreName(value)}
            disabled={props.isLoading}
          />

          <Divider size="xs" />

          <Box>
            <PrintSafeTextarea
              label="Texto de cierre"
              description="Este texto aparecerá impreso al final de cada presupuesto."
              minRows={6}
              maxRows={12}
              maxLength={3000}
              autosize
              value={footText}
              onChange={(value: string) => setFootText(value)}
              disabled={props.isLoading}
            />
            <Text
              align="right"
              color="gray"
              size="sm"
            >{`${footText.length}/3000`}</Text>
          </Box>
        </Stack>
        <Group mt="xl" position="right">
          <Button
            loading={props.isLoading}
            onClick={submitHandler}
            sx={{ width: "12em" }}
          >
            <BiSave size="20" />
            <Text ml="6px">Guardar</Text>
          </Button>
        </Group>
      </form>
    </PaperConfigSection>
  );
};

export default ConfigClientQuote;
