import {
  Anchor,
  BackgroundImage,
  Box,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Link } from "react-router-dom";

import styles from "./HomeTopBanner.module.css";
import stock from "../../assets/img/stock_02.jpg";
import Logo from "../UI/Logo";

type HomeTopBannerProps = {
  isLoggedIn: boolean;
};

const START_REDIRECT = "/signup";
const LOGIN_REDIRECT = "/login";

const HomeTopBanner = (props: HomeTopBannerProps) => {
  return (
    <Box>
      <BackgroundImage src={stock} className={styles.topBannerImage}>
        <Paper className={styles.topBannerCard} radius="xs">
          <Stack>
            <Logo width="300px" />
            <Title color="black" order={2}>
              AluDig le permite presupuestar sus proyectos de carpintería de{" "}
              <Text color="blue" component="span" inherit>
                aluminio
              </Text>{" "}
              y{" "}
              <Text color="blue" component="span" inherit>
                PVC
              </Text>{" "}
              de manera{" "}
              <Text color="cyan" component="span" inherit>
                fácil
              </Text>{" "}
              y{" "}
              <Text color="cyan" component="span" inherit>
                rápida
              </Text>
            </Title>
            <Text color="gray" size="md" sx={{ lineHeight: 1.8 }}>
              Nuestra herramienta online incluye un{" "}
              <Text color="brand" weight="bold" component="span">
                diseñador de aberturas
              </Text>{" "}
              y una{" "}
              <Text color="brand" weight="bold" component="span">
                calculadora automática
              </Text>
              , con los que podrá generar presupuestos eficientemente, tanto
              para su propio uso como para entregar a sus clientes.
            </Text>
            {!props.isLoggedIn && (
              <Group noWrap>
                <Button
                  size="md"
                  sx={{ width: "13em" }}
                  component={Link}
                  to={START_REDIRECT}
                >
                  Comenzar
                </Button>
                <Anchor ml="lg" component={Link} to={LOGIN_REDIRECT}>
                  Iniciar sesión
                </Anchor>
              </Group>
            )}
          </Stack>
        </Paper>
      </BackgroundImage>
    </Box>
  );
};

export default HomeTopBanner;
