import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BiEdit,
  BiBorderAll,
  BiBorderNone,
  BiInfoCircle,
  BiErrorCircle,
} from "react-icons/bi";

import UserContext from "../../store/user-context";
import {
  Alert,
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteDesign,
  getBrands,
  getDesigns,
  getTemplateDesigns,
} from "../../services/services";
import DesignListTable from "./DesignListTable";

const NEW_LINK = "/design/new";
const EDIT_LINK = "/design/edit/";
const CREATE_FROM_USER_LINK = "/design/from/";
const CREATE_FROM_TEMPLATE_LINK = "/design/template/";

const MyDesigns = () => {
  const userCtx = useContext(UserContext);

  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [removingDesign, setRemovingDesign] = useState("");

  const queryClient = useQueryClient();
  const { data: designData, isFetching: isLoadingDesigns } = useQuery(
    "designs",
    () => getDesigns(userCtx.data.id, userCtx.token),
    { placeholderData: [] }
  );
  const { data: templateData, isFetching: isLoadingTemplates } = useQuery(
    "templates",
    () => getTemplateDesigns(userCtx.data.brds, userCtx.token),
    { placeholderData: [] }
  );
  const { data: brandData } = useQuery(
    "brands",
    () => getBrands(userCtx.token),
    { placeholderData: [] }
  );
  const deleteDesignMutation = useMutation(
    (designId: string) => deleteDesign(designId, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("designs");
      },
    }
  );

  const navigate = useNavigate();

  const editDesignHandler = (id: string) => {
    navigate(`${EDIT_LINK}${id}`);
  };

  const removeDesignHandler = (id: string) => {
    setRemovingDesign(id);
    setOpenRemoveModal(true);
  };

  const removeConfirmHandler = async () => {
    if (removingDesign) {
      deleteDesignMutation.mutate(removingDesign);
    }
    setOpenRemoveModal(false);
  };

  const baseNewDesignHandler = (designId: string, fromTemplate: boolean) => {
    navigate(
      `${
        fromTemplate ? CREATE_FROM_TEMPLATE_LINK : CREATE_FROM_USER_LINK
      }${designId}`
    );
  };

  const DESIGN_MAX_COUNT =
    userCtx.data.role && userCtx.data.role === "admin"
      ? 2000
      : Number(process.env.REACT_APP_MAX_DESIGNS) || 500;

  return (
    <div>
      <Modal
        centered
        size="md"
        padding="xl"
        withCloseButton={false}
        opened={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
      >
        <Stack align="center" my="sm" spacing="xl">
          <Text align="center">{`¿Desea eliminar el diseño "${
            removingDesign
              ? designData.find((d) => d._id === removingDesign)?.name
              : ""
          }"?`}</Text>
          <Group grow>
            <Button
              color="red"
              fullWidth
              onClick={removeConfirmHandler}
              loading={deleteDesignMutation.isLoading}
              sx={{ width: "150px" }}
            >
              Eliminar
            </Button>
            <Button
              variant="outline"
              fullWidth
              onClick={() => setOpenRemoveModal(false)}
              sx={{ width: "150px" }}
            >
              Cancelar
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Stack align="flex-start" spacing="xs">
        {designData.length >= DESIGN_MAX_COUNT ? (
          <Alert
            icon={<BiErrorCircle size="20" />}
            title="Atención"
            color="yellow"
            miw={500}
          >
            Se ha alcanzado el límite máximo de diseños.
          </Alert>
        ) : (
          <Button
            size="md"
            variant="light"
            component={designData.length >= DESIGN_MAX_COUNT ? "button" : Link}
            to={NEW_LINK}
            disabled={designData.length >= DESIGN_MAX_COUNT}
          >
            <BiEdit size="20" />
            <Text ml={4}>Nuevo diseño</Text>
          </Button>
        )}
      </Stack>

      <Stack mt="xl" spacing="xl">
        {designData.length > 0 ? (
          <DesignListTable
            title="Mis Diseños"
            titleIcon={<BiBorderAll size={28} />}
            data={designData}
            brandData={brandData}
            loading={isLoadingDesigns}
            templateTable={false}
            allowCreation={designData.length < DESIGN_MAX_COUNT}
            onEdit={(id: string) => editDesignHandler(id)}
            onRemove={(id: string) => removeDesignHandler(id)}
            onBase={(id: string, template: boolean) =>
              baseNewDesignHandler(id, template)
            }
          />
        ) : (
          <Paper mb="sm" p="xl" shadow="xs" sx={{ maxWidth: "1100px" }}>
            <Group noWrap>
              <ThemeIcon color="brand" variant="light" size={40} radius="xl">
                <BiInfoCircle size={40} />
              </ThemeIcon>
              <Text>
                Su cuenta aún no tiene diseños. Para comenzar, recomendamos
                elegir una de las plantillas que hemos preparado y hacer click
                en{" "}
                <Text span fw="bold" inherit>
                  "Usar como base"
                </Text>
                . Si ya tiene experiencia con nuestro programa, puede crear un{" "}
                <Text span fw="bold" inherit>
                  Nuevo diseño
                </Text>{" "}
                desde cero con el botón de arriba.
              </Text>
            </Group>
          </Paper>
        )}
        <DesignListTable
          title="Plantillas"
          titleIcon={<BiBorderNone size={28} />}
          data={templateData}
          brandData={brandData}
          loading={isLoadingTemplates}
          templateTable={true}
          allowCreation={designData.length < DESIGN_MAX_COUNT}
          onBase={(id: string, template: boolean) =>
            baseNewDesignHandler(id, template)
          }
        />
      </Stack>
    </div>
  );
};

export default MyDesigns;
