import { IMaskInput } from "react-imask";
import { Input, MantineSize, Sx, Text } from "@mantine/core";

type PhoneInputProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
  form?: string;
  maxLength?: number;
  size?: MantineSize;
  sx?: Sx;
  prefix?: React.ReactNode;
};

const PhoneInput = (props: PhoneInputProps) => {

  const phoneRegex = new RegExp(`^\\d{1,${props.maxLength || 10}}$`, "g");

  return (
    <Input.Wrapper
      label={props.label}
      description={props.description}
      error={props.error}
      required={props.required}
      size={props.size || "md"}
    >
      <Input
        sx={[props.sx]}
        component={IMaskInput}
        mask={phoneRegex}
        value={props.value}
        onAccept={(value: string, mask: any) => props.onChange(value)}
        required={props.required}
        size={props.size || "md"}
        icon={props.prefix ? <Text>{props.prefix}</Text> : undefined}
        iconWidth={props.prefix ? 60 : 0}
      />
    </Input.Wrapper>
  );
};

export default PhoneInput;
