const currencyFormat = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "ARS",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const currencyUsdFormat = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const weightFormat = new Intl.NumberFormat("es-AR", {
  style: "unit",
  unit: "kilogram",
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

const meterFormat = new Intl.NumberFormat("es-AR", {
  style: "unit",
  unit: "meter",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatCurrencyDisplay = (amount: number, usd: boolean) => {
  if (usd) return currencyUsdFormat.format(amount);
  else return currencyFormat.format(amount);
};
export const formatWeightDisplay = (amount: number) =>
  weightFormat.format(amount);
export const formatMeterDisplay = (mm: number) => meterFormat.format(mm / 1000);
