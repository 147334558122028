import { NumberInput, Sx } from "@mantine/core";
import { AiOutlinePercentage } from "react-icons/ai";

type PercentageInputProps = {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  sx?: Sx;
};

const PercentageInput = (props: PercentageInputProps) => {
  return (
    <NumberInput
      label={props.label}
      sx={props.sx || { width: "8em" }}
      value={props.value}
      onChange={(value: number) => {
        props.onChange(value || 0);
      }}
      step={1}
      min={props.min || 0}
      max={props.max || 200}
      rightSection={<AiOutlinePercentage color="gray" />}
    />
  );
};

export default PercentageInput;
