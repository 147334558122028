import { createContext, ReactNode, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSiteConfig } from "../services/services";

interface ISiteConfigContext {
  maintenance: boolean;
}

const SiteConfigContext = createContext<ISiteConfigContext>({
  maintenance: false,
});

type ContextProps = {
  children: ReactNode;
};

const MAINTENANCE_REDIRECT = "/maintenance";

export const SiteConfigContextProvider = (props: ContextProps) => {
  const [siteConfigState, setSiteConfigState] = useState({
    maintenance: false,
  });

  const navigate = useNavigate();
  useQuery("site-config", getSiteConfig, {
    onSuccess: (data) => {
      setSiteConfigState({
        maintenance: data.maintenance,
      });
      if (data.maintenance === true) {
        navigate(MAINTENANCE_REDIRECT);
      }
    },
  });

  const contextValue: ISiteConfigContext = {
    maintenance: siteConfigState.maintenance,
  };

  return (
    <SiteConfigContext.Provider value={contextValue}>
      {props.children}
    </SiteConfigContext.Provider>
  );
};

export default SiteConfigContext;
