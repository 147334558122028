import { Center, NumberInput, SegmentedControl, Text } from "@mantine/core";
import Item from "../../models/item";
import DiffInput from "../UI/DiffInput";
import RemoveButton from "../UI/RemoveButton";
import { ProductCategory } from "../../utils/globalConstants";

type DesignItemProps = {
  item: Item;
  acc: boolean;
  isDoor: boolean;
  hLetter?: string;
  vLetter?: string;
  onItemChange: (id: number, state: {}) => void;
  onItemRemove: (id: number) => void;
};

const angleList = [
  { value: "45", label: "45" },
  { value: "90", label: "90" },
];
const dobleAngle = { value: "4590", label: "45/90" };

const formatWeightDisplay = (x: number) => {
  return x.toLocaleString("es-AR", {
    style: "decimal",
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });
};

const DesignItem = (props: DesignItemProps) => {
  const { isDoor, hLetter = "A", vLetter = "H" } = props;
  const { itemId } = props.item;

  const diffChangeHandler = (value: number) => {
    props.onItemChange(itemId, { diff: value ?? 0 });
  };
  const mulChangeHandler = (value: number) => {
    props.onItemChange(itemId, { mul: value ?? 1 });
  };
  const angleChangeHandler = (value: string) => {
    props.onItemChange(itemId, { ang: +value });
  };
  const orientChangeHandler = (value: string) => {
    props.onItemChange(itemId, { ori: value });
  };
  const countChangeHandler = (value: number) => {
    props.onItemChange(itemId, { count: value ?? 1 });
  };
  const removeClickHandler = () => {
    props.onItemRemove(itemId);
  };

  const productAngle = props.item.prodAng;
  let angleContent;
  if (Number.isInteger(productAngle)) {
    if (productAngle === -1 || (isDoor && productAngle)) {
      angleContent = (
        <Center>
          <SegmentedControl
            size="sm"
            data={isDoor ? angleList.concat(dobleAngle) : angleList}
            color="brand"
            value={props.item.ang.toString()}
            onChange={angleChangeHandler}
            styles={{ label: { padding: "4px 16px" } }}
          />
        </Center>
      );
    } else angleContent = productAngle.toString();
  } else angleContent = "";

  const productOrient = props.item.prodOrient;
  let orientContent;
  let diffOrMulContent =
    props.item.cat !== ProductCategory.MOSQUITO &&
    props.item.cat !== ProductCategory.PREMARC ? (
      <Center>
        {props.item.mul ? (
          <NumberInput
            icon={<Text size="sm">x</Text>}
            size="sm"
            sx={{ width: "6em" }}
            min={1}
            max={99}
            value={props.item.mul}
            onChange={mulChangeHandler}
          />
        ) : (
          <DiffInput
            size="sm"
            value={props.item.diff}
            onChange={diffChangeHandler}
          />
        )}
      </Center>
    ) : null;
  if (productOrient === "s" || productOrient === "m") {
    orientContent = (
      <Center>
        <SegmentedControl
          size="sm"
          data={[
            { value: "h", label: hLetter ?? "A" },
            { value: "v", label: vLetter ?? "H" },
          ]}
          color="brand"
          value={props.item.ori}
          onChange={orientChangeHandler}
          styles={{ label: { padding: "4px 16px" } }}
        />
      </Center>
    );
  } else if (productOrient === "h") {
    orientContent = hLetter ?? "A";
  } else if (productOrient === "v") {
    orientContent = vLetter ?? "H";
  } else {
    diffOrMulContent = null;
  }

  const countContent = !props.item.mul ? (
    <Center>
      <NumberInput
        size="sm"
        sx={{ width: "5em" }}
        min={1}
        max={999}
        value={props.item.count}
        onChange={countChangeHandler}
      />
    </Center>
  ) : null;

  return (
    <tr>
      <td>{props.item.code}</td>
      <td>{props.item.name}</td>
      <td>{orientContent}</td>
      <td>{diffOrMulContent}</td>
      {!props.acc && <td>{angleContent}</td>}
      {!props.acc && <td>{formatWeightDisplay(props.item.wgt)}</td>}
      <td>{countContent}</td>
      <td>
        <Center>
          <RemoveButton onClick={removeClickHandler} />
        </Center>
      </td>
    </tr>
  );
};

export default DesignItem;
