import {
  Alert,
  Button,
  Group,
  List,
  PasswordInput,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useState } from "react";
import {
  BiEnvelope,
  BiError,
  BiInfoCircle,
  BiLockAlt,
  BiTrash,
  BiUser,
  BiUserPlus,
} from "react-icons/bi";
import PasswordEditInput from "../Signup/PasswordEditInput";
import PaperConfigSection from "./PaperConfigSection";

type ConfigGuestsProps = {
  guests: GuestData[];
  onCreate: (name: string, email: string, pass: string) => void;
  onDelete: (id: string) => void;
  loading: boolean;
  submitting: boolean;
};

const MAX_GUESTS = 2;

const ConfigGuests = (props: ConfigGuestsProps) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [passValid, setPassValid] = useState<boolean>(false);
  const [displayGuestError, setDisplayGuestError] = useState<string>("");

  const nameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const passChangeHandler = (value: string) => {
    setPass(value);
  };
  const passConfirmChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassConfirm(e.target.value);
  };

  const submitGuestHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (pass !== passConfirm) {
      setDisplayGuestError("Las contraseñas no coinciden.");
      return;
    }
    if (!passValid) {
      setDisplayGuestError("La contraseña no cumple los requisitos mínimos.");
      return;
    }
    setDisplayGuestError("");
    const formattedName = name.trim();
    const formattedEmail = email.trim();
    props.onCreate(formattedName, formattedEmail, pass);
    setName("");
    setEmail("");
    setPass("");
    setPassConfirm("");
    setPassValid(false);
  };

  const removeGuestHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const removeId = e.currentTarget.value;
    props.onDelete(removeId);
  };

  return (
    <PaperConfigSection
      title="Empleados"
      description="Puede definir hasta dos cuentas de empleado enlazadas a su cuenta
        principal."
    >
      <Alert
        m="xl"
        title="Notas"
        color="blue"
        icon={<BiInfoCircle size={32} />}
      >
        <List pr="sm" size="sm">
          <List.Item>
            Las cuentas de empleado pueden generar presupuestos en su nombre y
            ver el historial, pero no modificar los diseños ni acceder a la
            configuración.
          </List.Item>
          <List.Item>
            Estas cuentas forman parte de la cuenta principal: si esta última es
            desactivada o eliminada, lo mismo ocurre con las cuentas de
            empleado.
          </List.Item>
        </List>
      </Alert>

      <Stack mx="auto" mb="sm" maw={600} spacing="xs">
        {props.guests.length > 0 && (
          <Table color="brand">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Correo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.guests.map((guest) => (
                <tr key={guest.email}>
                  <td width="24px" align="center">
                    <BiUser size={20} />
                  </td>
                  <td>{guest.firstName}</td>
                  <td>{guest.email}</td>
                  <td align="right">
                    <Button
                      p="xs"
                      variant="subtle"
                      color="red"
                      value={guest.id}
                      onClick={removeGuestHandler}
                      loading={props.submitting}
                      disabled={props.loading}
                    >
                      <BiTrash />
                      <Text ml={3}>Eliminar</Text>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {props.guests.length < MAX_GUESTS && (
          <Stack mt="md" mx="auto" p="md" bg="brand.0" maw={400}>
            <Group align="center" spacing={4}>
              <BiUserPlus size={24} />
              <Title order={4}>Nuevo empleado</Title>
            </Group>
            <form id="guestSignupForm" onSubmit={submitGuestHandler}>
              <Stack spacing="xs">
                <TextInput
                  size="sm"
                  placeholder="Nombre"
                  form="guestSignupForm"
                  icon={<BiUser />}
                  value={name}
                  onChange={nameChangeHandler}
                  maxLength={16}
                  disabled={props.loading || props.submitting}
                  required
                />
                <TextInput
                  size="sm"
                  placeholder="Correo electrónico"
                  form="guestSignupForm"
                  icon={<BiEnvelope />}
                  type="email"
                  value={email}
                  onChange={emailChangeHandler}
                  maxLength={64}
                  disabled={props.loading || props.submitting}
                  required
                />
                <PasswordEditInput
                  size="sm"
                  placeholder="Contraseña"
                  form="guestSignupForm"
                  icon={<BiLockAlt />}
                  value={pass}
                  onChange={passChangeHandler}
                  onValidityChange={(valid: boolean) => setPassValid(valid)}
                  disabled={props.loading || props.submitting}
                  required
                />
                <PasswordInput
                  size="sm"
                  placeholder="Confirmar contraseña"
                  form="guestSignupForm"
                  icon={<BiLockAlt />}
                  value={passConfirm}
                  onChange={passConfirmChangeHandler}
                  disabled={props.loading || props.submitting}
                  required
                />
              </Stack>
              <Group mt="md" position="right">
                <Button
                  form="guestSignupForm"
                  type="submit"
                  loading={props.submitting}
                  disabled={props.guests.length >= MAX_GUESTS || props.loading}
                >
                  Crear cuenta
                </Button>
              </Group>
            </form>
            {displayGuestError && (
              <Alert
                color="red"
                title="Error"
                icon={<BiError size={24} />}
                styles={{
                  message: {
                    whiteSpace: "pre-line",
                  },
                }}
              >
                {displayGuestError}
              </Alert>
            )}
          </Stack>
        )}
      </Stack>
    </PaperConfigSection>
  );
};

export default ConfigGuests;
