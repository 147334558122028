import BrandLines from "../models/brand-lines";
import Design from "../models/design";
import HistoryQuoteState from "../models/history-quote-state";
import Item from "../models/item";
import Product from "../models/product";
import SavedQuote from "../models/saved-quote";
import UserConfig from "../models/user-config";
import UserData from "../models/user-data";
import { Country, Plan } from "../utils/globalConstants";

const baseUrl = process.env.REACT_APP_BACKEND_API_URL;
const jsonHeader = { "Content-Type": "application/json" };

type BrandListItem = {
  code: string;
  name: string;
  country: string;
  pvc?: boolean;
};

export type DesignSaveBody = {
  name: string;
  brand: string;
  line: string;
  items: Item[];
  open: string;
  revId: string;
  revDiff?: number[];
  mosDiff?: number[];
  tejDiff?: number[];
  premDiff?: number[];
  moduDiff?: number[];
  guiDiff?: number;
  cvdMul?: number;
  laborTime?: number;
  pan: number;
  crossId: string;
  crossDiff?: number[];
  ok: boolean;
};

type PdfRequestResponse = {
  costFile: {
    filename: string;
    content: string;
  };
  orderFile: {
    filename: string;
    content: string;
  };
  clientFile: {
    filename: string;
    content: string;
  };
  materialFile: {
    filename: string;
    content: string;
  };
};

type ErrorData = {
  message: string;
};

const authHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

// const sendRequest = async (url: string, method = "GET", authHeader = false) => {
//   const headers = { method };
//   const response = await fetch(url);
// };

export const loginUser = async (email: string, pass: string) => {
  const body = JSON.stringify({ email, pass });
  const response = await fetch(`${baseUrl}/users/login`, {
    method: "POST",
    headers: { ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  return response.json();
};

export const signupUser = async (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  pass: string,
  country: string,
  city: string,
  plan: string,
  brds: string[],
  captcha: string
) => {
  const body = JSON.stringify({
    firstName,
    lastName,
    email,
    phone,
    pass,
    country,
    city,
    plan,
    brds,
    captcha,
  });
  const response = await fetch(`${baseUrl}/users/signup`, {
    method: "POST",
    headers: { ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  return response.json();
};

export const getUserData = async (token: string): Promise<UserData> => {
  const response = await fetch(`${baseUrl}/users/me`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const editUserData = async (
  token: string,
  firstName: string,
  lastName: string,
  phone: string,
  city: string
): Promise<UserData> => {
  const body = JSON.stringify({ data: { firstName, lastName, phone, city } });
  const response = await fetch(`${baseUrl}/users/me`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  return response.json();
};

export const changeUserPassword = async (
  oldPass: string,
  newPass: string,
  token: string
): Promise<boolean> => {
  const body = JSON.stringify({ oldPass, newPass });
  const response = await fetch(`${baseUrl}/users/me/change-password`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const deleteUserAccount = async (
  email: string,
  pass: string,
  token: string
): Promise<boolean> => {
  const body = JSON.stringify({ email, pass });
  const response = await fetch(`${baseUrl}/users/me`, {
    method: "DELETE",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const getDesigns = async (
  userId: string,
  token: string
): Promise<Design[]> => {
  const response = await fetch(`${baseUrl}/designs/user/${userId}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getTemplateDesigns = async (
  brands: string[],
  token: string
): Promise<Design[]> => {
  const brandsString = brands.join(",");
  const response = await fetch(`${baseUrl}/designs/templates/${brandsString}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getBrands = async (token: string): Promise<BrandLines[]> => {
  const response = await fetch(`${baseUrl}/products/brands`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getBrandList = async (): Promise<BrandListItem[]> => {
  const response = await fetch(`${baseUrl}/products/brandlist`);
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const deleteDesign = async (designId: string, token: string) => {
  const response = await fetch(`${baseUrl}/designs/${designId}`, {
    method: "DELETE",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getHistory = async (token: string): Promise<SavedQuote[]> => {
  const response = await fetch(`${baseUrl}/quote/history`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getHistoryFile = async (
  quoteId: string,
  file: string,
  token: string
) => {
  const response = await fetch(`${baseUrl}/quote/file/${quoteId}/${file}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    return null;
  }
  return response.json();
};

export const deleteHistoryFile = async (quoteId: string, token: string) => {
  const response = await fetch(`${baseUrl}/quote/delete/${quoteId}`, {
    method: "POST",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const deleteAllHistory = async (token: string) => {
  const response = await fetch(`${baseUrl}/quote/delete`, {
    method: "POST",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getHistoryQuoteState = async (
  quoteId: string,
  token: string
): Promise<HistoryQuoteState> => {
  const response = await fetch(`${baseUrl}/quote/state/${quoteId}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    return null;
  }
  return response.json();
};

export const getExtraProducts = async (token: string): Promise<Product[]> => {
  const response = await fetch(`${baseUrl}/products/extra`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getCustomProducts = async (token: string): Promise<Product[]> => {
  const response = await fetch(`${baseUrl}/products/custom`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getCustomAluminum = async (token: string): Promise<Product[]> => {
  const response = await fetch(`${baseUrl}/products/alu`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const editCustomProduct = async (
  productId: string,
  productData: any,
  token: string
): Promise<Product> => {
  const body = JSON.stringify(productData);
  const response = await fetch(`${baseUrl}/products/edit/${productId}`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return response.json();
};

export const editProductsCostByPercentage = async (
  type: string,
  brand: string,
  percentage: number,
  token: string
) => {
  const body = JSON.stringify({ type, brand, percentage });
  const response = await fetch(`${baseUrl}/products/edit-perc`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const editCustomAluPrices = async (
  productId: string,
  data: any,
  token: string
): Promise<Product> => {
  const body = JSON.stringify({ prices: data });
  const response = await fetch(`${baseUrl}/products/edit/alu/${productId}`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return response.json();
};

export const editAluCostByPercentage = async (
  brand: string,
  percentage: number,
  token: string
) => {
  const body = JSON.stringify({ brand, percentage });
  const response = await fetch(`${baseUrl}/products/edit-alu-perc`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getBannerImage = async (token: string) => {
  const response = await fetch(`${baseUrl}/users/me/banner`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  const imageData = await response.json();
  return imageData?.content
    ? `data:image/png;base64, ${imageData.content}`
    : "";
};

export const saveBanner = async (newBanner: File, token: string) => {
  const formData = new FormData();
  formData.append("banner", newBanner);
  const response = await fetch(`${baseUrl}/users/me/banner`, {
    method: "POST",
    headers: authHeader(token),
    body: formData,
  });
  if (!response.ok) {
    throw new Error();
  }
  const imageData = await response.json();
  return imageData?.content
    ? `data:image/png;base64, ${imageData.content}`
    : "";
};

export const deleteBanner = async (token: string) => {
  const response = await fetch(`${baseUrl}/users/me/banner`, {
    method: "DELETE",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const saveConfig = async (
  newConfig: UserConfig,
  token: string
): Promise<UserConfig> => {
  const body = JSON.stringify({ config: newConfig });
  const response = await fetch(`${baseUrl}/users/me/config`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return response.json();
};

export const createGuestUser = async (
  name: string,
  email: string,
  pass: string,
  token: string
) => {
  const body = JSON.stringify({
    name,
    email,
    pass,
  });
  const response = await fetch(`${baseUrl}/users/guest`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  return response.json();
};

export const getGuests = async (
  userId: string,
  token: string
): Promise<GuestData[]> => {
  const response = await fetch(`${baseUrl}/users/${userId}/guests`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const deleteGuestUser = async (guestId: string, token: string) => {
  const response = await fetch(`${baseUrl}/users/guest/${guestId}`, {
    method: "DELETE",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getDesignById = async (
  designId: string,
  token: string
): Promise<Design> => {
  const response = await fetch(`${baseUrl}/designs/${designId}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getMultipleDesigns = async (
  designIds: string[],
  token: string
): Promise<Design[]> => {
  const body = JSON.stringify({ ids: designIds });
  const response = await fetch(`${baseUrl}/designs/fetch`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const saveNewDesign = async (
  designBody: DesignSaveBody,
  token: string
) => {
  const body = JSON.stringify(designBody);
  const response = await fetch(`${baseUrl}/designs`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return true;
};

export const editDesign = async (
  designId: string,
  designBody: DesignSaveBody,
  token: string
) => {
  const body = JSON.stringify(designBody);
  const response = await fetch(`${baseUrl}/designs/${designId}`, {
    method: "PATCH",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return true;
};

export const getProductsByBrandLine = async (
  brand: string,
  line: string,
  token: string
): Promise<Product[]> => {
  const response = await fetch(`${baseUrl}/products/${brand}-${line}`, {
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const precalculateQuoteCost = async (
  requestBody: any,
  token: string
): Promise<{ cost: number }> => {
  const body = JSON.stringify(requestBody);
  const response = await fetch(`${baseUrl}/quote/precalculate`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return response.json();
};

export const requestQuote = async (
  requestBody: any,
  token: string
): Promise<PdfRequestResponse> => {
  const body = JSON.stringify(requestBody);
  const response = await fetch(`${baseUrl}/quote/request`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    const errorData: ErrorData = await response.json();
    throw new Error(errorData.message);
  }
  return response.json();
};

export const setTerms = async (token: string): Promise<boolean> => {
  const response = await fetch(`${baseUrl}/users/me/accept-terms`, {
    method: "PATCH",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const setFirstTime = async (token: string): Promise<boolean> => {
  const response = await fetch(`${baseUrl}/users/me/first-time`, {
    method: "PATCH",
    headers: authHeader(token),
  });
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const sendContactMessage = async (
  subject: string,
  content: string,
  token: string
) => {
  const body = JSON.stringify({ subject, content });
  const response = await fetch(`${baseUrl}/message`, {
    method: "POST",
    headers: { ...authHeader(token), ...jsonHeader },
    body,
  });
  if (!response.ok) {
    throw new Error();
  }
  return true;
};

export const getSiteConfig = async () => {
  const response = await fetch(`${baseUrl}/config`);
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};

export const getCountries = async (): Promise<Country[]> => {
  const response = await fetch(`${baseUrl}/config/countries`);
  if (!response.ok) {
    throw new Error();
  }
  const countries: Country[] = await response.json();
  return countries;
};

export const getPlans = async (): Promise<Plan[]> => {
  const response = await fetch(`${baseUrl}/config/plans`);
  if (!response.ok) {
    throw new Error();
  }
  const plans: Plan[] = await response.json();
  return plans.sort((a, b) => a.price - b.price);
};

export const getHelpFaq = async (): Promise<any[]> => {
  const response = await fetch(`${baseUrl}/config/faq`);
  if (!response.ok) {
    throw new Error();
  }
  return response.json();
};
