import { useEffect, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Group,
  Loader,
  NumberInput,
  SelectItem,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import { BiDollar, BiSave } from "react-icons/bi";
import Product from "../../models/product";
import PillTabs from "../common/PillTabs";
import PaperConfigSection from "./PaperConfigSection";
import CustomProductTable from "./CustomProductTable";
import BrandLines from "../../models/brand-lines";
import { ProductCategory } from "../../utils/globalConstants";

type ConfigCustomizeProdsProps = {
  isLoading: boolean;
  aluP: number;
  tejP: number;
  labP: number;
  usd?: boolean;
  customProds: Product[];
  brands: SelectItem[];
  brandLines: BrandLines[];
  intl: boolean;
  roundCosts: boolean;
  onSubmit: (tejP: number, labP: number) => void;
  onCustomSave: (id: string, prod: any) => Promise<Product>;
  onPercentageApply: (
    type: string,
    brand: string,
    percentage: number
  ) => Promise<boolean>;
};

enum CategoryTab {
  ACCESSORIES = "accessories",
  GLASSES = "vidpol",
  PAINT = "pinano",
  COMPACTS = "compacts",
}

const ConfigCustomizeProds = (props: ConfigCustomizeProdsProps) => {
  const [tejPrice, setTejPrice] = useState<number>(props.tejP || 200);
  const [labPrice, setLabPrice] = useState<number>(props.labP || 0);
  const [categoryTab, setCategoryTab] = useState(CategoryTab.ACCESSORIES);
  const [brandTab, setBrandTab] = useState(props.brands[0]?.value);
  const [hasPvcBrands, setHasPvcBrands] = useState(false);

  const submitHandler = () => {
    props.onSubmit(tejPrice, labPrice);
  };

  const saveCustomProdHandler = async (id: string, prod: any) => {
    return await props.onCustomSave(id, prod);
  };

  const applyPercentageHandler = async (percentage: number) => {
    if (categoryTab === CategoryTab.ACCESSORIES)
      return await props.onPercentageApply("a", brandTab, percentage);
    else return await props.onPercentageApply(categoryTab, null, percentage);
  };

  useEffect(() => {
    setHasPvcBrands(props.brandLines.some((b) => b.pvc));
  }, [props.brandLines]);

  return (
    <PaperConfigSection
      title="Productos"
      description="Personalice los datos de accesorios, vidrios y pinturas a utilizar."
    >
      <Group spacing="xl" align="flex-end">
        <NumberInput
          label="Costo tejido mosq. (p/ m²)"
          sx={{ width: "15em" }}
          min={1}
          max={99999}
          precision={props.roundCosts ? 0 : 2}
          decimalSeparator=","
          icon={<BiDollar size="20" />}
          value={tejPrice}
          onChange={(value) => setTejPrice(value || 0)}
          disabled={props.isLoading}
        />
        <NumberInput
          label="Costo mano de obra (p/ hora)"
          sx={{ width: "15em" }}
          min={0}
          max={99999}
          precision={props.roundCosts ? 0 : 2}
          decimalSeparator=","
          icon={<BiDollar size="20" />}
          value={labPrice}
          onChange={(value) => setLabPrice(value || 0)}
          disabled={props.isLoading}
        />
        <Button
          loading={props.isLoading}
          onClick={submitHandler}
          sx={{ width: "12em" }}
        >
          <BiSave size="20" />
          <Text ml="6px">Guardar</Text>
        </Button>
      </Group>

      <Divider mt="xl" size="sm" />

      {props.customProds.length === 0 && (
        <Center pt={80} pb={60}>
          <Stack align="center">
            <Text size="lg" color="dimmed">
              Cargando productos...
            </Text>
            <Loader size="lg" variant="dots" />
          </Stack>
        </Center>
      )}
      {props.customProds.length > 0 && (
        <Tabs
          my="lg"
          color="brand"
          value={categoryTab}
          onTabChange={(value: CategoryTab) => {
            setCategoryTab(value);
          }}
          sx={{ minHeight: "200px" }}
        >
          <Tabs.List grow>
            <Tabs.Tab value={CategoryTab.ACCESSORIES}>
              <Text size="md">Accesorios</Text>
            </Tabs.Tab>
            <Tabs.Tab value={CategoryTab.GLASSES}>
              <Text size="md">Vidrios, paneles y policarb.</Text>
            </Tabs.Tab>
            <Tabs.Tab value={CategoryTab.PAINT}>
              <Text size="md">Pinturas y anodizados</Text>
            </Tabs.Tab>
            <Tabs.Tab value={CategoryTab.COMPACTS}>
              <Text size="md">Compactos</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={CategoryTab.ACCESSORIES}>
            <>
              {props.brands.length > 0 && (
                <PillTabs
                  brands={props.brands}
                  value={brandTab}
                  onChange={(value) => setBrandTab(value)}
                />
              )}
              <CustomProductTable
                products={props.customProds.filter(
                  (p) =>
                    p.cat === ProductCategory.ACC &&
                    (p.brd === brandTab || !p.brd)
                )}
                onPercentageApply={applyPercentageHandler}
                onCustomSave={saveCustomProdHandler}
                usd={props.usd}
                roundCosts={props.roundCosts}
                showPercentageButton
                displayUnits
              />
            </>
          </Tabs.Panel>
          <Tabs.Panel value={CategoryTab.GLASSES}>
            <CustomProductTable
              mt={10}
              products={props.customProds.filter((p) =>
                [
                  ProductCategory.GLASS,
                  ProductCategory.POLIC,
                  ProductCategory.PNL,
                ].includes(p.cat as ProductCategory)
              )}
              onPercentageApply={applyPercentageHandler}
              onCustomSave={saveCustomProdHandler}
              usd={props.usd}
              fixedUnits="m²"
              roundCosts={props.roundCosts}
              showPercentageButton
            />
          </Tabs.Panel>
          <Tabs.Panel value={CategoryTab.PAINT}>
            <CustomProductTable
              mt={10}
              products={props.customProds
                .filter((p) =>
                  [ProductCategory.PAINT, ProductCategory.ANOD].includes(
                    p.cat as ProductCategory
                  )
                )
                .filter((p) => (hasPvcBrands ? true : p.spc !== "pvc"))}
              onPercentageApply={applyPercentageHandler}
              onCustomSave={saveCustomProdHandler}
              usd={props.usd}
              fixedUnits="kg alum."
              roundCosts={props.roundCosts}
              hideCost={props.intl}
              showSpecialTag
              showColor
              showPercentageButton
            />
          </Tabs.Panel>
          <Tabs.Panel value={CategoryTab.COMPACTS}>
            <CustomProductTable
              mt={10}
              products={props.customProds.filter(
                (p) => p.cat === ProductCategory.COMPACT
              )}
              onCustomSave={saveCustomProdHandler}
              usd={props.usd}
              fixedUnits="m²"
              roundCosts={props.roundCosts}
            />
          </Tabs.Panel>
        </Tabs>
      )}
    </PaperConfigSection>
  );
};

export default ConfigCustomizeProds;
