import { List, Paper, SelectItem, Title } from "@mantine/core";
import { BiCheck } from "react-icons/bi";

type QuoteTotalTableProps = {
  quoteNames: SelectItem[];
};

const QuoteTotalTable = (props: QuoteTotalTableProps) => {
  return (
    <Paper p="xl" shadow="sm">
      <Title order={3} mb="md">
        Artículos
      </Title>
      <List spacing="xs" icon={<BiCheck size={20} />} center>
        {props.quoteNames.map((q) => (
          <List.Item key={q.value}>{q.label}</List.Item>
        ))}
      </List>
    </Paper>
  );
};

export default QuoteTotalTable;
