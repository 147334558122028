const MIN_MODULES = 1;
const MAX_MODULES = 5;

export const recalculateModules = (
  modules: (string | string[])[],
  horzCrossbars: number | number[]
) => {
  let newModules = modules;
  if (
    horzCrossbars &&
    (typeof horzCrossbars === "number" || typeof horzCrossbars === "object")
  ) {
    const receivedCount =
      typeof horzCrossbars === "number"
        ? horzCrossbars + 1
        : horzCrossbars.length + 1;
    const newModuleCount = Math.min(
      Math.max(receivedCount, MIN_MODULES),
      MAX_MODULES
    );
    if (newModules.length < newModuleCount) {
      const extraCount = newModuleCount - newModules.length;
      const extraModules = Array(extraCount).fill("", 0);
      newModules = newModules.concat(extraModules);
    } else if (newModules.length > newModuleCount) {
      newModules.splice(newModuleCount);
    }
  } else {
    newModules.splice(1);
  }
  return newModules;
};

export const recalculateSubmodules = (
  modules: (string | string[])[],
  vertCrossbars: number | number[],
  crossCut: number
) => {
  let newModules = modules;
  if (
    vertCrossbars &&
    (typeof vertCrossbars === "number" || typeof vertCrossbars === "object")
  ) {
    const receivedCount =
      typeof vertCrossbars === "number"
        ? vertCrossbars + 1
        : vertCrossbars.length + 1;
    const newModuleCount = Math.min(
      Math.max(receivedCount, MIN_MODULES),
      MAX_MODULES
    );
    newModules = modules.map((module, index) => {
      if (Array.isArray(module)) {
        const isModuleCut =
          crossCut === 0 ||
          (crossCut > 0 && index >= modules.length - crossCut) ||
          (crossCut < 0 && index < Math.abs(crossCut));
        if (isModuleCut) {
          if (module.length < newModuleCount) {
            const extraCount = newModuleCount - module.length;
            const extraModules = Array(extraCount).fill("", 0);
            return module.concat(extraModules);
          } else if (module.length > newModuleCount) {
            return module.splice(newModuleCount);
          } else return module;
        } else return module[0];
      } else {
        return module;
      }
    });
  } else {
    newModules = modules.map((module) => {
      if (Array.isArray(module)) {
        return module[0];
      } else {
        return module;
      }
    });
  }
  return newModules;
};

export const normalizeHorizontalCrossbars = (
  cross: number | number[],
  height: number
) => {
  let crossH: number[] = [];
  if (typeof cross === "number") {
    const horizCount = cross;
    const distance = height / (horizCount + 1);
    crossH = [];
    for (let ho = 1; ho <= horizCount; ho++) {
      crossH.push(distance * ho);
    }
  } else {
    crossH = cross;
  }
  return crossH;
};

export const normalizeVerticalCrossbars = (
  cross: number | number[],
  width: number,
  panels: number
) => {
  let crossV: number[] = [];
  if (typeof cross === "number") {
    const vertCount = cross;
    const distance = width / panels / (vertCount + 1);
    crossV = [];
    for (let ve = 1; ve <= vertCount; ve++) {
      crossV.push(distance * ve);
    }
  } else {
    crossV = cross;
  }
  return crossV;
};
