import { useState } from "react";
import { Group, Text, SimpleGrid, Container, Button } from "@mantine/core";
import { BiSave } from "react-icons/bi";
import { CostSections } from "../../utils/globalConstants";
import PercentageInput from "../common/PercentageInput";
import PaperConfigSection from "./PaperConfigSection";

type ConfigGainsProps = {
  isLoading: boolean;
  gains: CostSections;
  intl: boolean;
  onSubmit: (gains: CostSections) => void;
};

const ConfigGains = (props: ConfigGainsProps) => {
  const [gains, setGains] = useState<CostSections>(
    props.gains ?? new CostSections()
  );

  const submitHandler = () => {
    props.onSubmit(gains);
  };

  return (
    <PaperConfigSection
      title="Ganancias"
      description="Modifique los porcentajes de ganancia por rubro."
    >
      <Container sx={{ maxWidth: "750px" }}>
        <SimpleGrid cols={5} spacing="xl" verticalSpacing="md">
          <PercentageInput
            label="Aluminio"
            value={gains.alu}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, alu: value };
              });
            }}
          />
          <PercentageInput
            label="PVC"
            value={gains.pvc}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, pvc: value };
              });
            }}
          />
          <PercentageInput
            label="Accesorios"
            value={gains.acc}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, acc: value };
              });
            }}
          />
          <PercentageInput
            label="Vidrios"
            value={gains.vid}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, vid: value };
              });
            }}
          />
          <PercentageInput
            label="Policarbonatos"
            value={gains.pol}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, pol: value };
              });
            }}
          />
          <PercentageInput
            label="Paneles"
            value={gains.pnl}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, pnl: value };
              });
            }}
          />
          {!props.intl && (
            <PercentageInput
              label="Pinturas"
              value={gains.pin}
              onChange={(value: number) => {
                setGains((prev) => {
                  return { ...prev, pin: value };
                });
              }}
            />
          )}
          {!props.intl && (
            <PercentageInput
              label="Anodizados"
              value={gains.ano}
              onChange={(value: number) => {
                setGains((prev) => {
                  return { ...prev, ano: value };
                });
              }}
            />
          )}
          <PercentageInput
            label="Tejido"
            value={gains.tej}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, tej: value };
              });
            }}
          />
          <PercentageInput
            label="Compactos"
            value={gains.com}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, com: value };
              });
            }}
          />
          <PercentageInput
            label="Mano de obra"
            value={gains.lab}
            onChange={(value: number) => {
              setGains((prev) => {
                return { ...prev, lab: value };
              });
            }}
          />
        </SimpleGrid>
      </Container>
      <Group mt="xl" position="right">
        <Button
          loading={props.isLoading}
          onClick={submitHandler}
          sx={{ width: "12em" }}
        >
          <BiSave size="20" />
          <Text ml="6px">Guardar</Text>
        </Button>
      </Group>
    </PaperConfigSection>
  );
};

export default ConfigGains;
