import { Button, Modal, Stack, Text } from "@mantine/core";

type HelpIntroProps = {
  opened: boolean;
  onClose: () => void;
};

const HelpIntroModal = (props: HelpIntroProps) => {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="xl"
      padding={32}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <Stack align="center">
        <Text size="xl" weight="bold">
          ¡Bienvenido/a a AluDig!
        </Text>
        <Text align="justify">
          Lo hemos llevado a la página de <strong>Ayuda</strong>, donde podrá
          ver tutoriales que le enseñarán a utilizar nuestro sitio. Podrá volver
          a esta página cuando desee mediante el menú ubicado en la esquina
          superior derecha.
        </Text>
        <Text align="justify">
          Cuando haya visto los tutoriales le recomendamos visitar la sección de{" "}
          <strong>Configuración</strong> para modificar la aplicación a su
          gusto, especialmente los costos del aluminio y otros productos.
        </Text>
        <Text align="justify">
          Luego podrá revisar los <strong>Diseños</strong> y hacer cualquier
          cambio que crea necesario en las aberturas de muestra que incluimos
          para usted, además de crear sus propios diseños.
        </Text>
        <Text align="justify">
          Finalmente, en <strong>Presupuesto</strong> podrá usar nuestra
          calculadora automática para cotizar sus proyectos y generar PDFs
          imprimibles para usted y sus clientes.
        </Text>
        <Text align="justify">¡Gracias por confiar en nosotros!</Text>
        <Button mt="md" onClick={props.onClose}>Cerrar</Button>
      </Stack>
    </Modal>
  );
};

export default HelpIntroModal;
