import { Route, Routes } from "react-router-dom";
import { BiBrush } from "react-icons/bi";

import MyDesigns from "../components/Design/MyDesigns";
import NewDesign, { DesignEditType } from "../components/Design/NewDesign";
import { Group, Title } from "@mantine/core";
import PageWrapper from "../components/common/PageWrapper";

const DesignPage = () => {
  const paths = {
    mine: "",
    new: "new",
    edit: "edit",
    createFromUser: "from",
    createFromTemplate: "template",
  };

  return (
    <PageWrapper>
      <Group my="lg" spacing={8}>
        <BiBrush size={36} />
        <Title order={1}>Diseño</Title>
      </Group>

      <Routes>
        <Route path={paths.mine} element={<MyDesigns />} />
        <Route
          path={paths.new}
          element={<NewDesign editType={DesignEditType.NewDesign} />}
        />
        <Route
          path={paths.edit + "/:designId"}
          element={
            <NewDesign
              editType={
                DesignEditType.EditOwnDesign
              } /*overwrite={true} template={false}*/
            />
          }
        />
        <Route
          path={paths.createFromUser + "/:designId"}
          element={
            <NewDesign
              editType={
                DesignEditType.BaseOnOwnDesign
              } /*overwrite={false} template={false}*/
            />
          }
        />
        <Route
          path={paths.createFromTemplate + "/:designId"}
          element={
            <NewDesign
              editType={
                DesignEditType.BaseOnTemplate
              } /*overwrite={false} template={true}*/
            />
          }
        />
      </Routes>
    </PageWrapper>
  );
};

export default DesignPage;
