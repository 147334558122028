const UTF_FIRST_CODE = 65;
const UTF_LAST_CODE = 90;

export const getArticleCodeFromIndex = (index: number) => {
  let code = "";
  let codeIndex = index + UTF_FIRST_CODE;
  if (codeIndex > UTF_LAST_CODE) {
    const prefix = index / (UTF_LAST_CODE - UTF_FIRST_CODE);
    code += String.fromCharCode(prefix - 1 + UTF_FIRST_CODE);
    codeIndex = (index % (UTF_LAST_CODE - UTF_FIRST_CODE)) - 1 + UTF_FIRST_CODE;
  }
  code += String.fromCharCode(codeIndex);

  return code;
};
