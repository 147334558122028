import {
  Button,
  Center,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import DesignFilter, { DesignFilterValues } from "../../UI/DesignFilter";
import BrandLines from "../../../models/brand-lines";
import UnifiedGridLayout, { UnifiedGridItem } from "./UnifiedGridLayout";
import { QuotePartLayout } from "../../../utils/globalConstants";

type UnifiedPlanModalProps = {
  opened: boolean;
  onConfirm: (unifiedPlan: QuotePartLayout[]) => void;
  onClose: () => void;
  brands: BrandLines[];
  designs: {
    value: string;
    label: string;
    brand: string;
    line: string;
    type: string;
  }[];
};

const DEFAULT_SCALE = 0.1;
const LIMIT_LENGTH = 1000;

const UnifiedPlanModal = (props: UnifiedPlanModalProps) => {
  const [nextId, setNextId] = useState<number>(2);
  const [items, setItems] = useState<UnifiedGridItem[]>([]);
  const [drawScale, setDrawScale] = useState(DEFAULT_SCALE);
  const [actualWidth, setActualWidth] = useState(500);
  const [actualHeight, setActualHeight] = useState(600);

  const [lineLocked, setLineLocked] = useState(false);
  const [filter, setFilter] = useState<DesignFilterValues>({
    brand: "",
    line: "",
    type: "",
  });
  const changeFilter = (values: DesignFilterValues) => {
    setFilter((prev) => {
      return { ...prev, ...values };
    });
  };
  const clearFilter = () => {
    if (lineLocked) {
      setFilter((prev) => {
        return { ...prev, type: "" };
      });
    } else {
      setFilter({
        brand: "",
        line: "",
        type: "",
      });
    }
  };

  const addItemHandler = (
    designId: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    const design = props.designs.find((d) => d.value === designId);
    if (!design) return;

    // Add new item
    // If new item has negative X or Y, move all items so coords always start at (0, 0)
    setItems((prev) => {
      return prev
        .map((item) => {
          return {
            ...item,
            x: x < 0 ? item.x + Math.abs(x) : item.x,
            y: y < 0 ? item.y + Math.abs(y) : item.y,
          };
        })
        .concat({
          id: nextId.toString(),
          x: Math.max(x, 0),
          y: Math.max(y, 0),
          w: width,
          h: height,
          designId,
          text: design.label,
        });
    });
    setNextId((prev) => prev + 1);
    if (!lineLocked) {
      setLineLocked(true);
      setFilter({ brand: design.brand, line: design.line, type: "" });
    }
  };

  const removeItemHandler = (id: string) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  };

  const confirmHandler = () => {
    if (items.length === 0) return;
    const orderedItems = items.sort((a, b) => a.y - b.y || a.x - b.x);
    props.onConfirm(orderedItems);
    closeHandler();
  };

  const closeHandler = () => {
    props.onClose();
    setItems([]);
    setFilter({
      brand: "",
      line: "",
      type: "",
    });
    setLineLocked(false);
  };

  useEffect(() => {
    let totalWidth = 100;
    let totalHeight = 100;
    items.forEach((item) => {
      const xw = item.x + item.w;
      const yh = item.y + item.h;
      if (xw > totalWidth) totalWidth = xw;
      if (yh > totalHeight) totalHeight = yh;
    });

    const newScale = Math.max(
      (LIMIT_LENGTH * DEFAULT_SCALE) / Math.max(totalWidth, totalHeight),
      DEFAULT_SCALE
    );
    setDrawScale(newScale);
    setActualWidth(newScale * totalWidth);
    setActualHeight(newScale * totalHeight);
  }, [items]);

  const filteredDesigns = props.designs.filter(
    (d) =>
      (filter.brand ? d.brand === filter.brand : true) &&
      (filter.line ? d.line === filter.line : true) &&
      (filter.type ? d.type === filter.type : true)
  );

  return (
    <Modal
      opened={props.opened}
      onClose={closeHandler}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={true}
      size="auto"
      centered
    >
      <Stack p="md" sx={{ minHeight: "560px" }}>
        <Title order={3}>Añadir abertura unificada</Title>
        <Text>
          {`Defina la estructura general de esta abertura unificada. Puede clickear una abertura para añadir otras a su alrededor. Una vez completado este paso inicial podrá definir los detalles de cada abertura individual.`}
        </Text>

        <Center>
          <DesignFilter
            filter={filter}
            brands={props.brands}
            onChange={changeFilter}
            onClear={clearFilter}
            disableBrandLine={lineLocked}
            filledBg={true}
          />
        </Center>

        <Center
          p={50}
          sx={{
            flexGrow: 1,
            flexDirection: "column",
            display: "flex",
            minWidth: `${actualWidth}px`,
            minHeight: `${actualHeight}px`,
          }}
        >
          <UnifiedGridLayout
            items={items}
            designs={filteredDesigns}
            drawScale={drawScale}
            onItemAdd={addItemHandler}
            onItemRemove={removeItemHandler}
          />
        </Center>

        <Group position="center">
          <Button onClick={confirmHandler} disabled={items.length < 2}>
            Continuar
          </Button>
          <Button variant="outline" onClick={closeHandler}>
            Cancelar
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default UnifiedPlanModal;
