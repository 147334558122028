import { useEffect, useState } from "react";
import { Button, Group, Modal, NumberInput, Stack, Text } from "@mantine/core";
import CodeInput from "../common/CodeInput";

type QuoteRenameModalProps = {
  opened: boolean;
  onConfirm: (
    id: number,
    newOrder: number,
    newCode: string,
    compact: boolean
  ) => void;
  onClose: () => void;
  maxOrder: number;
  data: {
    id: number;
    order: number;
    current: string;
    compact: boolean;
  };
};

const QuoteRenameModal = (props: QuoteRenameModalProps) => {
  const [code, setCode] = useState<string>();
  const [order, setOrder] = useState<number>();

  const codeChangeHandler = (value: string) => {
    const newCode = value.toUpperCase();
    setCode(newCode);
  };

  const confirmClickHandler = () => {
    const clampedOrder = Math.min(Math.max(order, 1), props.maxOrder);
    props.onConfirm(props.data.id, clampedOrder, code, props.data.compact);
  };

  const cancelClickHandler = () => {
    props.onClose();
  };

  useEffect(() => {
    setOrder(props.data?.order || 1);
    setCode(props.data?.current || "");
  }, [props.data]);

  return (
    <Modal
      centered
      size={500}
      padding="lg"
      withCloseButton={false}
      opened={props.opened}
      onClose={props.onClose}
    >
      <Stack>
        <Text weight="bold">
          Modificar artículo "{props.data?.current || ""}"
        </Text>
        <Stack>
          <Group position="apart" noWrap>
            <Stack spacing={0}>
              <Text>Código</Text>
              <Text size="sm" color="dimmed">
                Máximo 6 caracteres. Sólo mayúsculas, números, guiones o barras.
              </Text>
            </Stack>
            <CodeInput
              sx={{ maxWidth: "120px" }}
              maxLength={6}
              value={code}
              onChange={codeChangeHandler}
            />
          </Group>
          {props.data && !props.data.compact && (
            <Group position="apart" noWrap>
              <Stack spacing={0}>
                <Text>Orden</Text>
                <Text size="sm" color="dimmed">
                  Posición en el presupuesto respecto a otros artículos.
                </Text>
              </Stack>
              <NumberInput
                sx={{ maxWidth: "95px" }}
                min={1}
                max={props.maxOrder}
                value={order}
                onChange={(value: number) => setOrder(value)}
              />
            </Group>
          )}
        </Stack>
        <Group mt="sm" position="right">
          <Button disabled={!order || !code} onClick={confirmClickHandler}>
            Confirmar
          </Button>
          <Button variant="outline" onClick={cancelClickHandler}>
            Cancelar
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default QuoteRenameModal;
