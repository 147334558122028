import { Button, Group, NumberInput, Popover, Stack } from "@mantine/core";
import { FloatingPosition } from "@mantine/core/lib/Floating";
import { useState } from "react";
import { BiPlus } from "react-icons/bi";
import { MIN_MEASURE } from "../../../utils/globalConstants";
import SelectDesign from "../../UI/SelectDesign";

export type UnifiedBlockPosition = "none" | "left" | "right" | "top" | "bottom";
export type UnifiedBlockSide = "none" | "horizontal" | "vertical";

type UnifiedAddButtonProps = {
  designs: {
    value: string;
    label: string;
    brand: string;
    line: string;
    type: string;
  }[];
  position: UnifiedBlockPosition;
  side: UnifiedBlockSide;
  maxMargin?: number;
  onAdd?: (
    designId: string,
    width: number,
    height: number,
    position: UnifiedBlockPosition,
    margin: number,
  ) => void;
  onAddFirst?: (designId: string, width: number, height: number) => void;
  label?: React.ReactNode;
  popoverPosition?: FloatingPosition;
  style?: React.CSSProperties;
};

const DESIGN_DROPDOWN_COUNT = 50;

const UnifiedAddButton = (props: UnifiedAddButtonProps) => {
  const [pop, setPop] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [width, setWidth] = useState(1000);
  const [height, setHeight] = useState(1000);
  const [margin, setMargin] = useState(0);

  const openSelectorHandler = () => {
    setPop(true);
  };

  const addHandler = () => {
    props.onAdd(selected, width, height, props.position, margin);
    closeHandler();
  };

  const addFirstHandler = () => {
    props.onAddFirst(selected, width, height);
    closeHandler();
  };

  const closeHandler = () => {
    setPop(false);
    setSelected("");
  };

  return (
    <Popover
      opened={pop}
      onClose={closeHandler}
      position={props.popoverPosition || "top"}
      shadow="md"
      closeOnClickOutside={true}
      withArrow
    >
      <Popover.Target>
        <Button
          px={8}
          onClick={openSelectorHandler}
          sx={{ width: "fit-content" }}
          style={props.style}
        >
          {props.label || <BiPlus size={20} />}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack p={4} spacing="xs">
          <SelectDesign
            label="Diseño"
            size="sm"
            data={props.designs}
            value={selected}
            limit={DESIGN_DROPDOWN_COUNT}
            onChange={(value) => setSelected(value)}
            sx={{ width: "32em" }}
          />
          <Group align="flex-end">
            <NumberInput
              sx={{ width: "6em" }}
              size="sm"
              label="Ancho"
              min={MIN_MEASURE}
              max={6000}
              value={width}
              onChange={(value: number) => setWidth(value)}
            />
            <NumberInput
              sx={{ width: "6em" }}
              size="sm"
              label="Alto"
              min={MIN_MEASURE}
              max={6000}
              value={height}
              onChange={(value: number) => setHeight(value)}
            />
            {props.side !== "none" && (
              <NumberInput
                sx={{ width: "8em" }}
                size="sm"
                label={`Margen ${
                  props.side === "horizontal" ? "superior" : "izquierdo"
                }`}
                min={0}
                max={props.maxMargin}
                value={margin}
                onChange={(value: number) => setMargin(value)}
              />
            )}
            <Button
              sx={{ width: "10em" }}
              disabled={!selected || !width || !height}
              onClick={props.position === "none" ? addFirstHandler : addHandler}
            >
              Añadir
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UnifiedAddButton;
