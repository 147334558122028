import { Button, Group, Menu, Text } from "@mantine/core";
import {
  BiCaretDown,
  BiCaretUp,
  BiExit,
  BiHelpCircle,
  BiUser,
  BiUserCircle,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import { IUserContext } from "../../store/user-context";
import styles from "./UserMenu.module.css";
import { useState } from "react";

type UserMenuProps = {
  context: IUserContext;
  paths: any;
};

const UserMenu = (props: UserMenuProps) => {
  const { context: userCtx } = props;

  const [opened, setOpened] = useState(false);

  return (
    <Menu
      position="bottom-end"
      transitionProps={{ transition: "fade" }}
      withArrow
      arrowPosition="center"
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Button variant="light" color="brand">
          <Group align="center" spacing={6} noWrap>
            <BiUserCircle size={20} />
            <Text size="md">{`${userCtx.data.firstName} ${
              !userCtx.isGuest ? userCtx.data.lastName : ""
            }`}</Text>
            {opened ? <BiCaretUp size={20} /> : <BiCaretDown size={20} />}
          </Group>
        </Button>
      </Menu.Target>
      <Menu.Dropdown className={styles.headerMenuDropdown}>
        {userCtx.data.terms && (
          <Menu.Item
            icon={<BiUser size={20} />}
            component={Link}
            to={props.paths.account}
          >
            <Text size="md">Mi cuenta</Text>
          </Menu.Item>
        )}
        {userCtx.data.terms && (
          <Menu.Item
            icon={<BiHelpCircle size={20} />}
            component={Link}
            to={props.paths.help}
          >
            <Text size="md">Ayuda</Text>
          </Menu.Item>
        )}
        <Menu.Item icon={<BiExit size={20} />} onClick={userCtx.onLogout}>
          <Text size="md">Cerrar sesión</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserMenu;
