import {
  Box,
  Button,
  Center,
  Group,
  Loader,
  Pagination,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { ReactNode, useState } from "react";
import { BiCopy, BiPencil, BiTrash } from "react-icons/bi";
import BrandLines from "../../models/brand-lines";
import Design from "../../models/design";
import { OPENINGS } from "../../utils/globalConstants";
import DesignFilter, { DesignFilterValues } from "../UI/DesignFilter";
import styles from "./DesignListTable.module.css";

const COLUMN_COUNT = 5;
const columnWidths = ["30em", "6em", "8em", "12em", "6em"];
const PER_PAGE = 10;

type DesignListTableProps = {
  title: string;
  loading: boolean;
  data: Design[];
  brandData: BrandLines[];
  templateTable?: boolean;
  titleIcon?: ReactNode;
  allowCreation?: boolean;
  onEdit?: (id: string) => void;
  onRemove?: (id: string) => void;
  onBase?: (id: string, template: boolean) => void;
};

const DesignListTable = (props: DesignListTableProps) => {
  const { templateTable = false } = props;

  const [page, setPage] = useState(1);

  const [filter, setFilter] = useState<DesignFilterValues>({
    brand: "",
    line: "",
    type: "",
  });
  const changeFilter = (values: DesignFilterValues) => {
    setPage(1);
    setFilter((prev) => {
      return { ...prev, ...values };
    });
  };
  const clearFilter = () => {
    setPage(1);
    setFilter({
      brand: "",
      line: "",
      type: "",
    });
  };

  const editDesignHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const editId = e.currentTarget.value;
    props.onEdit(editId);
  };
  const removeDesignHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const removeId = e.currentTarget.value;
    props.onRemove(removeId);
  };
  const baseNewDesignHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const designId = e.currentTarget.value;
    props.onBase(designId, props.templateTable);
  };

  const filteredDesigns = props.data
    ? props.data.filter(
        (d: Design) =>
          (filter.brand ? d.brand === filter.brand : true) &&
          (filter.line ? d.line === filter.line : true) &&
          (filter.type ? d.open === filter.type : true)
      )
    : [];

  const designCount = filteredDesigns ? filteredDesigns.length : 0;
  const pageCount = Math.ceil(designCount / PER_PAGE);
  const firstIndexInPage = (page - 1) * PER_PAGE;
  const paginatedDesigns = filteredDesigns.slice(
    firstIndexInPage,
    firstIndexInPage + PER_PAGE
  );

  let designListContent;
  if (filteredDesigns.length === 0) {
    designListContent = (
      <tr>
        <td colSpan={COLUMN_COUNT}>No se han encontrado diseños.</td>
      </tr>
    );
  } else {
    designListContent = paginatedDesigns.map((design) => {
      const designBrand = props.brandData.find((b) => b.code === design.brand);
      const designLine = designBrand
        ? designBrand.lines.find((l) => l.code === design.line)
        : null;
      return (
        <tr key={design._id}>
          <td className={styles.firstColumn}>{design.name}</td>
          <td style={{ minWidth: columnWidths[1] }}>
            {designBrand ? designBrand.name : <Text color="red">Error</Text>}
          </td>
          <td style={{ minWidth: columnWidths[2] }}>
            {designLine ? designLine.name : <Text color="red">Error</Text>}
          </td>
          <td style={{ minWidth: columnWidths[3] }}>
            {OPENINGS.find((op) => op.code === design.open)?.name ?? ""}
          </td>
          <td>
            {/* <Button variant="subtle" value={design._id}>
            <BiShowAlt />
            Ver
          </Button>
          <span style={{ userSelect: "none" }}> · </span> */}
            {!templateTable ? (
              <Group position="center" spacing={0} noWrap>
                {designBrand && designLine && (
                  <>
                    <Button
                      p="xs"
                      variant="subtle"
                      value={design._id}
                      onClick={editDesignHandler}
                      disabled={!designBrand || !designLine}
                    >
                      <BiPencil />
                      <Text ml={3}>Modificar</Text>
                    </Button>
                    <span style={{ userSelect: "none" }}>·</span>
                  </>
                )}
                <Button
                  p="xs"
                  variant="subtle"
                  value={design._id}
                  onClick={baseNewDesignHandler}
                  disabled={!props.allowCreation}
                >
                  <BiCopy />
                  <Text ml={3}>Copiar</Text>
                </Button>
                <span style={{ userSelect: "none" }}>·</span>
                <Button
                  p="xs"
                  variant="subtle"
                  value={design._id}
                  onClick={removeDesignHandler}
                >
                  <BiTrash />
                  <Text ml={3}>Borrar</Text>
                </Button>
              </Group>
            ) : (
              <div>
                {designBrand && designLine && (
                  <Button
                    p="xs"
                    variant="subtle"
                    value={design._id}
                    onClick={baseNewDesignHandler}
                    disabled={!props.allowCreation}
                  >
                    <BiCopy />
                    <Text ml={3}>Usar como base</Text>
                  </Button>
                )}
              </div>
            )}
          </td>
        </tr>
      );
    });
  }

  return (
    <Box
      p="md"
      sx={(theme) => ({
        backgroundColor: !templateTable
          ? theme.colors.brand[2]
          : theme.colors.brand[0],
        width: "fit-content",
        borderRadius: "4px",
      })}
    >
      <Group mb="sm" spacing={8}>
        {props.titleIcon}
        <Title order={2}>{props.title}</Title>
      </Group>
      {props.loading ? (
        <Group my={40} position="center">
          <Loader size="xl" variant="dots" />
        </Group>
      ) : (
        <div style={{ width: "fit-content" }}>
          <Group position="left" align="flex-end" spacing={0}>
            <Box sx={{ width: "720px" }}>
              <DesignFilter
                brands={props.brandData}
                filter={filter}
                onChange={changeFilter}
                onClear={clearFilter}
                marginBottom={0}
                leftIcon={false}
                filledBg={false}
              />
            </Box>
            <Text size="lg" mb="xs">{`${filteredDesigns.length} ${
              filteredDesigns.length === 1
                ? `${
                    templateTable ? "plantilla encontrada" : "diseño encontrado"
                  }`
                : `${
                    templateTable
                      ? "plantillas encontradas"
                      : "diseños encontrados"
                  }`
            }`}</Text>
          </Group>
          <div style={{ width: "fit-content" }}>
            <Box p="lg" sx={{ backgroundColor: "white", width: "fit-content" }}>
              <Table striped verticalSpacing={3} sx={{ width: "max-content" }}>
                <thead>
                  <tr>
                    <th className={styles.firstColumn}>Nombre</th>
                    <th style={{ minWidth: columnWidths[1] }}>Marca</th>
                    <th style={{ minWidth: columnWidths[2] }}>Línea</th>
                    <th style={{ minWidth: columnWidths[3] }}>Tipo</th>
                    <th className={styles.actionsColumn}>Acciones</th>
                  </tr>
                </thead>
                <tbody>{designListContent}</tbody>
              </Table>
              <Center>
                {pageCount > 1 && (
                  <Pagination
                    mt="sm"
                    value={page}
                    total={pageCount}
                    onChange={(page: number) => setPage(page)}
                  />
                )}
              </Center>
            </Box>
          </div>
        </div>
      )}
    </Box>
  );
};

export default DesignListTable;
