import {
  Button,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { BiDollar, BiEdit } from "react-icons/bi";
import Product from "../../models/product";
import { formatCurrencyDisplay } from "../../utils/amountFormat";
import { AiOutlinePercentage } from "react-icons/ai";

type CustomBarsTableProps = {
  products: Product[];
  colors: Product[];
  pvc: boolean;
  mt?: number;
  showPercentageButton?: boolean;
  currentBrandName?: string;
  onCustomSave: (id: string, data: any) => Promise<Product>;
  onPercentageApply?: (percentage: number) => Promise<boolean>;
};

const FIRST_COL_WIDTH = 90;
const COL_WIDTH = 110;

const MIN_PERCENTAGE = -100;
const MAX_PERCENTAGE = 100;

const CustomBarsTable = (props: CustomBarsTableProps) => {
  const [colorColumns, setColorColumns] = useState<Product[]>([]);
  const [tableWidth, setTableWidth] = useState(950);

  const [selectedProd, setSelectedProd] = useState<Product>(null);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalPercentageOpen, setModalPercentageOpen] =
    useState<boolean>(false);

  const [prices, setPrices] = useState<number[]>([]);
  const [editPercentage, setEditPercentage] = useState<number>(undefined);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.colors && props.colors.length > 0) {
      const filteredColors = props.colors
        .filter((c) => (props.pvc ? c.spc === "pvc" : !c.spc))
        .sort((a, b) => a.ord - b.ord);
      const colCount = filteredColors.length + +!props.pvc;
      setColorColumns(filteredColors);
      setTableWidth(FIRST_COL_WIDTH + colCount * COL_WIDTH);
    }
  }, [props.colors, props.pvc]);

  useEffect(() => {
    setSelectedProd(null);
  }, [props.products]);

  const rowClickHandler = (prod: Product) => {
    setSelectedProd(prod);
    // setEditCode(prod.code);
    // setEditName(prod.name);
    // setEditPrice(prod.pri);
  };

  const percentageButtonHandler = () => {
    setError(false);
    setEditPercentage(undefined);
    setModalPercentageOpen(true);
  };
  const editButtonHandler = () => {
    setError(false);
    const pricesArray = selectedProd.prices ?? [];
    const missingIndices = props.colors.length - pricesArray.length;
    const extendedArray = pricesArray.concat(new Array(missingIndices).fill(0));
    setPrices(extendedArray);
    setModalEditOpen(true);
  };

  const onPriceChange = (value: number, index: number) => {
    setPrices((prev) =>
      prev.map((p, i) => {
        if (i === index) return value;
        else return p;
      })
    );
  };

  const saveButtonHandler = async () => {
    setSaving(true);
    const saveResponse = await props.onCustomSave(selectedProd._id, prices);
    if (saveResponse) {
      setModalEditOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  const applyPercentageHandler = async () => {
    setSaving(true);
    const saveResponse = await props.onPercentageApply(editPercentage);
    if (saveResponse) {
      setModalPercentageOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  return (
    <Stack>
      <Modal
        title="Editar costos de barras"
        size="54em"
        padding="xl"
        opened={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={true}
      >
        <Stack>
          {selectedProd && (
            <Text fw="bold">{`[${selectedProd.code}] ${selectedProd.name}`}</Text>
          )}
          <SimpleGrid cols={5} spacing="xl" verticalSpacing="md">
            {!props.pvc && (
              <NumberInput
                key={0}
                size="sm"
                label="S/ pintar"
                min={0}
                max={999999}
                icon={<BiDollar size="20" />}
                value={prices ? prices[0] ?? 0 : 0}
                onChange={(value) => onPriceChange(value || 0, 0)}
              />
            )}
            {colorColumns.map((col, i) => (
              <NumberInput
                key={col.ord}
                size="sm"
                label={col.code}
                min={0}
                max={999999}
                icon={<BiDollar size="20" />}
                value={prices ? prices[i + +!props.pvc] ?? 0 : 0}
                onChange={(value) => onPriceChange(value || 0, i + +!props.pvc)}
              />
            ))}
          </SimpleGrid>
          <Group mt={16} position="right">
            <Button
              sx={{ width: "10em" }}
              onClick={saveButtonHandler}
              loading={saving}
            >
              Guardar
            </Button>
            <Button
              sx={{ width: "8em" }}
              variant="outline"
              onClick={() => setModalEditOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <Modal
        size="lg"
        padding="xl"
        opened={modalPercentageOpen}
        onClose={() => setModalPercentageOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Stack align="center">
          <Text>
            Ingrese un porcentaje para modificar en conjunto el costo de{" "}
            <Text span inherit fw="bold">
              todos los productos de la marca actual.
            </Text>{" "}
            Puede ingresar un porcentaje negativo.
          </Text>
          {props.currentBrandName && (
            <Text my={0}>
              Marca: <strong>{props.currentBrandName}</strong>
            </Text>
          )}
          <NumberInput
            sx={{ width: "7em" }}
            step={1}
            min={MIN_PERCENTAGE}
            max={MAX_PERCENTAGE}
            rightSection={<AiOutlinePercentage color="gray" />}
            value={editPercentage}
            onChange={(value: number) => setEditPercentage(value)}
            disabled={saving}
          />
          <Group mt="sm">
            <Button
              sx={{ width: "10em" }}
              loading={saving}
              disabled={
                !editPercentage ||
                editPercentage < MIN_PERCENTAGE ||
                editPercentage > MAX_PERCENTAGE ||
                editPercentage === 0
              }
              onClick={applyPercentageHandler}
            >
              Confirmar
            </Button>
            <Button
              sx={{ width: "10em" }}
              variant="outline"
              onClick={() => setModalPercentageOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <ScrollArea
        mt={props.mt}
        w={950}
        h={400}
        style={{ border: "1px solid darkgray" }}
      >
        <Table
          w={tableWidth}
          color="brand"
          highlightOnHover
          sx={{ userSelect: "none" }}
        >
          <thead
            style={{
              position: "sticky",
              top: "0px",
              backgroundColor: "var(--secondary)",
            }}
          >
            <tr>
              <th style={{ width: `${FIRST_COL_WIDTH}px` }}></th>
              {!props.pvc && (
                <th style={{ width: `${COL_WIDTH}px` }}>S/ pintar</th>
              )}
              {colorColumns.map((col) => (
                <th key={col._id} style={{ width: `${COL_WIDTH}px` }}>
                  {col.code}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.products.map((prod) => (
              <tr
                key={prod._id}
                onClick={() => rowClickHandler(prod)}
                style={{
                  backgroundColor:
                    selectedProd && prod._id === selectedProd._id
                      ? "lightblue"
                      : prod.pri === 0
                      ? "lightgray"
                      : "white",
                }}
              >
                <td>{prod.code}</td>
                {[...Array(colorColumns.length + +!props.pvc)].map((e, i) => (
                  <td key={i}>
                    {prod.prices && prod.prices[i]
                      ? formatCurrencyDisplay(prod.prices[i], false)
                      : "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>

      <Group position="right" sx={{ flexGrow: 1 }}>
        {props.showPercentageButton && (
          <Button variant="outline" onClick={percentageButtonHandler}>
            Modificar costos con porcentaje
          </Button>
        )}
        <Button
          sx={{ width: "10em" }}
          onClick={editButtonHandler}
          disabled={!selectedProd}
        >
          <BiEdit size={20} />
          <Text size="sm" ml={4}>
            Editar
          </Text>
        </Button>
      </Group>
    </Stack>
  );
};

export default CustomBarsTable;
