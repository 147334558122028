import { Group, Title } from "@mantine/core";
import PageWrapper from "../components/common/PageWrapper";
import { BiCalculator } from "react-icons/bi";
import { Route, Routes } from "react-router-dom";
import NewQuote from "../components/Quote/NewQuote";

const paths = {
  new: "",
  edit: "edit",
};

const QuotePage = () => {
  return (
    <PageWrapper>
      <Group mt="lg" spacing={8}>
        <BiCalculator size={36} />
        <Title order={1}>Presupuesto</Title>
      </Group>

      <Routes>
        <Route path={paths.new} element={<NewQuote />} />
        <Route path={paths.edit + "/:quoteId"} element={<NewQuote />} />
      </Routes>
    </PageWrapper>
  );
};

export default QuotePage;
