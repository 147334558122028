import { Group, Text, Title } from "@mantine/core";
import { BiWrench } from "react-icons/bi";
import PageWrapper from "../components/common/PageWrapper";

const MaintenancePage = () => {
  return (
    <PageWrapper>
      <Group my="lg" spacing={8}>
        <BiWrench size={36} />
        <Title order={1}>Mantenimiento</Title>
      </Group>

      <Text mb="md">
        El sitio web de AluDig se encuentra temporalmente en mantenimiento.
      </Text>
      <Text>Por favor regrese más tarde.</Text>
    </PageWrapper>
  );
};

export default MaintenancePage;
