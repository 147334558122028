import { Center, Container, Paper, Table } from "@mantine/core";
import Item from "../../models/item";
import { formatCurrencyDisplay } from "../../utils/amountFormat";
import RemoveButton from "../UI/RemoveButton";

type QuoteBonusTableProps = {
  items: Item[];
  onItemRemove: (itemId: number) => void;
  usd?: boolean;
};

const COLUMN_WIDTHS = ["24em", "4em", "5em", "3em"];

const QuoteBonusTable = (props: QuoteBonusTableProps) => {
  const removeClickHandler = (val: number) => {
    props.onItemRemove(val);
  };

  const extraContent = (
    <tbody>
      {props.items.map((item) => (
        <tr key={item.itemId}>
          <td style={{ minWidth: COLUMN_WIDTHS[0] }}>{item.name}</td>
          <td style={{ minWidth: COLUMN_WIDTHS[1] }}>
            {item.count ? item.count : ""}
          </td>
          <td style={{ minWidth: COLUMN_WIDTHS[2] }}>
            {formatCurrencyDisplay(item.cost, props.usd ?? false)}
          </td>
          <td style={{ minWidth: COLUMN_WIDTHS[3] }}>
            <Center>
              <RemoveButton value={item.itemId} onClick={removeClickHandler} />
            </Center>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <Paper sx={{ maxWidth: "1300px" }} p="xl" shadow="sm">
      <Container sx={{ width: "fit-content" }} mb="md">
        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: COLUMN_WIDTHS[0] }}>Descripción</th>
              <th style={{ minWidth: COLUMN_WIDTHS[1] }}>Cant.</th>
              <th style={{ minWidth: COLUMN_WIDTHS[2] }}>Costo</th>
              <th style={{ minWidth: COLUMN_WIDTHS[3] }}>Quitar</th>
            </tr>
          </thead>
          {extraContent}
        </Table>
      </Container>
    </Paper>
  );
};

export default QuoteBonusTable;
