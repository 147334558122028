import {
  Button,
  Group,
  Paper,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { ChangeEvent, useState } from "react";
import {
  BiCalendarEvent,
  BiEdit,
  BiEnvelope,
  BiHome,
  BiPhone,
  BiSave,
  BiUser,
} from "react-icons/bi";
import UserData from "../../models/user-data";
import PhoneInput from "../common/PhoneInput";
import styles from "./Account.module.css";

const ICON_SIZE = 18;

type AccountUserInfoProps = {
  data: UserData;
  loading: boolean;
  onSaveUserInfo: (
    firstName: string,
    lastName: string,
    phone: string,
    city: string
  ) => void;
};

const AccountUserInfo = (props: AccountUserInfoProps) => {
  const [showEmail, setShowEmail] = useState(false);
  const [editing, setEditing] = useState(false);

  const [newFirstName, setNewFirstName] = useState(props.data.firstName);
  const [newLastName, setNewLastName] = useState(props.data.lastName);
  const [newPhone, setNewPhone] = useState(props.data.phone);
  const [newCity, setNewCity] = useState(props.data.city);

  const toggleEmailHandler = () => {
    setShowEmail((prev) => !prev);
  };

  const editButtonHandler = () => {
    setNewFirstName(props.data.firstName);
    setNewLastName(props.data.lastName);
    setNewPhone(props.data.phone);
    setNewCity(props.data.city);
    setEditing(true);
  };

  const saveButtonHandler = () => {
    props.onSaveUserInfo(newFirstName, newLastName, newPhone, newCity);
    setEditing(false);
  };

  const firstNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setNewFirstName(e.target.value);
  };

  const lastNameCHangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setNewLastName(e.target.value);
  };

  const cityChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setNewCity(e.target.value);
  };

  return (
    <Paper p="xl">
      <Title order={2}>Datos</Title>
      <Table mt="sm" verticalSpacing="xs" className={styles.tableUser}>
        <tbody>
          <tr>
            <td>
              <Group spacing="sm">
                <BiEnvelope size={ICON_SIZE} />
                Correo
              </Group>
            </td>
            <td>
              <Group>
                <Text>{showEmail ? props.data.email : "******@******"}</Text>
                <Button
                  compact
                  variant="subtle"
                  sx={{ height: "fit-content", padding: "3px 6px" }}
                  onClick={toggleEmailHandler}
                >
                  {showEmail ? "Ocultar" : "Mostrar"}
                </Button>
              </Group>
            </td>
          </tr>
          <tr>
            <td>
              <Group spacing="sm">
                <BiUser size={ICON_SIZE} />
                Nombre
              </Group>
            </td>
            <td>
              {!editing ? (
                <Text>{`${props.data.firstName} ${props.data.lastName}`}</Text>
              ) : (
                <Group noWrap>
                  <TextInput
                    sx={{ maxWidth: "16em" }}
                    size="sm"
                    value={newFirstName}
                    onChange={firstNameChangeHandler}
                    maxLength={32}
                  />
                  <TextInput
                    sx={{ maxWidth: "16em" }}
                    size="sm"
                    value={newLastName}
                    onChange={lastNameCHangeHandler}
                    maxLength={32}
                  />
                </Group>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Group spacing="sm">
                <BiPhone size={ICON_SIZE} />
                Teléfono
              </Group>
            </td>
            <td>
              {!editing ? (
                props.data.phone
              ) : (
                <PhoneInput
                  size="sm"
                  sx={{ maxWidth: "16em" }}
                  maxLength={16}
                  value={newPhone}
                  onChange={(value: string) => setNewPhone(value)}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Group spacing="sm">
                <BiHome size={ICON_SIZE} />
                Localidad
              </Group>
            </td>
            <td>
              {!editing ? (
                props.data.city
              ) : (
                <TextInput
                  sx={{ maxWidth: "33em" }}
                  size="sm"
                  value={newCity}
                  onChange={cityChangeHandler}
                  maxLength={64}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Group spacing="sm">
                <BiCalendarEvent size={ICON_SIZE} />
                Ingreso
              </Group>
            </td>
            <td>
              {new Date(props.data.createdAt).toLocaleDateString("es-AR", {
                dateStyle: "medium",
              })}
            </td>
          </tr>
        </tbody>
      </Table>

      <Group mt="sm" position="left">
        {!editing ? (
          <Button onClick={editButtonHandler}>
            <BiEdit size={20} />
            <Text ml={6}>Editar datos</Text>
          </Button>
        ) : (
          <Group>
            <Button loading={props.loading} onClick={saveButtonHandler}>
              <BiSave size={20} />
              <Text ml={6}>Guardar</Text>
            </Button>
            <Button
              variant="outline"
              loading={props.loading}
              onClick={() => setEditing(false)}
            >
              Cancelar
            </Button>
          </Group>
        )}
      </Group>
    </Paper>
  );
};

export default AccountUserInfo;
