import { ActionIcon } from "@mantine/core";
import { BiXCircle } from "react-icons/bi";

type RemoveButtonProps = {
  className?: string;
  onClick?: (val: string | number | undefined) => void;
  value?: string | number;
  size?: number;
};

const RemoveButton = (props: RemoveButtonProps) => {
  const clickHandler = () => {
    props.onClick(props.value);
  };

  return (
    <ActionIcon color="red" radius="lg" onClick={clickHandler}>
      <BiXCircle size={props.size || 24} />
    </ActionIcon>
  );
};

export default RemoveButton;
