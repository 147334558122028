import { useEffect, useRef, useState } from "react";
import {
  Group,
  Text,
  Title,
  SimpleGrid,
  Container,
  Button,
  NumberInput,
  Stack,
  Divider,
  Center,
  SelectItem,
} from "@mantine/core";
import { BiDollar, BiSave } from "react-icons/bi";
import BrandLines from "../../models/brand-lines";
import Product from "../../models/product";
import PillTabs from "../common/PillTabs";
import PaperConfigSection from "./PaperConfigSection";
import CustomBarsTable from "./CustomBarsTable";

export type AluPrice = {
  code: string;
  value: number;
};

type ConfigAluminumProps = {
  isLoading: boolean;
  brandLines: BrandLines[];
  prices: AluPrice[];
  cutLossPerBar: number;
  cutLossPerCut: number;
  canOptimizeCuts: boolean;
  intl: boolean;
  usd?: boolean;
  colors: Product[];
  customAlu: Product[];
  roundCosts: boolean;
  onSubmit: (prices: AluPrice[], barLoss: number, cutLoss: number) => void;
  onCustomSave: (id: string, prices: number[]) => Promise<Product>;
  onPercentageApply: (
    type: string,
    brand: string,
    percentage: number
  ) => Promise<boolean>;
};

const DEFAULT_PRICE = 1000;
const OTHER_CODE = "other";

const ConfigAluminum = (props: ConfigAluminumProps) => {
  const [prices, setPrices] = useState<AluPrice[]>([]);
  const [barLoss, setBarLoss] = useState<number>(props.cutLossPerBar);
  const [cutLoss, setCutLoss] = useState<number>(props.cutLossPerCut);

  const [ppbBrands, setPpbBrands] = useState<SelectItem[]>([]);
  const [brandTab, setBrandTab] = useState<string>();
  const [brandIsPvc, setBrandIsPvc] = useState<boolean>(false);
  const [lines, setLines] = useState<SelectItem[]>([]);
  const [lineTab, setLineTab] = useState<string>("");

  const [showPerKgTable, setShowPerKgTable] = useState<boolean>(false);
  const [showPerBarTable, setShowPerBarTable] = useState<boolean>(false);

  const initialized = useRef(false);

  useEffect(() => {
    setShowPerKgTable(props.brandLines.some((b) => !b.ppb));
    setShowPerBarTable(props.brandLines.some((b) => b.ppb));
    setPpbBrands(
      props.brandLines
        .filter((bl) => bl.ppb)
        .map((bl) => ({ value: bl.code, label: bl.name }))
    );
  }, [props.brandLines]);

  useEffect(() => {
    if (initialized.current === false && props.prices) {
      if (!props.intl) {
        const lines = props.brandLines
          .map((bl) => bl.lines.map((line) => line.code))
          .flat();
        const linePrices: AluPrice[] = lines.map((line) => {
          const value =
            props.prices?.find((p) => p.code === line)?.value || DEFAULT_PRICE;
          return { code: line, value };
        });

        setPrices(linePrices);
      }
      initialized.current = true;
    }
  }, [props.brandLines, props.prices, props.intl]);

  const aluPriceChangeHandler = (code: string, value: number) => {
    setPrices((prevState) => {
      return prevState.map((price) => {
        if (price.code === code) return { code, value };
        else return price;
      });
    });
  };

  const brandTabChangeHandler = (value: string) => {
    const brand = props.brandLines.find((bl) => bl.code === value);
    const lns = brand?.lines ?? [];
    setLines(
      lns
        .concat({ code: OTHER_CODE, name: "Otros" })
        .map((line) => ({ value: line.code, label: line.name }))
    );
    setLineTab(lns[0].code);
    setBrandTab(value);
    setBrandIsPvc(brand.pvc || false);
  };

  const saveCustomAluPriceHandler = async (id: string, prices: number[]) => {
    return await props.onCustomSave(id, prices);
  };

  const applyPercentageHandler = async (percentage: number) => {
    return await props.onPercentageApply("alu", brandTab, percentage);
  };

  const submitHandler = () => {
    if (prices.length > 0) props.onSubmit(prices, barLoss, cutLoss);
    else if (props.intl) props.onSubmit(null, barLoss, cutLoss);
  };

  return (
    <PaperConfigSection
      title="Barras"
      description={"Defina el costo del aluminio o PVC."}
    >
      {showPerKgTable && (
        <>
          <Title order={3}>Costos por kilo</Title>

          <Container sx={{ width: "800px" }}>
            {props.brandLines
              .filter((bl) => !bl.ppb)
              .map((bl) => (
                <Stack my={20} key={bl.code} spacing={0}>
                  <Text weight="bold">{bl.name}</Text>
                  <SimpleGrid cols={5} spacing="md">
                    {bl.lines.map((line) => {
                      const priceValue =
                        prices?.find((p) => p.code === line.code)?.value ||
                        DEFAULT_PRICE;
                      return (
                        <NumberInput
                          key={line.code}
                          label={line.name}
                          size="sm"
                          sx={{ width: "9em" }}
                          min={1}
                          max={99999}
                          precision={props.roundCosts ? 0 : 2}
                          decimalSeparator=","
                          icon={<BiDollar size="20" />}
                          value={priceValue}
                          onChange={(value: number) =>
                            aluPriceChangeHandler(line.code, value)
                          }
                          disabled={props.isLoading}
                        />
                      );
                    })}
                  </SimpleGrid>
                </Stack>
              ))}
          </Container>
        </>
      )}

      {showPerBarTable && (
        <>
          <Divider mt="xl" mb="md" size="sm" />

          <Title order={3}>Costos por barra</Title>

          {ppbBrands.length > 0 && (
            <>
              <PillTabs
                brands={ppbBrands}
                value={brandTab}
                color="brand.4"
                onChange={(value) => brandTabChangeHandler(value)}
              />
              {lineTab ? (
                <PillTabs
                  brands={lines}
                  value={lineTab}
                  color="brand.2"
                  onChange={(value) => setLineTab(value)}
                />
              ) : (
                <Text color="gray" mb="xs">
                  Seleccione una marca para ver las líneas correspondientes.
                </Text>
              )}
            </>
          )}
          <CustomBarsTable
            products={props.customAlu.filter(
              (alu) =>
                alu.brd === brandTab &&
                (lineTab === OTHER_CODE ? !alu.lin : alu.lin === lineTab)
            )}
            colors={props.colors}
            pvc={brandIsPvc}
            showPercentageButton={true}
            currentBrandName={
              ppbBrands.find((b) => b.value === brandTab)?.label
            }
            onCustomSave={saveCustomAluPriceHandler}
            onPercentageApply={applyPercentageHandler}
          />
        </>
      )}

      {props.canOptimizeCuts && (
        <>
          <Divider mt="xl" mb="md" size="sm" />

          <Title order={3}>Optimización de Cortes</Title>

          <Center>
            <Group mt="md" spacing={40}>
              <Group noWrap>
                <Text>Despunte de barra total (mm)</Text>
                <NumberInput
                  sx={{ width: "6em" }}
                  min={0}
                  max={99}
                  value={barLoss || 0}
                  onChange={(value) => setBarLoss(value || 0)}
                />
              </Group>
              <Group noWrap>
                <Text>Corte de hoja (mm)</Text>
                <NumberInput
                  sx={{ width: "6em" }}
                  min={0}
                  max={99}
                  value={cutLoss || 0}
                  onChange={(value) => setCutLoss(value || 0)}
                />
              </Group>
            </Group>
          </Center>
        </>
      )}

      <Group mt="xl" position="right">
        <Button
          loading={props.isLoading}
          onClick={submitHandler}
          sx={{ width: "12em" }}
        >
          <BiSave size="20" />
          <Text ml="6px">Guardar</Text>
        </Button>
      </Group>
    </PaperConfigSection>
  );
};

export default ConfigAluminum;
