import {
  ActionIcon,
  Box,
  Center,
  Container,
  Group,
  NumberInput,
  Paper,
  Select,
  SelectItem,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  BiMoveHorizontal,
  BiMoveVertical,
  BiPurchaseTag,
  BiTrash,
} from "react-icons/bi";
import Product from "../../models/product";
import Graphic from "../Graphic/Graphic";
import styles from "./Compact.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  COMPACT_MODELS,
  MAX_MEASURE,
  MIN_MEASURE,
  ProductCategory,
} from "../../utils/globalConstants";
import { RiPaintFill } from "react-icons/ri";

const UPDATE_TIME = 300;

type CompactProps = {
  form: CompactFormState;
  compactId: number;
  compactModel: string;
  onCompactApplied: (compactId: number, formState: CompactFormState) => void;
  onRename: (compactId: number, compact: boolean) => void;
  onRemove: (compactId: number) => void;
  custom: Product[];
};

export type CompactFormState = {
  count?: number;
  color?: string;
  width?: number;
  height?: number;
};

const Compact = (props: CompactProps) => {
  const { onCompactApplied, compactId, compactModel, form, custom } = props;

  const firstTimeApplied = useRef(false);

  const [compactState, setCompactState] = useState<CompactFormState>();
  const [compactName, setCompactName] = useState<string>("");
  const [colorList, setColorList] = useState<SelectItem[]>();

  const countChangeHandler = (val: number) => {
    setCompactState((prev) => ({
      ...prev,
      count: val,
    }));
  };
  const colorChangeHandler = (newValue: string) => {
    setCompactState((prev) => ({
      ...prev,
      color: newValue,
    }));
  };
  const widthChangeHandler = (val: number) => {
    setCompactState((prev) => ({
      ...prev,
      width: val,
    }));
  };
  const heightChangeHandler = (val: number) => {
    setCompactState((prev) => ({
      ...prev,
      height: val,
    }));
  };

  const applyFormHandler = useCallback(
    (formValues: CompactFormState) => {
      if (!formValues) return;
      onCompactApplied(compactId, formValues);
    },
    [onCompactApplied, compactId]
  );

  const renameClickHandler = () => {
    props.onRename(props.compactId, true);
  };

  const removeClickHandler = () => {
    props.onRemove(props.compactId);
  };

  const paintColorHex =
    props.custom?.find((xp) => xp._id === form?.color)?.col || "";

  useEffect(() => {
    if (!firstTimeApplied.current) {
      firstTimeApplied.current = true;
      const colors = custom
        .filter((p) => p.cat === ProductCategory.COMPACT)
        .map((p) => ({ value: p._id, label: p.name }));
      const name =
        COMPACT_MODELS.find((c) => c.code === compactModel).name || "Compacto";
      setColorList(colors);
      setCompactName(name);
      if (form) {
        setCompactState(form);
      } else {
        setCompactState({
          count: 1,
          color: colors[0].value,
          width: 1500,
          height: 1100,
        });
      }
    } else {
      const timeout = setTimeout(() => {
        applyFormHandler(compactState);
      }, UPDATE_TIME);
      return () => clearTimeout(timeout);
    }
  }, [applyFormHandler, compactId, compactModel, compactState, custom, form]);

  return (
    <Container fluid sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "0", right: "10px" }}>
        <Stack spacing="lg">
          <Tooltip
            label="Código"
            position="left"
            transitionProps={{ transition: "fade" }}
            withArrow
          >
            <ActionIcon
              variant="transparent"
              color="brand"
              radius="xl"
              onClick={renameClickHandler}
            >
              <BiPurchaseTag size={28} />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Eliminar"
            position="left"
            transitionProps={{ transition: "fade" }}
            withArrow
          >
            <ActionIcon
              variant="transparent"
              color="red"
              radius="xl"
              onClick={removeClickHandler}
            >
              <BiTrash size={28} />
            </ActionIcon>
          </Tooltip>
        </Stack>
      </Box>
      <div className={styles.topSection}>
        <Paper
          withBorder
          shadow={null}
          sx={{
            minWidth: "450px",
            borderWidth: "2px",
            borderColor: "lightgray",
            overflow: "hidden",
          }}
          style={{ zIndex: 1 }}
        >
          {compactState && (
            <Box style={{ padding: "24px 1em" }}>
              <Text mb="md" weight="bold">
                {compactName}
              </Text>
              <Stack>
                <Group mb="md" spacing="xl" align="flex-end">
                  <NumberInput
                    sx={{ width: "8em" }}
                    label="Cantidad"
                    min={1}
                    max={99}
                    icon={<Text>x</Text>}
                    value={compactState.count}
                    onChange={countChangeHandler}
                  />
                  <Select
                    sx={{ width: "17em" }}
                    label="Color"
                    icon={<RiPaintFill size="20" />}
                    data={colorList}
                    value={compactState.color}
                    onChange={colorChangeHandler}
                    maxDropdownHeight={280}
                  />
                </Group>
                <Group mb="md" spacing="xl" align="flex-end">
                  <NumberInput
                    sx={{ width: "10em" }}
                    label="Ancho"
                    min={MIN_MEASURE}
                    max={MAX_MEASURE}
                    icon={<BiMoveHorizontal size="24" />}
                    value={compactState.width}
                    onChange={widthChangeHandler}
                  />
                  <NumberInput
                    sx={{ width: "10em" }}
                    label="Alto"
                    min={MIN_MEASURE}
                    max={MAX_MEASURE}
                    icon={<BiMoveVertical size="24" />}
                    value={compactState.height}
                    onChange={heightChangeHandler}
                  />
                </Group>
              </Stack>
            </Box>
          )}
        </Paper>
        <Center className={styles.divGraphic}>
          {compactState && (
            <Graphic
              data={[
                {
                  partId: 0,
                  openingType: "comp",
                  realWidth: compactState.width,
                  realHeight: compactState.height,
                  xPosition: 0,
                  yPosition: 0,
                  panels: 1,
                  modules: [],
                  color: paintColorHex,
                  mosquito: false,
                  tapajun: false,
                  frameAng: 90,
                  panelAng: 90,
                  guide: false,
                },
              ]}
              currentId={0}
              unified={false}
            />
          )}
        </Center>
      </div>
    </Container>
  );
};

export default Compact;
