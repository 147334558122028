import { useContext } from "react";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import UserContext from "../../store/user-context";
import HeaderLogo from "./HeaderLogo";
import styles from "./MainHeader.module.css";
import UserMenu from "./UserMenu";
import HeaderNav from "./HeaderNav";

type MainHeaderProps = {
  paths: any;
  maintenance: boolean;
};

const MainHeader = (props: MainHeaderProps) => {
  const userCtx = useContext(UserContext);

  return (
    <header className={styles.header}>
      <Link className={styles.headerLogo} to={props.paths.home}>
        <HeaderLogo />
      </Link>
      {!props.maintenance && (
        <HeaderNav context={userCtx} paths={props.paths} />
      )}
      {userCtx.loggedIn && (
        <div className={styles.headerUserMenu}>
          {userCtx.isGuest && (
            <Text
              className={styles.guestAccountLabel}
              size="sm"
              color="brand.0"
            >
              Cuenta de empleado
            </Text>
          )}
          <UserMenu context={userCtx} paths={props.paths} />
        </div>
      )}
    </header>
  );
};

export default MainHeader;
