import { Accordion } from "@mantine/core";

type QuoteAccordionProps = {
  value: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
};

const QuoteAccordion = (props: QuoteAccordionProps) => {
  return (
    <Accordion
      mt="md"
      multiple={false}
      variant="separated"
      chevronPosition="left"
      value={props.value}
      onChange={props.onChange}
      styles={{
        label: { fontWeight: "bold" },
        item: { backgroundColor: "white", border: "1px solid lightgray" },
        content: {
          backgroundColor: "white",
          padding: "1em 1em 2em",
        },
      }}
    >
      {props.children}
    </Accordion>
  );
};

export default QuoteAccordion;
