import { useEffect, useState } from "react";
import {
  AspectRatio,
  Box,
  Group,
  Paper,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useQuery } from "react-query";
import { BiHelpCircle, BiLogoWhatsapp } from "react-icons/bi";

import HelpFaq from "../components/Help/HelpFaq";
import HelpIntroModal from "../components/Help/HelpIntroModal";
import { getHelpFaq } from "../services/services";
import PageWrapper from "../components/common/PageWrapper";

const TUTORIAL_EMBED_WIDTH = 700;
const PHONE = process.env.REACT_APP_CONTACT_PHONE;

const HelpPage = () => {
  // const userCtx = useContext(UserContext);

  const [introModal, setIntroModal] = useState<boolean>(false);

  // const [contactSubject, setContactSubject] = useState<string>("");
  // const [contactMessage, setContactMessage] = useState<string>("");
  // const [contactModal, setContactModal] = useState<boolean>(false);

  const [loadingTutorial, setLoadingTutorial] = useState<boolean>(true);

  // const firstTimeMutation = useMutation(() => setFirstTime(userCtx.token));
  // const messageMutation = useMutation(
  //   () => sendContactMessage(contactSubject, contactMessage, userCtx.token),
  //   {
  //     onSuccess: (data) => {
  //       if (data === true) {
  //         setContactModal(true);
  //         setContactSubject("");
  //         setContactMessage("");
  //       }
  //     },
  //   }
  // );
  const { data: helpFaqData } = useQuery("help-faq", getHelpFaq, {
    placeholderData: [],
  });

  // const checkFirstTime = useCallback(async () => {
  //   if (userCtx.data.active && userCtx.data.firstTime === false) {
  //     setIntroModal(true);
  //     userCtx.editData({ firstTime: true });
  //     firstTimeMutation.mutate();
  //   }
  // }, [userCtx, firstTimeMutation]);

  // const sendContactMessageHandler = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   messageMutation.mutate();
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   checkFirstTime();
  // }, [checkFirstTime]);

  return (
    <PageWrapper>
      <HelpIntroModal
        opened={introModal}
        onClose={() => setIntroModal(false)}
      />

      {/* <Modal
        opened={contactModal}
        onClose={() => setContactModal(false)}
        withCloseButton={false}
        centered
        padding={30}
      >
        <Stack align="center">
          <Text size="xl">¡Gracias por comunicarse!</Text>
          <Text align="center">
            Su mensaje ha sido recibido. Nos pondremos en contacto con usted
            dentro de las próximas 72 horas.
          </Text>
          <Button onClick={() => setContactModal(false)}>Cerrar</Button>
        </Stack>
      </Modal> */}

      <Group my="lg" spacing={8}>
        <BiHelpCircle size={36} />
        <Title order={1}>Ayuda</Title>
      </Group>

      <Stack spacing={30}>
        <Stack spacing="xs">
          <Title order={2}>Tutoriales</Title>
          <Stack>
            <Skeleton width={TUTORIAL_EMBED_WIDTH} visible={loadingTutorial}>
              <AspectRatio
                ratio={16 / 9}
                sx={{ maxWidth: TUTORIAL_EMBED_WIDTH }}
              >
                <iframe
                  src="https://www.youtube.com/embed/Dq0xOeoaTr4"
                  title="Tutorial Básico"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={() => setLoadingTutorial(false)}
                />
              </AspectRatio>
            </Skeleton>
          </Stack>
        </Stack>

        <Stack spacing="xs">
          <Title order={2}>Preguntas Frecuentes</Title>
          <Box sx={{ width: "900px" }}>
            <HelpFaq faq={helpFaqData} />
          </Box>
        </Stack>

        <Stack spacing="xs">
          <Title order={2}>Contacto</Title>
          <Paper p="xl" w={600}>
            <Stack>
              <Text>
                Si necesita soporte o encontró un problema que desea reportar,
                puede comunicarse con nosotros:
              </Text>
              <Group spacing={0}>
                <ThemeIcon mr={8} color="green" radius="xl">
                  <BiLogoWhatsapp size={20} />
                </ThemeIcon>
                <Text fw="bold" color="gray" align="center">
                  {PHONE}
                </Text>
                <Text ml={4} color="dimmed">
                  (Argentina)
                </Text>
              </Group>
            </Stack>
            {/* <form id="contactForm" onSubmit={sendContactMessageHandler}>
                <Stack>
                  <Text>
                    Si necesita soporte o encontró un problema que desea
                    reportar, puede llenar este formulario para comunicarse con
                    nosotros.
                  </Text>
                  <TextInput
                    label="Asunto"
                    form="contactForm"
                    maxLength={64}
                    required
                    disabled={messageMutation.isLoading}
                    value={contactSubject}
                    onChange={(e) => setContactSubject(e.target.value)}
                  />
                  <Box>
                    <Textarea
                      label="Mensaje"
                      form="contactForm"
                      minRows={4}
                      maxRows={10}
                      maxLength={500}
                      autosize
                      required
                      disabled={messageMutation.isLoading}
                      value={contactMessage}
                      onChange={(e) => setContactMessage(e.target.value)}
                    />
                    <Text
                      align="right"
                      color="gray"
                      size="sm"
                    >{`${contactMessage.length}/500`}</Text>
                  </Box>
                  <Group position="right">
                    <Button
                      type="submit"
                      form="contactForm"
                      sx={{ width: "10em" }}
                      loading={messageMutation.isLoading}
                    >
                      <BiSend size="20" />
                      <Text ml="6px">Enviar</Text>
                    </Button>
                  </Group>
                </Stack>
              </form> */}
          </Paper>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default HelpPage;
