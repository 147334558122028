import {
  Alert,
  Box,
  Button,
  Chip,
  Group,
  Loader,
  Paper,
  PasswordInput,
  Select,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { BiError, BiErrorCircle, BiUserPlus } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import PhoneInput from "../components/common/PhoneInput";
import PlanButton from "../components/Signup/PlanButton";

import {
  getBrandList,
  getCountries,
  getPlans,
  signupUser,
} from "../services/services";
import UserContext from "../store/user-context";
import PageWrapper from "../components/common/PageWrapper";
import PasswordEditInput from "../components/Signup/PasswordEditInput";

import { COUNTRY_FLAGS } from "../utils/globalConstants";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA || "";
const ARG_COUNTRY_CODE = "ARG";

enum PLAN_CODE {
  BASICO = "basc",
  STANDARD = "stan",
  PREMIUM = "prem",
}

const DEFAULT_PLAN = PLAN_CODE.STANDARD;

const SignupPage = () => {
  const userCtx = useContext(UserContext);
  const [country, setCountry] = useState<string>(ARG_COUNTRY_CODE);
  const [international, setInternational] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const [passConfirm, setPassConfirm] = useState<string>("");
  const [passValid, setPassValid] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>(DEFAULT_PLAN);
  const [brandCount, setBrandCount] = useState<number>(1);
  const [chosenBrands, setChosenBrands] = useState<string[]>([]);
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [displayError, setDisplayError] = useState<string>("");

  const [countryOptions, setCountryOptions] = useState([
    { value: "ARG", label: "Argentina" },
  ]);
  const [countryCode, setCountryCode] = useState("+54 9");

  const { data: countryData } = useQuery("countries", getCountries, {
    placeholderData: [],
    onSuccess(data) {
      const dataArray = data.map((c) => {
        const flag = COUNTRY_FLAGS.find((f) => f.code === c.code)?.flag || "";
        return { value: c.code, label: `${flag}  ${c.name}` };
      });
      setCountryOptions(dataArray);
    },
  });
  const { data: brandListData, isFetching: brandListLoading } = useQuery(
    "brandlist",
    getBrandList,
    {
      placeholderData: [],
    }
  );
  const { data: plansData, isFetching: plansLoading } = useQuery(
    "plans",
    getPlans,
    {
      placeholderData: [],
    }
  );
  const signupMutation = useMutation(
    () =>
      signupUser(
        firstName,
        lastName,
        email,
        phone,
        pass,
        country,
        city,
        selectedPlan,
        chosenBrands,
        captchaToken
      ),
    {
      onSuccess: (data) => userCtx.onLogin(data.token, data.user),
      onError: (error: Error) =>
        setDisplayError(
          error.message ||
            "Ocurrió un error inesperado.\nRevise los campos y vuelva a intentarlo."
        ),
    }
  );

  const countryChangeHandler = (value: string) => {
    const data = countryData.find((c) => c.code === value);
    if (!data) return;
    const isInternational = value !== ARG_COUNTRY_CODE;
    setCountry(value);
    setInternational(isInternational);
    setCountryCode(`+${data.prefix}`);
    planChangeHandler(DEFAULT_PLAN);
  };
  const firstNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const lastNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const phoneChangeHandler = (value: string) => {
    setPhone(value);
  };
  const cityChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
  };
  const passChangeHandler = (value: string) => {
    setPass(value);
  };
  const passConfirmChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassConfirm(e.target.value);
  };
  const planChangeHandler = (value: string) => {
    const plan = plansData.find((p) => p.code === value);
    if (plan) {
      setSelectedPlan(value);
      setChosenBrands([]);
      setBrandCount(plan.brandCount);
    }
  };
  const brandSelectionHandler = (newValue: string[]) => {
    setChosenBrands(newValue);
  };
  const captchaCompleteHandler = (token: string): void => {
    setCaptchaToken(token);
  };

  const signupFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (pass !== passConfirm) {
      setDisplayError("Las contraseñas no coinciden.");
      return;
    }
    if (!passValid) {
      setDisplayError("La contraseña no cumple los requisitos mínimos.");
      return;
    }
    if (selectedPlan === PLAN_CODE.PREMIUM) {
      if (chosenBrands.length === 0) {
        setDisplayError("Elija al menos una marca a utilizar.");
        return;
      } else if (chosenBrands.length > 2) {
        setDisplayError("No puede seleccionar más de dos marcas.");
        return;
      }
    }
    setDisplayError("");

    signupMutation.mutate();
  };

  const plansContent = plansLoading ? (
    <Loader m="md" size="xl" variant="dots" />
  ) : (
    <Stack spacing={6} align="center">
      <SimpleGrid
        spacing={8}
        cols={international ? 2 : 3}
        breakpoints={[
          {
            maxWidth: 600,
            cols: 1,
          },
          {
            maxWidth: 700,
            cols: 2,
          },
        ]}
      >
        {plansData
          .filter((p) => p.intl === true || p.intl === international)
          .map((plan: any) => (
            <PlanButton
              key={plan.code}
              plan={plan}
              selected={plan.code === selectedPlan}
              usd={international}
              onClick={planChangeHandler}
            />
          ))}
      </SimpleGrid>
      {!international && (
        <Group spacing={5}>
          <BiErrorCircle size={international ? 21 : 16} />
          <Text size="sm" color="brand.6">
            Cuota ajustable trimestralmente según inflación.
          </Text>
        </Group>
      )}
    </Stack>
  );

  const brandsContent = brandListLoading ? (
    <Loader m="md" size="xl" variant="dots" />
  ) : (
    <Chip.Group value={chosenBrands} onChange={brandSelectionHandler} multiple>
      <Group mb="md" p="md" position="center" spacing="md" bg="whitesmoke">
        {brandListData
          .filter((b) => !b.country || b.country === country)
          .sort((a, b) => Number(Boolean(a.pvc)) - Number(Boolean(b.pvc)))
          .map((b) => (
            <Chip
              radius="sm"
              size="lg"
              key={b.code}
              value={b.code}
              disabled={
                chosenBrands.length >= brandCount &&
                !chosenBrands.includes(b.code)
              }
            >
              <Text span>{b.name}</Text>
              {b.pvc && (
                <Text span ml={3} fz={10}>
                  PVC
                </Text>
              )}
            </Chip>
          ))}
      </Group>
    </Chip.Group>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper>
      <Paper p="xl" style={{ margin: "40px auto", maxWidth: "800px" }}>
        <form id="signupForm" onSubmit={signupFormHandler}>
          <Stack align="center">
            <Group my="sm" spacing={6}>
              <BiUserPlus size="28" />
              <Title order={3}>Registre su cuenta</Title>
            </Group>
            <Stack align="stretch" spacing="sm" sx={{ maxWidth: "500px" }}>
              <Select
                data={countryOptions}
                value={country}
                onChange={countryChangeHandler}
                size="md"
                label="País"
              />
              <Group noWrap>
                <TextInput
                  label="Nombre"
                  form="signupForm"
                  value={firstName}
                  onChange={firstNameChangeHandler}
                  maxLength={32}
                  required
                />
                <TextInput
                  label="Apellido"
                  form="signupForm"
                  value={lastName}
                  onChange={lastNameChangeHandler}
                  maxLength={32}
                  required
                />
              </Group>
              <TextInput
                label="Correo electrónico"
                form="signupForm"
                type="email"
                value={email}
                onChange={emailChangeHandler}
                maxLength={64}
                required
              />
              <PhoneInput
                label={`Celular (sin 0${international ? "" : " ni 15"})`}
                form="signupForm"
                value={phone}
                onChange={phoneChangeHandler}
                maxLength={16}
                prefix={countryCode}
                required
              />
              <TextInput
                label="Localidad"
                form="signupForm"
                value={city}
                onChange={cityChangeHandler}
                maxLength={64}
                required
              />
              <PasswordEditInput
                label="Contraseña"
                form="signupForm"
                value={pass}
                onChange={passChangeHandler}
                onValidityChange={(valid: boolean) => setPassValid(valid)}
                required
              />
              <PasswordInput
                label="Confirmar contraseña"
                form="signupForm"
                value={passConfirm}
                onChange={passConfirmChangeHandler}
                required
              />
            </Stack>
            <Stack mt="xs" align="center">
              <Text>Seleccione un plan</Text>
              {plansContent}
            </Stack>
            {selectedPlan !== PLAN_CODE.BASICO && (
              <Stack align="center" spacing={2} mt="xs">
                {selectedPlan === PLAN_CODE.STANDARD && (
                  <Text>Seleccione la marca a utilizar</Text>
                )}
                {selectedPlan === PLAN_CODE.PREMIUM && (
                  <Text>Seleccione 2 marcas a utilizar</Text>
                )}
                <Text mb="md" color="gray">
                  {"(click para elegir o quitar una marca)"}
                </Text>
                {brandsContent}
              </Stack>
            )}

            <Box mt="sm">
              <ReCAPTCHA sitekey={SITE_KEY} onChange={captchaCompleteHandler} />
            </Box>

            <Button
              my="md"
              size="md"
              form="signupForm"
              type="submit"
              loading={signupMutation.isLoading}
              disabled={
                !selectedPlan ||
                (brandCount > 0 && chosenBrands.length !== brandCount) ||
                !captchaToken
              }
            >
              Crear cuenta
            </Button>
            {displayError && (
              <Alert
                color="red"
                title="Error"
                icon={<BiError size={24} />}
                styles={{
                  message: {
                    whiteSpace: "pre-line",
                  },
                }}
              >
                {displayError}
              </Alert>
            )}
          </Stack>
        </form>
      </Paper>
    </PageWrapper>
  );
};

export default SignupPage;
