import {
  Alert,
  Box,
  Button,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useContext, useState } from "react";
import { BiErrorCircle, BiHistory, BiTrash } from "react-icons/bi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  deleteAllHistory,
  deleteHistoryFile,
  getHistory,
  getHistoryFile,
} from "../services/services";
import UserContext from "../store/user-context";
import PageWrapper from "../components/common/PageWrapper";
import HistoryAccordion from "../components/History/HistoryAccordion";

const MAX_SAVED_QUOTES = Number(process.env.REACT_APP_SAVED_QUOTES);
const QUOTE_EDIT_URL = "/quote/edit";

const HistoryPage = () => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data: historyData, isFetching: isLoading } = useQuery(
    "history",
    () => getHistory(userCtx.token),
    { placeholderData: [] }
  );
  const deleteMutation = useMutation(
    (quoteId: string) => deleteHistoryFile(quoteId, userCtx.token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("history");
      },
    }
  );
  const deleteAllMutation = useMutation(() => deleteAllHistory(userCtx.token), {
    onSuccess: () => {
      queryClient.invalidateQueries("history");
    },
  });

  const buttonDownloadCostHandler = (id: string) => {
    downloadFile(id, "cost");
  };

  const buttonDownloadOrderHandler = (id: string) => {
    downloadFile(id, "order");
  };

  const buttonDownloadMaterialHandler = (id: string) => {
    downloadFile(id, "material");
  };

  const buttonDownloadClientHandler = (id: string) => {
    downloadFile(id, "client");
  };

  const buttonEditQuoteHandler = (id: string) => {
    navigate(`${QUOTE_EDIT_URL}/${id}`);
  };

  const buttonRemoveQuoteHandler = (id: string) => {
    deleteQuote(id);
  };

  const downloadFile = async (quoteId: string, file: string) => {
    setIsDownloading(true);
    const fileData = await getHistoryFile(quoteId, file, userCtx.token);
    if (fileData) {
      const blobData = `data:application/pdf;base64,${fileData.content}`;
      const link = document.createElement("a");
      link.href = blobData;
      link.download = fileData.filename;
      link.click();
    }
    setIsDownloading(false);
  };

  const deleteQuote = async (quoteId: string) => {
    deleteMutation.mutate(quoteId);
  };

  const deleteAll = async () => {
    deleteAllMutation.mutate();
  };

  return (
    <PageWrapper>
      <Group my="lg" spacing={8}>
        <BiHistory size={36} />
        <Title order={1}>Historial</Title>
      </Group>
      <Text mb="xl">
        Aquí se guardarán los presupuestos previamente generados para su
        descarga.
      </Text>

      {userCtx.data.config.saveQuotes ? (
        <Alert
          my="md"
          color="blue"
          title="Nota"
          icon={<BiErrorCircle size={24} />}
          sx={{ width: "fit-content" }}
        >
          {`El historial solo almacenará los ${MAX_SAVED_QUOTES} presupuestos generados más recientemente.
              Recuerde descargar los archivos que desee guardar a su computadora.`}
        </Alert>
      ) : (
        <Alert
          my="md"
          color="yellow"
          title="Aviso"
          icon={<BiErrorCircle size={24} />}
          sx={{ width: "fit-content" }}
        >{`Actualmente no se están almacenando sus presupuestos en la nube.\nPuede activar esta opción en la sección de Configuración.`}</Alert>
      )}

      <div style={{ minWidth: "850px", width: "fit-content" }}>
        {isLoading ? (
          <Stack mt={50} align="center">
            <Text size="lg">Cargando...</Text>
            <Loader size="xl" variant="dots" />
          </Stack>
        ) : (
          <>
            {historyData.length > 0 ? (
              <HistoryAccordion
                quotes={historyData}
                loading={isDownloading || deleteMutation.isLoading}
                canRemove={!userCtx.isGuest}
                canDownloadCostFile={!userCtx.isGuest}
                onDownloadCost={buttonDownloadCostHandler}
                onDownloadOrder={buttonDownloadOrderHandler}
                onDownloadMaterial={buttonDownloadMaterialHandler}
                onDownloadClient={buttonDownloadClientHandler}
                onEdit={buttonEditQuoteHandler}
                onRemove={buttonRemoveQuoteHandler}
              />
            ) : (
              <Box bg="white" p="lg">
                <Center>
                  <Text>No hay presupuestos guardados.</Text>
                </Center>
              </Box>
            )}
            {historyData.length > 0 && !userCtx.isGuest && (
              <Group mt="lg" position="right">
                <Button
                  color="red"
                  variant="light"
                  onClick={deleteAll}
                  disabled={isDownloading || deleteMutation.isLoading}
                >
                  <BiTrash size="24" />
                  <Text ml="6px">Vaciar historial</Text>
                </Button>
              </Group>
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default HistoryPage;
